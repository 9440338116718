import React, { useState, useEffect } from 'react'
import Modal from '../../../../../components/Modal/index'
import { updateMeetingInstanceForMeeting } from '../../../../../api/services/meetingTopic.services'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../../../../../components/ModalSubmitButton'
import { useMeetingRecording } from '../../../../../utils/meetingRecordingContext/meetingRecordingContext'

const SelectMeetingTagsModal = ({ isModalOpen, setIsModalOpen, meetings, meetingInstanceId }) => {
  console.log('meetings', meetings)
  const { t } = useTranslation(['Common'])
  const extendedMeetings = [
    { meetingId: null, meetingName: t('Common:selectMeetingModal.none') },
    ...meetings,
  ]
  const [inputChanged, setInputChanged] = useState(false)

  const [formValues, setFormValues] = useState({ meetings: null })
  const { dispatch } = useMeetingRecording()

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  function inputChangedHandler(e, data) {
    console.log('e', e)
    console.log('data', data)
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'meetings') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
    setInputChanged(true)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit() {
    if (formValues.meetings != null && formValues.meetings.meetingId != null) {
      console.log('meetingId', formValues.meetings.meetingId)
      console.log('meetingInstanceId', meetingInstanceId)
      let res = await updateMeetingInstanceForMeeting(
        formValues.meetings.meetingId,
        meetingInstanceId,
      )
      if (res?.success) {
        dispatch({
          type: 'SET_MEETING_ID',
          meetingId: formValues.meetings.meetingId,
        })
      }
    }
    if (inputChanged) {
      setIsModalOpen(false)
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={t('selectMeetingModal.selectMeeting')}
        isModalOpen={isModalOpen}
        data-testid="Assigne"
        closeModal={() => setIsModalOpen(false)}
        width={'40rem'}
        includeCloseIcon={false}
      >
        {
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <div style={{ width: '100%' }}>
                    {console.log('formValues.meetings', formValues)}
                    <SelectField
                      placeholder={t('formPlaceHolder.select')}
                      name="meetings"
                      value={formValues.meetings}
                      options={extendedMeetings}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.meetingName}
                      getOptionValue={(option) => option.meetingId}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                  </div>
                </div>
              </div>

              <div className="modal-dual-btn">
                <ModalSaveButtonFitted
                  tag={t('Common:commonButtons.select')}
                  onClick={onCustomSubmit}
                />
              </div>
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}
export default SelectMeetingTagsModal
