export const defaultState = {
  meetingInstanceId: null,
  brainstormInstanceId: null,
  problems: [],
  primaryProblem: '',
  // additionalProblems: [],
  isBrainstormingStarted: false,
  isStoppingRecording: false,
  progressState: 'idle',
  audioQueue: [],
  isBotAudioPlaying: false,
  brainstormMap: null,
  clarifications: [],
  isPaused: false,
  stream: null,
  isGeneratingProblems: false,
  isOdinPendingSpeaking: false,
  highlightedQuestions: [],
  steerConversation: '',
  subQuestions: [],
  steerConversationHacks: 1,
  recommendation: '',
  recommendationHacks: 0,
  encodedRequest: '',
  buttonTextState: 0,
  questionIdeasStorage: {},
  isMuted: false,
  discardedList: [],
  interventionActualAudio: null,
  readOnly: false,
  refinementCount: 0,
  stopIntervention: true,
  isMindMapLocked: false,
  isGeneratingMMRec: false,
  hasTopicsLimit: false,
  isUpdatingMMCount: 0,
  isUpdatingMM: false,
  shouldStopInterruptionTemporarily: false,
  useMicOnly: false,
  language: null,
}

export const restoreBrainstormSession = (state, action) => {
  // share screen again??
  const sessionData = action.brainstormSession
  const topLevelProblem = sessionData.topLevelProblem
  const latestBrainstormMapActionPlan = sessionData.latestBrainstormMapActionPlan
  let brainstormMapActionPlanStr = latestBrainstormMapActionPlan?.brainstormMapActionPlanStr
  let initMap = {
    action_plans: [],
  }
  if (brainstormMapActionPlanStr) {
    initMap = JSON.parse(latestBrainstormMapActionPlan.brainstormMapActionPlanStr)
  }

  initMap['problem'] = topLevelProblem.problemStatement
  const newState = {
    ...state,
    meetingInstanceId: sessionData.meetingInstanceId,
    brainstormInstanceId: sessionData.brainstormInstanceId,
    problems: [topLevelProblem.problemStatement],
    primaryProblem: topLevelProblem.problemStatement,
    isBrainstormingStarted: false, // or true??,
    progressState: 'exploration',
    brainstormMap: initMap,
    isOdinPendingSpeaking: false,
    highlightedQuestions: [...sessionData.highlightedSubProblems],
    steerConversation: '',
    subQuestions: topLevelProblem.subProblems.map((subProblem) => subProblem.problemStatement),
    recommendation: '',
    recommendationHacks: 0,
    encodedRequest: '',
    isGeneratingProblems: false,
    buttonTextState: 0,
    questionIdeasStorage:
      sessionData.questionIdeasStorage === '' ? {} : JSON.parse(sessionData.questionIdeasStorage),
    isMuted: false,
    discardedList: [...sessionData.discardedItems.map((q) => q.question)],
    readOnly: true,
    refinementCount: 0,
    isMindMapLocked: false,
    isGeneratingMMRec: false,
    // be careful with these two below.. it's OK for now since it's readonly
    stopIntervention: true,
    hasTopicsLimit: false,
    shouldStopInterruptionTemporarily: false,
  }
  return newState
}

const BrainstormReducer = (state, action) => {
  switch (action.type) {
    case 'START_RECORDING':
      return {
        ...state,
        meetingInstanceId: action.meetingInstanceId,
        brainstormInstanceId: action.brainstormInstanceId,
        problems: [],
        primaryProblem: '',
        isBrainstormingStarted: true,
        progressState: 'idle',
        brainstormMap: null,
        isOdinPendingSpeaking: false,
        highlightedQuestions: [],
        steerConversation: '',
        subQuestions: [],
        steerConversationHacks: 1,
        recommendation: '',
        recommendationHacks: 0,
        encodedRequest: '',
        isGeneratingProblems: false,
        buttonTextState: 0,
        questionIdeasStorage: {},
        isMuted: false,
        discardedList: [],
        readOnly: false,
        refinementCount: 0,
        isMindMapLocked: false,
        isGeneratingMMRec: false,
        shouldStopInterruptionTemporarily: false,
        //isPaused: true this was change we need if we didn't want to start recording immediately
        // don't set this here.. it will overwrite the value from toggle
        // hasTopicsLimit: true,
      }
    case 'UPDATE_PRIMARY_PROBLEM':
      return {
        ...state,
        primaryProblem: action.problem,
      }
    case 'SET_STATEMENT_RECOMMENDATION':
      return {
        ...state,
        recommendationRationale: action.recommendationRationale,
        statementSuggestion: action.statementSuggestion,
      }
    case 'UPDATE_PROBLEMS':
      return {
        ...state,
        problems: action.problems,
      }
    // case 'UPDATE_ADDITIONAL_PROBLEMS':
    //     return {
    //         ...state,
    //         additionalProblems: action.problems,
    //     }
    case 'SET_PROGRESS_STATE':
      console.log('state.progressState', state.progressState)
      if (state.progressState === 'exploration') {
        return state
      }
      return {
        ...state,
        progressState: action.progressState,
      }
    case 'SET_IS_STOPPING_RECORDING':
      return {
        ...state,
        isStoppingRecording: action.isStoppingRecording,
      }
    case 'STOP_RECORDING':
      console.log('STOP_RECORDING')
      return {
        ...state,
        meetingInstanceId: null,
        brainstormInstanceId: null,
        problems: [],
        isBrainstormingStarted: false,
        isStoppingRecording: false,
        progressState: 'idle',
        isBotAudioPlaying: false,
        audioQueue: [],
      }
    case 'ADD_TO_AUDIO_QUEUE':
      console.log('ADD_TO_AUDIO_QUEUE', action.audioUrls)
      return {
        ...state,
        audioQueue: [...state.audioQueue, ...action.audioUrls],
      }
    case 'REMOVE_FROM_AUDIO_QUEUE':
      // (prevQueue) => prevQueue.slice(1)
      console.log('REMOVE_FROM_AUDIO_QUEUE')
      return {
        ...state,
        audioQueue: state.audioQueue.slice(1),
        isBotAudioPlaying: false,
      }
    case 'SET_IS_PLAYING':
      return {
        ...state,
        isBotAudioPlaying: action.payload,
      }
    case 'SET_BRAINSTORM_MAP':
      console.log('Set new brainstormMap:', action.brainstormMap)
      const parkingLotTopicInNewMap = action.brainstormMap.action_plans.find(
        (actionPlan) => actionPlan.is_parking_lot,
      )

      if (!parkingLotTopicInNewMap && state?.brainstormMap?.action_plans) {
        const parkingLotTopic = state.brainstormMap.action_plans.find(
          (actionPlan) => actionPlan.is_parking_lot,
        )

        if (parkingLotTopic) {
          action.brainstormMap.action_plans = [
            ...action.brainstormMap.action_plans,
            parkingLotTopic,
          ]
        }
      }

      return {
        ...state,
        brainstormMap: structuredClone(action.brainstormMap),
        isUpdatingMMCount: action.auto ? state.isUpdatingMMCount + 1 : state.isUpdatingMMCount,
      }
    case 'UPDATE_PARKING_LOT_TOPIC':
      console.log('update PARKINGLOT', action.parkingLotTopic)

      const updatedBrainstormMap = structuredClone(state.brainstormMap)

      // find if there is already a parking lot topic
      const parkingLotTopicIndex = updatedBrainstormMap.action_plans.findIndex(
        (actionPlan) => actionPlan.is_parking_lot,
      )

      if (parkingLotTopicIndex !== -1) {
        updatedBrainstormMap.action_plans[parkingLotTopicIndex] = action.parkingLotTopic
      } else {
        updatedBrainstormMap.action_plans.push(action.parkingLotTopic)
      }

      return {
        ...state,
        brainstormMap: updatedBrainstormMap,
        isUpdatingMMCount: state.isUpdatingMMCount + 1,
      }
    case 'SET_CLARIFICATIONS':
      return {
        ...state,
        clarifications: action.clarifications,
      }
    case 'INCREMENT_REFINEMENT_COUNT':
      return {
        ...state,
        refinementCount: state.refinementCount + 1,
      }
    case 'SET_IS_PAUSED':
      return {
        ...state,
        isPaused: action.isPaused,
      }
    case 'SET_SUB_QUESTIONS':
      return {
        ...state,
        subQuestions: action.subQuestions,
      }
    case 'SET_STREAM':
      return {
        ...state,
        stream: action.stream,
      }
    case 'SET_IS_GENERATING_PROBLEMS':
      return {
        ...state,
        isGeneratingProblems: action.isGeneratingProblems,
      }
    case 'SET_ODIN_PENDING_SPEAKING':
      return {
        ...state,
        isOdinPendingSpeaking: action.isOdinPendingSpeaking,
      }
    case 'SET_HIGHLIGHTED_QUESTIONS':
      return {
        ...state,
        highlightedQuestions: action.highlightedQuestions,
      }
    case 'SET_STEER_CONVERSATION':
      return {
        ...state,
        // add some dots to trigger useEffect even if the value is the same
        steerConversation: action.steerConversation + Array(state.steerConversationHacks).join('.'),
        steerConversationHacks:
          state.steerConversationHacks > 2 ? 1 : state.steerConversationHacks + 1,
      }
    case 'SET_RECOMMENDATION':
      if (action.reset) {
        return {
          ...state,
          recommendation: '',
          recommendationHacks: 0,
        }
      }
      console.log('action.recommendation: ', action.recommendation)
      return {
        ...state,
        // added trim() here to remove leading/trailing spaces in case action/state.recommendation are both empty
        recommendation: (state.recommendation + ' ' + action.recommendation).trim(),
        // recommendation: action.recommendation==='' ? '' : action.recommendation + Array(state.recommendationHacks+1).join("."),
        // recommendationHacks: state.recommendationHacks > 2 ? 0 : state.recommendationHacks + 1,
      }
    case 'SET_ENCODED_REQUEST':
      console.log('encoded_user_request_str: ', action.encodedRequest)
      return {
        ...state,
        encodedRequest: action.encodedRequest,
      }
    case 'SET_BUTTON_TEXT':
      return {
        ...state,
        buttonTextState: action.buttonTextState,
      }
    case 'SET_QUESTION_IDEAS_STORAGE':
      if (action.questionNum === -1) {
        return {
          ...state,
        }
      }
      const existingIdeas = state.questionIdeasStorage[action.questionNum] ?? []

      return {
        ...state,
        questionIdeasStorage: {
          ...state.questionIdeasStorage,
          [action.questionNum]: [
            ...existingIdeas,
            action.recommendation.replace(/\$\$/g, ' ').replace(/\/n/g, ' '),
          ],
        },
      }
    case 'SET_IS_MUTED':
      return {
        ...state,
        isMuted: action.isMuted,
      }
    case 'ADD_TO_DISCARDED_LIST':
      return {
        ...state,
        discardedList: [...state.discardedList, action.description],
      }
    case 'SET_INTERVENTION_ACTUAL_AUDIO':
      return {
        ...state,
        interventionActualAudio: action.interventionActualAudio,
      }
    case 'RESTORE_BRAINSTORM_SESSION':
      console.log('what is state: ', state)
      return restoreBrainstormSession(state, action)
    case 'TOGGLE_STOP_INTERVENTION':
      return {
        ...state,
        stopIntervention: !state.stopIntervention,
      }
    case 'SET_MINDMAP_LOCKED':
      return {
        ...state,
        isMindMapLocked: action.isMindMapLocked,
      }
    case 'SET_IS_GENERATING_MM_REC':
      return {
        ...state,
        isGeneratingMMRec: action.payload,
      }
    case 'TOGGLE_HAS_TOPICS_LIMIT':
      return {
        ...state,
        hasTopicsLimit: !state.hasTopicsLimit,
      }
    case 'SET_IS_UPDATING_MM':
      return {
        ...state,
        isUpdatingMM: action.payload,
      }
    case 'SET_SHOULD_STOP_INTERRUPTION':
      console.log('opop SET_SHOULD_STOP_INTERRUPTION', action.payload)
      return {
        ...state,
        shouldStopInterruptionTemporarily: action.payload,
      }
    case 'TOGGLE_USE_MIC_ONLY':
      return {
        ...state,
        useMicOnly: !state.useMicOnly,
      }
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language,
      }
    default:
      throw new Error(`Action: ${action.type} not implemented in BrainstormReducer.`)
  }
}

export default BrainstormReducer
