import { useTranslation } from 'react-i18next'
import './style.css'
import { useEffect, useState } from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { convertTimeStamToMMDDYYYY } from '../../utils/time'
import DecisionLogModal from '../DecisionLogModal'
import { useNavigate } from 'react-router-dom'
import { meetingMultiViewSubRoutes, routes } from '../../utils/routes'

const MeetingReportComponent = () => {
  const { t } = useTranslation(['Common'])
  const [meetings, setMeetings] = useState([])
  const [isDecisionLogModalOpen, setIsDecisionLogModalOpen] = useState(false)
  const [meetingInstanceId, setMeetingInstanceId] = useState(null)
  const [isFinalized, setIsFinalized] = useState(false)
  const [meetingName, setMeetingName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getMeetings()
  }, [])

  const getMeetings = async () => {
    try {
      let result = await getHttpRequest('/get_meetings_under_cxo')
      console.log(result)
      setMeetings(result.meetings)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getFrequency = (frequency) => {
    switch (frequency) {
      case 1:
        return t('meetingReport.weekly')
      case 2:
        return t('meetingReport.biWeekly')
      case 3:
        return t('meetingReport.monthly')
      default:
        return ''
    }
  }

  const navigateToMeetingNotes = (meetingInstanceId) => {
    let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingSummaries}`
    console.log(url)
    navigate(url, {
      state: {
        meetingInstanceId: meetingInstanceId,
      },
    })
    navigate(0)
  }

  return (
    <div
      style={{ marginLeft: '0.5rem', marginTop: '5rem', display: 'flex', flexDirection: 'column' }}
    >
      {isDecisionLogModalOpen && meetingInstanceId && (
        <DecisionLogModal
          isViewOpen={isDecisionLogModalOpen}
          closeView={() => {
            setMeetingInstanceId(null)
            setIsDecisionLogModalOpen(false)
          }}
          meetingInstanceId={meetingInstanceId}
          isFinalized={isFinalized}
          reload={getMeetings}
          meetingName={meetingName}
        />
      )}
      <div style={{ fontSize: '1.6rem', color: '#376dc3' }}>{t('meetingReport.myTeamCadence')}</div>
      <div className="shadow-meeting-report" style={{ marginTop: '3rem', width: '80%' }}>
        <div className="meeting_report-inner-grid">
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.meeting')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.facilitator')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.frequency')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.status')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.lastHeldOn')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.lastHolder')}
          </div>
          <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.lastMeetingLog')}
          </div>
          {/* <div className="meeting_report-header" style={{ textAlign: 'left' }}>
            {t('meetingReport.linkToMeetingNotes')}
          </div> */}
        </div>
        {meetings.length > 0 &&
          meetings.map((meeting, index) => {
            console.log(meeting)
            return (
              <div>
                <div className="divider" style={{ marginBottom: '0.1rem' }}></div>
                <div
                  className="meeting_report-inner-grid"
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                  }}
                >
                  <div>{meeting.meetingName}</div>
                  <div>{meeting.facilitatorName}</div>
                  <div>{getFrequency(meeting.frequency)}</div>
                  <div>
                    {
                      <div
                        style={{
                          backgroundColor:
                            meeting.status === 0 ? 'green' : meeting.status === 1 ? 'red' : 'grey',
                          width: '2rem',
                          height: '2rem',
                        }}
                      ></div>
                    }
                  </div>
                  {meeting.lastMeetingTs == 0 ? (
                    <div> -- </div>
                  ) : (
                    <div>{convertTimeStamToMMDDYYYY(meeting.lastMeetingTs)}</div>
                  )}
                  {meeting.lastMeetingCreatedBy == '' ? (
                    <div>--</div>
                  ) : (
                    <div>{meeting.lastMeetingCreatedBy}</div>
                  )}
                  {meeting.lastMeetingInstanceName == '' ? (
                    <div>--</div>
                  ) : (
                    <div
                      onClick={() => {
                        setMeetingInstanceId(meeting.lastMeetingInstanceId)
                        setIsFinalized(meeting.lastInstanceFinalized)
                        setMeetingName(meeting.meetingName)
                        setIsDecisionLogModalOpen(true)
                      }}
                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {meeting.lastMeetingInstanceName}
                    </div>
                  )}
                  {/* {meeting.lastMeetingInstanceName == '' ? (
                    <div>--</div>
                  ) : (
                    <div
                      onClick={() => {
                        navigateToMeetingNotes(meeting.lastMeetingInstanceId)
                      }}
                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('meetingReport.viewMeetingNotes')}
                    </div>
                  )} */}
                </div>
              </div>
            )
          })}
        <div className="divider" style={{ marginBottom: '0.1rem' }}></div>
      </div>
    </div>
  )
}

export default MeetingReportComponent
