import React, { useCallback } from 'react'
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow'
import 'reactflow/dist/style.css'
import { MarkerType } from 'reactflow'
import Swal from 'sweetalert2'

import { teamUpdateConnection } from '../../api/services/employee.services'

import CustomNode from './common/customNode.jsx'
import CustomNodeObjectiveMode from './common/customNodeObjectiveMode.jsx'

import DropDownEdge from './common/dropDownEdge'
import AddLevel1ObjButton from './common/addLevel1ObjButton'

import EmptyNode from './common/emptyNode'

import TopicNode from './common/topicNode'

import RecNode from './common/recNode'

import SetupAgentNode from './common/setupAgentNode'

import IngestionNode from './common/ingestionNode'

import RecSetupAgentL3Node from './common/recL3Node'

import ActionDecisionNode from './common/ActionDecisionNode'

const nodeTypes = {
  customNode: CustomNode,
  customNodeObjectiveMode: CustomNodeObjectiveMode,
  dropDownEdge: DropDownEdge,
  emptyNode: EmptyNode,
  topicNode: TopicNode,
  addLevel1ObjButton: AddLevel1ObjButton,
  recNode: RecNode,
  setupAgentNode: SetupAgentNode,
  recL3Node: RecSetupAgentL3Node,
  actionDecisionNode: ActionDecisionNode,
  IngestionNode: IngestionNode,
}

const onInit = (reactFlowInstance) => {}

const OverviewFlow = (props) => {
  const onConnect = useCallback(
    (params) => {
      params.type = 'buttonedge'
      params.markerEnd = {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#E91B51',
      }
      props.setEdges((eds) => addEdge(params, eds))
    },
    [props.setEdges],
  )

  const setData = (params) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want be Join both node!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      // reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        onConnect(params)
        updateConnection(params)
      }
    })
  }

  const updateConnection = async (params) => {
    const data = {
      teamLeadId: props.teamLeader,
      reportingTo: params.source,
      reported: params.target,
    }
    const result = await teamUpdateConnection(data)
    if (result) {
    }
  }

  return (
    <ReactFlow
      nodes={props.initialNodes}
      edges={props.initialEdges}
      onNodesChange={props.onNodesChange}
      onEdgesChange={props.onEdgesChange}
      // onConnect={setData}
      onInit={onInit}
      // fitView
      // attributionPosition="top-right"
      // edgeTypes={edgeTypes}

      nodeTypes={nodeTypes}
    >
      <Controls />
    </ReactFlow>
  )
}

export default OverviewFlow
