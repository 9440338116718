import React from 'react'
import { CardContainer, CardHeader } from './styles'
import styled from 'styled-components'
import edit from '../../../../assets/edit.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import plus from '../../../../assets/plus.svg'
import ManageObjectiveModal from './components/ManageObjectiveModal'
import EditMenuModal from './components/EditMenuModal'
import useDeleteHigherObjective from '../../../../api/mutation/deleteHigherObjective'
import ErrorModal from '../../../../components/ErrorModal'
import L1L2ObjectiveModal from '../../../../components/L1L2ObjectiveModal'
import { isCEO, isCXO } from '../../../../utils/auth'

const Grid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 0.8fr 0.3fr;
  row-gap: 0.5em;
  column-gap: 0.5rem;
  padding-top: 1rem;
  width: 100%;
`

const GridHeader = styled.div`
  font-weight: bold;
  justify-content: center;
  padding: 0.2rem 0.5rem;
`

const GridContent = styled.div`
  justify-content: center;
  padding: 0.2rem 0.6rem;
  word-break: break-word;
`

const AreaObjectiveCard = ({ areaObjectives = [], areaLeaders, enterpriseObjectives = [] }) => {
  const [objectivesToDisplay, setObjectivesToDisplay] = React.useState([])
  const [objective, setObjective] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isEditMenuOpen, setIsEditMenuOpen] = React.useState(false)
  const [indexToEdit, setIndexToEdit] = React.useState(null)
  const [isEdit, setIsEdit] = React.useState(false)
  const mutationDeleteHigherObjective = useDeleteHigherObjective()
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)

  const [leader, setLeader] = React.useState(null)

  //Display first leader objectives by default on load of data
  React.useEffect(() => {
    if (!leader && areaObjectives && areaLeaders?.length >= 1)
      setObjectivesToDisplay(areaLeaderObjectives[areaLeaders[0]?.eId])
    else setObjectivesToDisplay(areaLeaderObjectives[leader])
  }, [areaObjectives, areaLeaders, leader])

  const areaLeaderObjectives = {}

  for (let obj of areaObjectives) {
    if (obj.ownerId in areaLeaderObjectives) {
      areaLeaderObjectives[obj.ownerId].push(obj)
    } else {
      areaLeaderObjectives[obj.ownerId] = [obj]
    }
  }

  const handleOpenObjectiveModal = (objective, index = null) => {
    if (index !== null) {
      setIndexToEdit(index)
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setObjective(objective || {}) // Set empty object instead of null
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIndexToEdit(null)
    setObjective(null)
    setIsOpen(false)
  }

  const handleCloseEditMenuModal = () => {
    setIsEditMenuOpen(false)
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setObjectiveWidgetErrorMessage(null)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />

      <L1L2ObjectiveModal
        isOpen={isOpen}
        mode={isEdit ? 'edit' : 'add'}
        handleClose={handleCloseModal}
        objId={objective?.objId}
        objectiveType={'area'}
        leaders={[]}
        enterpriseObjectives={[]}
        isAreaDashboard={true}
        isCeo={isCEO || isCXO ? true : false}
        setEditedObj={() => {}}
        loadAgain={() => {}}
        objData={{}}
        setObjData={setObjective}
      />

      <CardContainer basis="100%">
        <CardHeader>Area Objectives</CardHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            width: '100%',
            position: 'relative',
          }}
        >
          <div className="underline-admin"></div>
        </div>
        <Grid>
          <GridHeader
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-start',
              paddingLeft: '0.1rem',
            }}
          >
            <GridHeader style={{ whiteSpace: 'nowrap', paddingLeft: '0' }}>
              Area objectives for Team Leader{' '}
            </GridHeader>
            <select
              style={{ marginBottom: '1rem' }}
              className="governance select"
              onChange={(e) => {
                setObjectivesToDisplay(areaLeaderObjectives[e.target.value])
                setLeader(e.target.value)
              }}
            >
              {areaLeaders?.map((leader, leaderIndex) => (
                <option value={leader.eId} key={`leader-${leaderIndex}`} id={leader.eId}>
                  {leader.name}
                </option>
              ))}
            </select>
          </GridHeader>
          <GridHeader style={{ paddingLeft: '0' }}>Linked to Enterprise objective</GridHeader>
          <GridHeader></GridHeader>
          {objectivesToDisplay?.map((obj, index) => (
            <React.Fragment key={`objectives-${index}`}>
              <GridContent style={{ paddingLeft: '0' }}>{obj.statement}</GridContent>
              <GridContent style={{ paddingLeft: '0' }}>
                {obj?.parentStatement[0]?.statement}
              </GridContent>
              <GridContent
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingLeft: '0',
                }}
              >
                {
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      handleOpenObjectiveModal(obj, index)
                    }}
                  >
                    <span class="material-symbols-outlined icon-size">edit</span>
                  </div>
                }
              </GridContent>
            </React.Fragment>
          ))}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
          <button
            type="button"
            className="fitted-button blue"
            style={{ background: 'white', padding: '0.4rem', height: '35px', fontWeight: 'bold' }}
            onClick={() => handleOpenObjectiveModal({}, null)}
          >
            ADD OBJECTIVE
          </button>
        </div>
      </CardContainer>
    </>
  )
}

export default AreaObjectiveCard
