import React, { useState } from 'react'

import TextArea from 'rc-textarea'
// import './customNode.scss'
// import ObjMapDropdownMenu from './objMapDropdownMenu'
// import { shortenName } from '../../../utils/general'
// import magic_wand from '../../../assets/magic.svg'
// import { getConsolidatedConfigSettings } from '../../../utils/auth'

function MindMapItemNode({ data, isConnectable }) {
  const [inputDescription, setInputDescription] = useState(data.label)
  // to avoid adding 'New Action: click to edit' to the discarded list
  const isNewItem = () => inputDescription?.trim() === 'New Action: click to edit'
  const isExternal = localStorage.getItem('tfex') === 'true'

  return (
    <div className="nodrag text-updater-node">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {data.isRecommended && (
            <div
              style={{
                width: '40px',
                color: 'black',
                padding: '2px',
                paddingLeft: '10px',
                fontSize: '14px',
                position: 'relative',
                top: '10px',
                border: '0px solid #cccccc',
                // borderTopLeftRadius: '20px',
                alignSelf: 'flex-start',
                borderRadius: '20px 0px 0px 0px',
                fontWeight: 'bold',
                backgroundColor: `#e5e7eb`,
              }}
            >
              AI
            </div>
          )}
          <div
            style={{
              width: '80px',
              color: 'black',
              padding: '2px',
              paddingLeft: '10px',
              fontSize: '14px',
              position: 'relative',
              top: '10px',
              border: '0px solid #cccccc',
              // borderTopLeftRadius: '20px',
              borderRadius: '0px 20px 0px 0px',
              fontWeight: 'bold',
              // alignSelf: 'flex-end',
              marginLeft: 'auto',
              backgroundColor: `${data.isRecommended ? '#e5e7eb' : data.borderColor}`,
            }}
            onClick={() => {
              if (data.isRecommended) return
              data.switchActionDecision(
                data.topicText,
                data.topicIndex,
                data.id,
                data.type,
                inputDescription,
              )
            }}
          >
            {data.type === 'action' ? 'Action' : data.type === 'comment' ? 'Comment' : 'Decision'}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '170px',
            textAlign: 'left',
            height: ' 120px',
            overflowY: 'auto',
            border: `2px solid ${data.isRecommended ? '#e5e7eb' : data.borderColor}`,
            padding: '10px',
            // borderTopRightRadius: '20px'
            boxShadow: data.isAddedInLastRound
              ? '0 0 5px #ff9b3e, 0 0 10px #ff9b3e, 0 0 15px #ff9b3e'
              : null,
            borderRadius: `${data.type === 'action' ? '0px 5px 0px 20px' : '5px 0px 20px 0px'}`,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '5px',
              boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
              borderRadius: '4px',
              backgroundColor: 'white',
              alignItems: 'center',
              height: '100px',
              // overflowY: 'auto',
            }}
          >
            <TextArea
              autoSize
              // rows={2}
              type="text"
              name="activity"
              style={{
                minHeight: '6rem',
                // height: '65px',
                maxHeight: '100px',
                width: '100%',
                resize: 'none',
                border: '1px solid rgb(238, 238, 238)',
                backgroundColor: 'rgba(238, 238, 238, 0.2)',
                borderRadius: '2.5px',
                fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
              }}
              onChange={(e) => {
                setInputDescription(e.target.value)
              }}
              onBlur={() => {
                data.updateMindMap(
                  data.topicText,
                  data.label,
                  inputDescription,
                  data.topicIndex,
                  data.id,
                  data.type,
                )
              }}
              onFocus={() => {
                data.lockMindMap()
              }}
              value={inputDescription}
              disabled={data.isRecommended}
            />
          </div>
        </div>
        {!isNewItem() && (
          <div
            style={{
              width: '25px',
              color: 'black',
              padding: '2px',
              paddingLeft: '10px',
              fontSize: '10px',
              position: 'relative',
              bottom: '15px',
              border: '0px solid #cccccc',
              borderRadius: '0px 0px 0px 20px',
              fontWeight: 'bold',
              backgroundColor: `${data.isRecommended ? '#e5e7eb' : data.borderColor}`,
              alignSelf: 'flex-start',
            }}
            onClick={() => data.removeItem(inputDescription, data.topicIndex, data.index, data.id)}
          >
            <span class="material-symbols-outlined" style={{ fontSize: '20px' }}>
              close
            </span>
          </div>
        )}
        {data.readOnly && (
          <div
            style={{
              width: '25px',
              color: 'black',
              padding: '2px',
              paddingLeft: '10px',
              fontSize: '10px',
              position: 'relative',
              bottom: '40px',
              border: '0px solid #cccccc',
              borderRadius: '0px 0px 20px 00px',
              fontWeight: 'bold',
              backgroundColor: `${data.isConverted ? '#AAAFB4' : data.isRecommended ? '#e5e7eb' : data.borderColor}`,
              alignSelf: 'flex-end',
            }}
            onClick={() => {
              if (data.isConverted) return
              data.forwardOpenModal(data.topicIndex, data.id, data.type, data.isRecommended)
            }}
          >
            <span class="material-symbols-outlined" style={{ fontSize: '20px' }}>
              forward
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MindMapItemNode
