import React from 'react'
import { useGovernanceContext } from '../../../context/governanceContext'
import employee from '../../../../../assets/employee.svg'
import AddEditEmployeeForm from './addEditEmployee'
import { objTitle } from '../../../../../components/ObjectiveDetails/styles'
import Modal from '../../../../../components/Modal'

const AddEditEmployeeModal = ({ setRefetchData }) => {
  const { state, dispatch } = useGovernanceContext()

  const [title, setTitle] = React.useState('')

  React.useEffect(() => {
    if (state.editCeoLeaderIndex !== null) {
      setTitle('Edit CEO')
    } else if (state.editAreaLeaderIndex !== null) {
      setTitle('Edit Area Leader')
    } else if (state.editCsuiteLeaderIndex !== null) {
      setTitle('Edit C-suite Leader')
    } else if (state.editTeamLeaderIndex !== null) {
      setTitle('Edit Team Leader')
    } else if (state.editTeamMemberIndex !== null) {
      setTitle('Edit Team Member')
    } else {
      if (state.role === 'ceo') {
        setTitle('Add CEO')
      } else if (state.role === 'csuite') {
        setTitle('Add C-suite Leader')
      } else if (state.role === 'areaLeader') {
        setTitle('Add Area Leader')
      } else {
        setTitle('Add Team Leader')
      }
    }
  }, [state.isEmployeeModalOpen === true])

  const [resetForm, setResetForm] = React.useState(null)

  const handleCancel = () => {
    dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
    resetForm()
  }

  return (
    <>
      {state.isEmployeeModalOpen === true && (
        <Modal title={title} closeModal={handleCancel} width={'25rem'}>
          <AddEditEmployeeForm setResetForm={setResetForm} setRefetchData={setRefetchData} />
        </Modal>
      )}
    </>
  )
}

export default AddEditEmployeeModal
