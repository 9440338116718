import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../../components/CustomMultipleSelect/singleOption'
import { getMeetingTopic } from '../../../../../../api/services/meetingTopic.services'
import { meetingViewModesEnum } from '../../../../../../utils/enumValues'
import InPageLoader from '../../../../../../components/InPageLoader'
import Toast from '../../../../../../common/toast'
import { meetingMilestoneUpdate } from '../../../../../../api/services/meetingTopic.services'
import { postHttpRequest } from '../../../../../../api/query/dynamicAPI'

const ActionTopicUpdateModal = ({
  umtId,
  milstoneId,
  closeModal,
  loadAgain = () => {},
  umt,
  isFromTopic = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [availableTopics, setAvailableTopics] = React.useState([])
  const [newSelectedTopic, setNewSelectedTopic] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMerging, setIsMerging] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await getAvailableTopics()
    })()
  }, [])

  const getAvailableTopics = async () => {
    try {
      const result = await getMeetingTopic(
        false,
        false,
        undefined,
        meetingViewModesEnum.ACTION_VIEW,
        undefined,
        true,
      )
      if (result) {
        let finalArray = []

        finalArray = finalArray.concat(result.blockedObjectives)
        finalArray = finalArray.concat(result.decisionObjectives)
        finalArray = finalArray.concat(result.updateObjectives)
        finalArray = finalArray.concat(result.adminTopicObjectives)
        const topicsList = (finalArray || []).map((item) => ({
          umtDescription: item.actionNeeded,
          umtId: item.umtId,
          objDescription: item.statement,
          objId: item.objectiveId,
        }))

        // if it's from card, then it's possible that umt is a resolved topic.
        if (isFromTopic) {
          setNewSelectedTopic(umt)
        } else {
          setNewSelectedTopic(topicsList.find((topic) => topic.umtId === umtId))
        }

        topicsList.sort((a, b) => a.umtDescription.localeCompare(b.umtDescription))

        setAvailableTopics(topicsList)
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    }
    setIsLoading(false)
  }

  const updateTopic = (newSelectedTopic) => {
    setNewSelectedTopic(newSelectedTopic)
  }

  const updateMilestone = async (newTopic) => {
    try {
      let postData = {
        umtId: newTopic.umtId,
        goalId: newTopic.objId,
      }
      const result = await meetingMilestoneUpdate(milstoneId, postData)
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    }
  }

  const mergeTopic = async (newTopic) => {
    try {
      await postHttpRequest('/transfer_to_new_topic', {
        newTopic: newTopic,
        currentTopic: umt,
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    }
    setIsMerging(false)
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={true}
      width="60%"
      modalContainerStyle={{ width: '90%' }}
      showCard={true}
      title={t('topicSelectionModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      {isFromTopic && (
        <div style={{ marginBottom: '1rem', textAlign: 'left', fontSize: '13px' }}>
          {t('Common:mergeTopicWarning')}
        </div>
      )}
      {!isLoading && newSelectedTopic && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
            width: '100%',
          }}
        >
          {isFromTopic && (
            <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
              <span style={{ display: 'flex', width: '4rem', alignItems: 'center' }}>
                {t('Common:filter.from')}:
              </span>
              <SelectField
                name="currentTopic"
                value={umt}
                getOptionLabel={(option) => option.umtDescription}
                getOptionValue={(option) => option.umtId}
                width={'45%'}
                menuPosition="fixed"
                isDisabled={true}
              />
            </div>
          )}
          <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
            <span style={{ display: 'flex', width: '4rem', alignItems: 'center' }}>
              {t('Common:filter.to')}:
            </span>
            <SelectField
              name="selectTopic"
              value={newSelectedTopic}
              options={availableTopics}
              onChange={updateTopic}
              getOptionLabel={(option) => option.umtDescription}
              getOptionValue={(option) => option.umtId}
              width={'45%'}
              menuPosition="fixed"
            />
          </div>

          <div
            className="sleek-button sleek-blue"
            onClick={async () => {
              if (newSelectedTopic.umtId !== umtId) {
                if (!isFromTopic) {
                  await updateMilestone(newSelectedTopic)
                  loadAgain()
                } else {
                  console.log('merge')
                  setIsMerging(true)
                  await mergeTopic(newSelectedTopic)
                  loadAgain()
                }
              }

              closeModal(true)
            }}
            style={{ marginLeft: 'auto' }}
          >
            {isMerging ? (
              <span className="loading-spinner" style={{ width: '1rem', height: '1rem' }}></span>
            ) : (
              <span>
                {isFromTopic
                  ? t('Common:commonButtons:mergeTopicButton')
                  : t('Common:commonButtons.saveSmall')}
              </span>
            )}
          </div>
        </div>
      )}
      {isLoading && <InPageLoader inComponent={true} />}
    </Modal>
  )
}

export default ActionTopicUpdateModal
