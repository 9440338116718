import Modal from '../../../../components/Modal'
import { Formik, Field, Form } from 'formik'
import ResolveBlockerSchema from '../../../../utils/validationSchemas/ResolveBlockerSchema'
import ModalSaveButton from '../../../../components/ModalSubmitButton'
import { colors } from '../../../../utils/colors'
import moment from 'moment'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const ResolveMeetingTopicModal = ({ isModalOpen, closeModal, meetingTopic }) => {
  const { dispatch } = useTeamLeaderContext()
  const { t } = useTranslation(['Common'])

  const handleSubmit = (closedTime) => {
    let resolvedMT = { ...meetingTopic }
    resolvedMT.mtId = meetingTopic.mtId
    resolvedMT.closedTime = closedTime
    resolvedMT.isOpen = false

    dispatch({ type: 'EDIT_MEETING_TOPIC', meetingTopic: { ...resolvedMT } })
  }

  return (
    <>
      <Modal
        title={t('resolveMeetingTopicsModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Formik
          enableReinitialize
          initialValues={{
            closedTime: moment(new Date()).format('YYYY-MM-DD'),
          }}
          validationSchema={Yup.object().shape({
            closedTime: Yup.date().required(t('required')).nullable(),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values.closedTime)
            closeModal()
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, setFieldValue }) => (
            <Form className="objective-form">
              <div>
                <div className="form-field" style={{ alignItems: 'center' }}>
                  <Field
                    type="date"
                    id="closedTime"
                    name="closedTime"
                    // in case we decide to bring on a limit
                    // max={yyyymmddNow()}
                  />
                  {errors.closedTime && touched.closedTime && (
                    <div className="validation-error">{errors.closedTime}</div>
                  )}

                  <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                    <button
                      className="fitted-button modal-save-button blue"
                      onClick={() => {
                        resetForm()
                        closeModal()
                      }}
                      type="button"
                      color={colors.grey}
                    >
                      {t('commonButtons.cancel')}
                    </button>
                    <ModalSaveButton />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ResolveMeetingTopicModal
