import React from 'react'
import { KpiReportByLeader } from '../../../KpiReportByLeader'
import { hasRole } from '../../../../utils/auth'
import { getConsolidatedConfigSettings } from '../../../../utils/auth'
import { useOutletContext } from 'react-router-dom'

const MeetingHubKpiReport = () => {
  // need to to reset MH view to default
  const { setResetViewMode } = useOutletContext()
  React.useEffect(() => {
    setResetViewMode((p) => p + 1)
  }, [])

  return <KpiReportByLeader hideTitle={true} />
}

export default MeetingHubKpiReport
