import React from 'react'
import Modal from '../../../../components/Modal'
import ModalSaveButton from '../../../../components/ModalSubmitButton'
import { useTranslation } from 'react-i18next'

const TeamLeaderSelectorModal = ({
  teamLeadersData,
  eIdsOfSelectedTls,
  setEIdsOfSelectedTls,
  closeModal,
  updateRefreshIndex,
}) => {
  const { t } = useTranslation(['MeetingHub'])
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState(
    teamLeadersData?.employees.map((teamLeader, index) =>
      eIdsOfSelectedTls.includes(teamLeader.eId),
    ),
  )

  const updateSelection = () => {
    let newEIdsOfSelectedTls = []
    for (let i = 0; i < teamLeadersData.employees.length; i++) {
      if (isChecked[i] === true) {
        newEIdsOfSelectedTls.push(teamLeadersData.employees[i].eId)
      }
    }
    setEIdsOfSelectedTls(newEIdsOfSelectedTls)
    updateRefreshIndex()
    closeModal()
  }

  const handleCheckBoxClick = (index, e) => {
    let newChecks = [...isChecked]
    newChecks[index] = e.target.checked
    setIsChecked(newChecks)
  }

  const selectAll = () => {
    let newChecks = isChecked.map((val, i) => true)
    setIsChecked(newChecks)
  }

  const clearAll = () => {
    let newChecks = isChecked.map((val, i) => false)
    setIsChecked(newChecks)
  }

  const submit = () => {
    if (isChecked.every((val) => val === false)) {
      setErrorMessage(
        'Please select one or more team leaders. The scorecard metrics and decisions will be updated for only those selected.',
      )
    } else {
      updateSelection()
    }
  }
  React.useEffect(() => {
    if (isChecked.every((val) => val === false)) {
      selectAll()
    }
  }, [])

  React.useEffect(() => {
    setErrorMessage(null)
  }, [isChecked])

  return (
    <>
      <Modal
        title={t('teamLeadersFilter.title')}
        closeModal={() => {
          closeModal()
        }}
        width={'35rem'}
      >
        <div style={{ textAlign: 'left' }}>
          {t('teamLeadersFilter.subtitle')}
          <br />
          <br />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            &nbsp;
            <span
              style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
              onClick={() => {
                selectAll()
              }}
            >
              {t('Common:filter.all')}
            </span>{' '}
            &nbsp;| &nbsp;
            <span
              style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
              onClick={() => {
                clearAll()
              }}
            >
              {t('Common:filter.clear')}
            </span>
            &nbsp;
          </div>
          <br />
          <br />
          {teamLeadersData?.employees.map((teamLeader, index) => (
            <>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                {teamLeader.name}

                <input
                  className="capacity-checkbox"
                  type="checkbox"
                  checked={isChecked[index]}
                  onChange={(e) => handleCheckBoxClick(index, e)}
                  value={teamLeader.eId}
                />
                <br />
              </div>
            </>
          ))}
        </div>
        <div className="validation-error">{errorMessage}</div>
        <div className="modal-dual-btn" style={{ gap: '1rem' }}>
          <button
            className="fitted-button modal-save-button blue"
            onClick={() => {
              closeModal()
            }}
            type="button"
          >
            {t('Common:commonButtons.cancel')}
          </button>
          <ModalSaveButton
            onClick={() => {
              submit()
            }}
            tag={t('Common:filter.applyFilter')}
          />
        </div>
      </Modal>
    </>
  )
}

export default TeamLeaderSelectorModal
