import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import { useTranslation } from 'react-i18next'
import Toast from '../../../common/toast'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { shortenName } from '../../../utils/general'
import Swal from 'sweetalert2'
import AccessKPIDashboardModal from '../AccessKPIDashboardModal'
import SelectKPIsModal from '../SelectKPIsModal'

const ConfigureKPIDashboardModal = ({ closeModal }) => {
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [kpiDashboardList, setKpiDashboardList] = useState(null)
  const [kpiDashboardListOriginal, setKpiDashboardListOriginal] = useState([])
  const [selectedKpiDashboard, setSelectedKpiDashboard] = useState(null) //only used for access modal and select kpis modal
  const [isAccessKpiDashboardModalOpen, setIsAccessKpiDashboardModalOpen] = useState(false)
  const [isSelectKPIsModalOpen, setIsSelectKPIsModalOpen] = useState(false)

  console.log('kpiDashboardList', kpiDashboardList)

  React.useEffect(() => {
    loadKpiDashboardList()
  }, [])

  async function loadKpiDashboardList() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(`/get_manage_kpi_dashboard_list`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (data?.kpiDashboards) {
        setKpiDashboardList(data.kpiDashboards)
        setKpiDashboardListOriginal(JSON.parse(JSON.stringify(data.kpiDashboards)))
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
        //title: API_ERROR_MESSAGE,
      })
    }
    setIsLoading(false)
  }

  async function createKpiDashboard() {
    setIsLoading(true)
    try {
      const data = await postHttpRequest(
        `/add_edit_delete_kpi_dashboard`,
        {
          operation: 'add',
          kpiDashboardId: null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (data?.success === true) {
        loadKpiDashboardList()
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
        //title: API_ERROR_MESSAGE,
      })
    }

    setIsLoading(false)
  }

  async function editKpiDashboard(kpiDashboardId, kpiDashboardName) {
    for (let i = 0; i < kpiDashboardListOriginal.length; i++)
      if (kpiDashboardId === kpiDashboardListOriginal[i].kpiDashboardId) {
        if (kpiDashboardName !== kpiDashboardListOriginal[i].kpiDashboardName) {
          break
        } else {
          return
        }
      }

    try {
      const data = await postHttpRequest(
        `/add_edit_delete_kpi_dashboard`,
        {
          operation: 'edit',
          kpiDashboardId: kpiDashboardId,
          kpiDashboardName: kpiDashboardName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    } catch (e) {
      Toast.fire({
        icon: 'error',
        //title: API_ERROR_MESSAGE,
      })
    }
  }

  async function deleteKpiDashboard(kpiDashboardId) {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteKPIDashboard'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })
    if (result.isConfirmed) {
      setIsLoading(true)
      try {
        const data = await postHttpRequest(
          `/add_edit_delete_kpi_dashboard`,
          {
            operation: 'delete',
            kpiDashboardId: kpiDashboardId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (data?.success === true) {
          loadKpiDashboardList()
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Toast.fire({
          icon: 'error',
          //title: API_ERROR_MESSAGE,
        })
      }
    }
  }

  function formatNames(accessList) {
    if (!accessList || accessList.length === 0) {
      return ''
    }
    // Sort names alphabetically
    const sortedNames = [...accessList]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((employee) => shortenName(employee.name))

    // Take first two names
    const firstTwoNames = sortedNames.slice(0, 2)

    // Calculate remaining count
    const remainingCount = Math.max(0, sortedNames.length - 2)

    // Format based on number of names
    if (sortedNames.length <= 2) {
      return firstTwoNames.join(' & ')
    }

    return `${firstTwoNames.join(' , ')} + ${remainingCount}`
  }

  return (
    <>
      {isAccessKpiDashboardModalOpen && (
        <AccessKPIDashboardModal
          closeModal={() => {
            setIsAccessKpiDashboardModalOpen(false)
          }}
          kpiDashboardId={selectedKpiDashboard.kpiDashboardId}
          currentAccessList={selectedKpiDashboard.accessList}
          loadModalBackground={loadKpiDashboardList}
        />
      )}

      {isSelectKPIsModalOpen && (
        <SelectKPIsModal
          kpiDashboardId={selectedKpiDashboard.kpiDashboardId}
          kpiDashboardName={selectedKpiDashboard.kpiDashboardName}
          closeModal={() => {
            setIsSelectKPIsModalOpen(false)
          }}
        />
      )}

      <Modal
        style={{ zIndex: 102 }}
        title={t('kpi.configureYourKpiDashboard')}
        closeModal={closeModal}
        width={'80rem'}
        titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
        modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
      >
        {isLoading && <InPageLoader inComponent={true} isLoading={isLoading} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {t('kpi.new')}
                <div
                  className="fitted-button blue tooltip"
                  onClick={() => {
                    createKpiDashboard()
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                </div>
              </div>
            </div>

            {kpiDashboardList?.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <span
                  style={{
                    alignSelf: 'flex-start',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1.5rem',
                  }}
                >
                  {/* <b>{t('kpi.kpiStatus')}</b> */}
                </span>
                <div className="divider"></div>

                <div style={{ marginBottom: '3rem', paddingTop: '2rem' }}>
                  <div className="kpi-dashboard-configuration-table">
                    <div className="kpi-status-table-header">{t('kpi.dashboardName')}</div>
                    <div className="kpi-status-table-header">{t('kpi.access')}</div>
                    <div className="kpi-status-table-header"></div>

                    {kpiDashboardList?.map((kpiDashboard, kpiIndex) => {
                      return (
                        <>
                          <React.Fragment key={`objective-${kpiDashboard.kpiId}`}>
                            <>
                              <div className="kpi-status-table-cell" style={{ textAlign: 'left' }}>
                                <input
                                  onBlur={() => {
                                    editKpiDashboard(
                                      kpiDashboard.kpiDashboardId,
                                      kpiDashboard.kpiDashboardName,
                                    )
                                  }}
                                  onChange={(e, index) => {
                                    let tempKpiDashboardList = [...kpiDashboardList]
                                    for (let i = 0; i < tempKpiDashboardList?.length; i++) {
                                      if (
                                        tempKpiDashboardList[i].kpiDashboardId ===
                                        kpiDashboard.kpiDashboardId
                                      ) {
                                        tempKpiDashboardList[i].kpiDashboardName = e.target.value
                                      }
                                    }
                                    setKpiDashboardList([...tempKpiDashboardList])
                                  }}
                                  value={kpiDashboard.kpiDashboardName}
                                />
                              </div>
                              <div
                                className="kpi-status-table-cell"
                                style={{
                                  textAlign: 'left',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {/* {kpiDashboard.accessList.map((employee, employeeIndex) =>
                                  shortenName(employee.name)
                                )} */}
                                {formatNames(kpiDashboard?.accessList)}
                              </div>
                              <div
                                className="kpi-status-table-cell"
                                style={{
                                  textAlign: 'left',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    display: 'flex',

                                    gap: '0.5rem',
                                    textAlign: 'left',
                                    padding: '0',
                                  }}
                                >
                                  <div
                                    className="fitted-button red tooltip"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                      deleteKpiDashboard(kpiDashboard.kpiDashboardId)
                                    }}
                                  >
                                    <span className="material-symbols-outlined">delete</span>
                                  </div>

                                  <div
                                    className="fitted-button blue tooltip"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                      setSelectedKpiDashboard(kpiDashboard)
                                      setIsAccessKpiDashboardModalOpen(true)
                                    }}
                                  >
                                    <span className="material-symbols-outlined">person_add</span>
                                  </div>

                                  <div
                                    className="fitted-button blue tooltip"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                      setSelectedKpiDashboard(kpiDashboard)
                                      setIsSelectKPIsModalOpen(true)
                                    }}
                                  >
                                    <span className="material-symbols-outlined">edit</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          </React.Fragment>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* {selectedKpiUpdate && updatesList.length === 0 && (
              <div>{t('kpi.noKPIUpdatesMessage')}</div>
            )} */}

            {(kpiDashboardList?.length <= 0 || !kpiDashboardList) && kpiDashboardList !== null && (
              <div style={{ paddingTop: '1rem' }}>{t('kpi.noKPIDashboardsMessage')}</div>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

export default ConfigureKPIDashboardModal
