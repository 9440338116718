import React, { useState, useEffect, useRef } from 'react'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { KpiReportByLeaderV2 } from '../../KpiReportByLeaderV2'

const ChangesReportKPIs = () => {
  const { t } = useTranslation(['ChangesReport', 'Common', 'ObjectiveMap'])
  const [selectedDaysForShowingWorseKpis, setSelectedDaysForShowingWorseKpis] = useState(7)

  const handleFilter = (days) => {
    setSelectedDaysForShowingWorseKpis(days)
  }

  return (
    <>
      <div>
        {/* <div
          className="button-container"
          style={{ paddingLeft: '2rem', marginTop: '1rem', marginBottom: '2rem' }}
        >
          <div
            className="button"
            onClick={() => handleFilter(7)}
            style={{
              backgroundColor: selectedDaysForShowingWorseKpis == 7 ? '#4472c4' : '#a5a5a5',
            }}
          >
            {t('ChangesReport:past7Days')}
          </div>
          <div
            className="button"
            onClick={() => handleFilter(14)}
            style={{
              backgroundColor: selectedDaysForShowingWorseKpis == 14 ? '#4472c4' : '#a5a5a5',
            }}
          >
            {t('ChangesReport:past14Days')}
          </div>
          <div
            className="button"
            onClick={() => handleFilter(30)}
            style={{
              backgroundColor: selectedDaysForShowingWorseKpis == 30 ? '#4472c4' : '#a5a5a5',
            }}
          >
            {t('ChangesReport:past30Days')}
          </div>
        </div> */}
        <KpiReportByLeaderV2
          hideTitle={true}
          selectedDaysForShowingWorseKpis={selectedDaysForShowingWorseKpis}
        />
      </div>
    </>
  )
}
export default ChangesReportKPIs
