import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { employeeMatching, finalizeIngestionData } from '../../api/services/objectives.services'
import Select from 'react-select'
import InPageLoader from '../InPageLoader'
import useHigherObjectives from '../../api/query/useHigherObjectives'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'

const SaveIngestionModal = ({ isModalOpen, closeModal, objectiveList, ingestId }) => {
  const {
    data: higherObjData,
    isLoading: isHigherObjLoading,
    error: higherObjError,
  } = useHigherObjectives()
  const { t } = useTranslation(['ObjectiveMap', 'commonButtons'])
  // const [newEmployeeWithIdInfo, setNewEmployeeWithIdInfo] = useState(
  //     newEmployeeWithId?.map((employee) => {
  //         return {
  //             name: employee.name,
  //             id: employee.id,
  //             gpt_name: employee.name,
  //         }
  //     }),
  // )
  const [employeeMatchingResult, setEmployeeMatchingResult] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isSavingObjectives, setIsSavingObjectives] = useState(false)
  const [newObjectives, setNewObjectives] = useState([])
  const [level2Cards, setLevel2Cards] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    getEmployeeMathcing()
  }, [])

  const formatInputObjectives = () => {
    console.log('objectiveList', objectiveList)
    if (!objectiveList?.levelCards) return []

    const level2Cards = objectiveList.levelCards.filter((obj) => obj.level === 1)

    const level2CardsDisplayGroups = level2Cards[0].cardDisplayGroups

    if (level2CardsDisplayGroups.length === 0) return []

    const level2CardsFlat = level2CardsDisplayGroups
      .map((group) =>
        group.cards.map((card) => ({
          description: card.goal,
          owner: card.owner,
          level: 2,
          realEId: card.realEId,
        })),
      )
      .flat(Infinity)

    console.log('level2CardsFlat', level2CardsFlat)
    setLevel2Cards(level2CardsFlat)

    const level2GoalIdNameMap = {}

    level2CardsDisplayGroups.forEach((group) => {
      group.cards.forEach((card) => {
        level2GoalIdNameMap[card.id] = card.goal
      })
    })

    console.log('level2GoalIdNameMap', level2GoalIdNameMap)

    const level3Cards = objectiveList.levelCards.filter((obj) => obj.level === 2)

    if (level3Cards.length === 0) return []

    const cardDisplayGroups = level3Cards[0].cardDisplayGroups

    if (cardDisplayGroups.length === 0) return []

    const level3CardFlat = cardDisplayGroups
      .map((group) =>
        group.cards.map((card) => ({
          description: card.goal,
          owner: card.owner,
          level: 3,
          parentDescription: level2GoalIdNameMap[card.parentId],
          realEId: card.realEId,
        })),
      )
      .flat(Infinity)

    return [...level3CardFlat]
  }

  const handleNameChange = (index, employee, event) => {
    setSelectedEmployee({
      ...selectedEmployee,
      [event.value]: event,
    })
    const originalObjectives = [...newObjectives]
    let obj = originalObjectives[index]
    obj.realEId = event.value
    setNewObjectives(originalObjectives)
  }

  const saveObjectives = async () => {
    const allObjectives = [...level2Cards, ...newObjectives]
    console.log('allObjectives', allObjectives)
    let res = await finalizeIngestionData(allObjectives, ingestId)
    console.log('res', res)
    closeModal()
    navigate(`/${routes.objectivemap}`)
  }

  const getEmployeeMathcing = async () => {
    // console.log('newEmployeeWithIdInfo', newEmployeeWithIdInfo)
    // const result = await employeeMatching(newEmployeeWithIdInfo?.map((employee) => employee.name), true)
    // setEmployeeMatchingResult(result)
    // let selectedEmployee = {}
    // let newmapping = []
    // for (const key in result) {
    //     selectedEmployee[result[key][0].eid] = {
    //         label: result[key][0].name,
    //         value: result[key][0].eid,
    //     }
    //     let employee = newEmployeeWithIdInfo.find((employee) => employee.name === key)
    //     if (employee) {
    //         let newEmployeeMapping = {
    //             ...employee,
    //             eid: result[key][0].eid,
    //             name: result[key][0].name,
    //         }
    //         newmapping.push(newEmployeeMapping)
    //     }
    // }
    let objectives = formatInputObjectives()
    console.log('objectives', objectives)
    const objectivesEidMap = objectives.map((objective) => {
      return {
        ...objective,
        // realEId: newmapping.find((employee) => employee.gpt_name === objective.owner).eid,
        fakeOwner: objective.owner,
      }
    })
    setNewObjectives(objectivesEidMap)
    // setNewEmployeeWithIdInfo(newmapping)
    setSelectedEmployee(selectedEmployee)
    setIsLoading(false)
  }

  const handleEnterpriseObjectiveChange = (event, objectiveDescription) => {
    console.log(objectiveDescription)
    const originalObjectives = [...level2Cards]
    let obj = originalObjectives.find((obj) => obj.description === objectiveDescription)
    obj.parent = event.value
    console.log(originalObjectives)
    setLevel2Cards(originalObjectives)
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="70rem"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={t('ObjectiveMap:modalTitle.mapToCompanyStrategy')}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
          <span>{t('ObjectiveMap:setUpMessages.companyStrategyMapping')}</span>
        </div>
        <div className="review-generated-topics-list" style={{ marginTop: '1.3rem' }}>
          <div className="review-generated-objective-employee-list-row ">
            <div
              className="review-generated-topics-list-header"
              style={{ paddingLeft: '0.5rem', fontSize: '1.3rem' }}
            >
              {t('ObjectiveMap:setUpMessages.objectives')}
            </div>
            <div
              className="review-generated-topics-list-header"
              style={{ fontSize: '1.3rem', width: '35rem' }}
            >
              {t('ObjectiveMap:setUpMessages.companyStrategy')}
            </div>
          </div>
          {isLoading && <InPageLoader inComponent={true} />}

          {!isLoading &&
            level2Cards.map((obj, index) => {
              console.log('obj', obj)
              return (
                <div key={index} className="review-generated-objective-employee-list-row ">
                  <div className="review-generated-topics-list-item">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {obj.description}
                    </div>
                  </div>
                  <div className="review-generated-topics-list-item">
                    {
                      <Select
                        options={higherObjData?.enterpriseObjectives.map((obj) => {
                          return {
                            value: obj.objId,
                            label: obj.statement,
                          }
                        })}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '35rem',
                            fontSize: '1.3rem',
                            textAlign: 'left',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            position: 'absolute',
                            zIndex: 9999,
                            width: '35rem',
                            left: '0',
                            top: '100%',
                            textAlign: 'left',
                          }),
                          option: (provided) => ({
                            ...provided,
                            textAlign: 'left',
                          }),
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            textAlign: 'left',
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        onChange={(e) => handleEnterpriseObjectiveChange(e, obj.description)}
                        placeholder={t('Common:filter.select') + '...'}
                      />
                    }
                  </div>
                  <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
                </div>
              )
            })}

          {/* {!isLoading &&
                        newObjectives.map((obj, index) => {
                            let employee = obj.fakeOwner
                            return (
                                <div key={index} className="review-generated-objective-employee-list-row ">
                                    <div className="review-generated-topics-list-item">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {obj.description}
                                        </div>
                                    </div>
                                    <div className="review-generated-topics-list-item">
                                        {employeeMatchingResult && (
                                            <Select
                                                options={employeeMatchingResult[employee]?.map((employee) => {
                                                    return {
                                                        value: employee.eid,
                                                        label: employee.name,
                                                    }
                                                })}
                                                value={selectedEmployee[obj.realEId]}
                                                onChange={(selectedOption) =>
                                                    handleNameChange(index, employee, selectedOption)
                                                }
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '20rem',
                                                        fontSize: '1.3rem',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        position: 'absolute',
                                                        zIndex: 9999,
                                                        width: '20rem',
                                                        left: '0',
                                                        top: '100%',
                                                    }),
                                                    menuPortal: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                menuPortalTarget={document.body}
                                                menuPosition="absolute"
                                            />
                                        )}
                                    </div>
                                    <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
                                </div>
                            )
                        })} */}
        </div>
        {!isLoading && (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <div
                onClick={saveObjectives}
                className="sleek-button sleek-full-blue"
                style={{ fontSize: '1rem', height: '2rem', cursor: 'pointer' }}
              >
                {isSavingObjectives && (
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '1rem',
                      width: '0.5rem',
                      height: '0.5rem',
                      margin: '0',
                      borderTopColor: 'white',
                    }}
                    className="loading-spinner"
                  ></span>
                )}
                {t('Common:commonButtons.save')}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default SaveIngestionModal
