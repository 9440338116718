// MeetingRecordingProvider.js
import React, { useReducer, useRef, useEffect } from 'react'
import BrainstormReducer, { defaultState } from './BrainstormReducer'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import useLock from '../../../common/useLock'
import ReactFlow, { ReactFlowProvider, useReactFlow } from '@xyflow/react'

const CHUNK_DURATION = 500
const SEND_INTERVAL = 10000
const numChunksIn2seconds = (15 * 1000) / (CHUNK_DURATION * 1.0)
const TOPIC_GENERATION_INTERVAL = 25000
const QUEUE_PEEK_INTERVAL = 15000
const DEVIATED_CONVERSATION_DETECTION_INTERVAL = 60000
const SHORT_TERM_SUMMARY_INTERVAL = 20000
const INITIAL_DETECT_DEVIATION_DELAY = 0

const BrainstormContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
  startRecording: () => Promise.resolve(),
  stopRecording: () => Promise.resolve(),
  generateProblems: () => Promise.resolve(),
  exploreProblems: () => Promise.resolve(),
  // revertBrainstormMap: () => Promise.resolve(),
  accumulateDataChunks: () => {},
  sendAudioData: () => Promise.resolve(),
  onAudioChunkOutput: () => {},
  generateClarifications: () => Promise.resolve(),
  detectDeviatedConversation: () => Promise.resolve(),
  generateMMRecommendation: () => Promise.resolve(),
})

const BrainstormProvider = ({ children }) => {
  const { t } = useTranslation(['Common'])

  const [state, dispatch] = useReducer(BrainstormReducer, defaultState)

  const mediaRecorderRef = useRef(null)
  const audioContextRef = useRef(null)
  const chunksRef = useRef([])
  const headerChunkRef = useRef(null)
  const sendingIntervalRef = useRef(null)
  const dataIntervalRef = useRef(null)
  const problemGenerationIntervalRef = useRef(null)
  const displayStreamRef = useRef(null)
  const audioStreamRef = useRef(null)
  const timeoutRef = useRef(null)
  const stateRef = useRef(state)
  const isProcessingDataQueue = useRef(false)
  const dataQueue = useRef([])
  const deviatedConversationDetectionTimeoutRef = useRef(null)
  const mindMapRecommendationTimeoutRef = useRef(null)
  const shortTermSummaryIntervalRef = useRef(null)
  const topicsGenerationIntervalRef = useRef(null)
  // const isReverting = useRef(false)
  const isGeneratingProblems = useRef(false)
  const numCallsToProblemGeneration = useRef(0)
  const lastAudioApiCall = useRef(0)
  const [counter, setCounter] = React.useState(0)
  const [selectedQuestionNumber, setSelectedQuestionNumber] = React.useState(-1)
  const sendAduioTimeoutRef = useRef(null)
  const initialDelayRef = useRef(null)
  // const isUpdatingMMRef = useRef(false)

  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const { requestQueueAccess, lockAccess, releaseLock } = useLock()

  const sourceIdEventSource = useRef(null)

  const { setCenter, getNodes, getNodesBounds, fitBounds, fitView } = useReactFlow()

  function findNewUuids(actionPlans, existingElements) {
    const existingIds = new Set(existingElements.map((element) => element.data.id))

    const actionPlanIds = new Set()

    // Helper function to extract IDs from an action plan
    function extractIds(plan) {
      ;['comments', 'decisions', 'actions'].forEach((type) =>
        plan[type]?.forEach((item) => item.id && actionPlanIds.add(item.id)),
      )
    }

    // Process all action plans
    actionPlans.forEach(extractIds)

    // Find IDs that are in actionPlanIds but not in existingIds
    return [...actionPlanIds].filter((id) => !existingIds.has(id))
  }

  const handleZoomToRandomNode = (uuidList) => {
    // Filter nodes to those whose data.id is in the provided UUID list
    const initialNodes = getNodes()
    const filteredNodes = initialNodes.filter((node) => uuidList.includes(node.data.id))

    if (filteredNodes.length === 0) {
      return
    }

    // Pick a random node from the filtered list
    const randomNode = filteredNodes[Math.floor(Math.random() * filteredNodes.length)]

    if (randomNode) {
      const { x, y } = randomNode.position

      setCenter(x, y, { zoom: 1.4, duration: 800 })

      // After 4 seconds, we need to reset the view to show everything.
      setTimeout(() => {
        fitViewToImportantNodes()
      }, 4000)
    }
  }

  function markNewItems(actionPlans, newUUIDs) {
    // Convert UUIDs array to Set for O(1) lookup
    const newIdsSet = new Set(newUUIDs)

    // Create deep copy of action plans to avoid mutating original
    const updatedActionPlans = JSON.parse(JSON.stringify(actionPlans))

    // Helper function to mark an item as new if its ID is in newUUIDs
    function markIfAddedInLastRound(item) {
      if (item.id && newIdsSet.has(item.id)) {
        item.isAddedInLastRound = true
      }
      return item
    }

    // Process each action plan
    return updatedActionPlans.map((plan) => ({
      ...plan,
      comments: plan.comments?.map(markIfAddedInLastRound) || [],
      decisions: plan.decisions?.map(markIfAddedInLastRound) || [],
      actions: plan.actions?.map(markIfAddedInLastRound) || [],
    }))
  }

  const fitViewToImportantNodes = () => {
    const nodes = getNodes()

    // Filter nodes by provided IDs
    const filteredNodes = nodes.filter(
      (node) =>
        node.data.type === 'action' ||
        node.data.type === 'decision' ||
        node.type == 'mindmapTopicNode' ||
        node.type == 'mindmapProblemNode',
    )

    if (filteredNodes.length === 0) {
      fitView({ duration: 800 })
      return
    }

    const bounds = getNodesBounds(filteredNodes)
    fitBounds(bounds, { duration: 800, padding: 0 })
  }

  useEffect(() => {
    return () => {
      cleanup()
    }
  }, [])

  useEffect(() => {
    stateRef.current = state
    console.log('stateRef.current: ', stateRef.current)
  }, [state])

  useEffect(() => {
    if (state.progressState === 'exploration') {
      console.log('trigger intervals')
      console.log('state.readOnly: ', state.readOnly)
      console.log(
        'deviatedConversationDetectionTimeoutRef.current',
        deviatedConversationDetectionTimeoutRef.current,
      )
      // state.readOnly/state.stopIntervention is only set once
      if (!deviatedConversationDetectionTimeoutRef.current && !state.readOnly) {
        if (deviatedConversationDetectionTimeoutRef.current) {
          clearTimeout(deviatedConversationDetectionTimeoutRef.current)
        }
        // create the timeout after 3 mins
        // initialDelayRef.current = setTimeout(() => {
        deviatedConversationDetectionTimeoutRef.current = setTimeout(() => {
          detectDeviatedConversation(stateRef.current.meetingInstanceId)
        }, DEVIATED_CONVERSATION_DETECTION_INTERVAL)
        // }, INITIAL_DETECT_DEVIATION_DELAY)
      }

      if (!topicsGenerationIntervalRef.current && !state.readOnly) {
        topicsGenerationIntervalRef.current = setInterval(() => {
          generateTopics(stateRef.current.meetingInstanceId)
        }, TOPIC_GENERATION_INTERVAL)
      }
    }

    return () => {
      console.log('clear intervals')
      if (deviatedConversationDetectionTimeoutRef.current) {
        clearTimeout(deviatedConversationDetectionTimeoutRef.current)
      }
      if (topicsGenerationIntervalRef.current) {
        clearInterval(topicsGenerationIntervalRef.current)
      }
      if (initialDelayRef.current) {
        clearTimeout(initialDelayRef.current)
      }
    }
  }, [state.progressState, state.readOnly])

  React.useEffect(() => {
    let shouldStopInterruptionTemporarilyTimer
    if (state.shouldStopInterruptionTemporarily) {
      shouldStopInterruptionTemporarilyTimer = setTimeout(() => {
        dispatch({ type: 'SET_SHOULD_STOP_INTERRUPTION', payload: false })
        // roughly 5 minutes
      }, 290000)
    }
    return () => {
      if (shouldStopInterruptionTemporarilyTimer) {
        clearTimeout(shouldStopInterruptionTemporarilyTimer)
      }
    }
  }, [state.shouldStopInterruptionTemporarily])

  const findFirstLargerTimestamp = () => {
    let low = 0
    let high = chunksRef.current.length - 1
    let result = -1

    while (low <= high) {
      let mid = Math.floor((low + high) / 2)
      // take 3 weconds of previous audio
      if (chunksRef.current[mid].timestamp > lastAudioApiCall.current - 3) {
        result = mid // Store the current index
        high = mid - 1 // Search in the left half
      } else {
        low = mid + 1 // Search in the right half
      }
    }
    return result
  }

  const startRecording = async () => {
    let includeTabAudio = stateRef.current.useMicOnly === false

    try {
      dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'idle' })
      dataQueue.current = []
      const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
        isStopRecording: false,
        isBrainstorming: true,
      })
      const meetingInstanceId = response.meetingInstanceId
      const brainstormInstanceId = response.associatedInstanceId

      let displayStream = null
      if (includeTabAudio) {
        displayStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          video: true,
        })
        displayStreamRef.current = displayStream
      }

      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      audioStreamRef.current = audioStream
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()

      // Create and configure audio context nodes
      const destinationNode = audioContextRef.current.createMediaStreamDestination()
      const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)
      audioSourceNode.connect(destinationNode)

      // Only connect display stream if it exists
      if (displayStream) {
        const displaySourceNode = audioContextRef.current.createMediaStreamSource(displayStream)
        displaySourceNode.connect(destinationNode)
      }

      mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)
      dispatch({ type: 'SET_STREAM', stream: destinationNode.stream })
      dispatch({ type: 'START_RECORDING', meetingInstanceId, brainstormInstanceId })

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          if (headerChunkRef.current === null) {
            headerChunkRef.current = event.data
          } else {
            // when bot audio is not playing (can't be muted), or bot audio is playing (but muted)
            if (
              (!stateRef.current.isBotAudioPlaying ||
                (stateRef.current.isMuted && stateRef.current.isBotAudioPlaying)) &&
              !stateRef.current.isPaused
            ) {
              chunksRef.current.push({
                timestamp: Math.floor(Date.now() / 1000),
                data: event.data,
              })
              let ind = findFirstLargerTimestamp()
              if (ind !== -1) {
                let newChunks = chunksRef.current.slice(ind)
                chunksRef.current = newChunks
              }
            } else {
              console.log('Bot audio playing or paused')
            }
          }
        }
      }

      mediaRecorderRef.current.start(CHUNK_DURATION)
      sendingIntervalRef.current = setInterval(() => {
        accumulateDataChunks()
        sendAudioData(meetingInstanceId)
      }, QUEUE_PEEK_INTERVAL)
      sourceIdEventSource.current = null
    } catch (err) {
      console.log(err)
      const result = await Swal.fire({
        title: t('Common:brainstorming.shareAudioErrorMessage'),
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const generateMMRecommendation = async () => {
    // TODO remember to handle the case when the user map_summary_to_problems is called but not yet finished...
    if (
      stateRef.current.progressState !== 'exploration' ||
      stateRef.current.isPaused ||
      stateRef.current.isMindMapLocked
    ) {
      dispatch({ type: 'SET_IS_GENERATING_MM_REC', payload: false })
      return
    }

    dispatch({ type: 'SET_IS_GENERATING_MM_REC', payload: true })
    if (stateRef.current.isUpdatingMM) {
      console.log(
        'generateMMRecommendation isUpdatingMMRef.current: ',
        stateRef.current.isUpdatingMM,
      )
      if (mindMapRecommendationTimeoutRef.current) {
        clearTimeout(mindMapRecommendationTimeoutRef.current)
      }

      mindMapRecommendationTimeoutRef.current = setTimeout(() => {
        generateMMRecommendation()
      }, 1000)
      return
    }
    // isUpdatingMMRef.current = true
    dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/generate_mm_recommendation/${stateRef.current.meetingInstanceId}`,
        {
          brainstormMap: JSON.stringify(stateRef.current.brainstormMap),
          language_setting: stateRef.current.language,
        },
      )
      if (response.status === 200 && !stateRef.current.isMindMapLocked && response.rec) {
        dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: response.rec, auto: true })
      }
    } catch (error) {
      console.log('error generate_mm_recommendation: ', error)
    }

    try {
      await postHttpRequest(
        `/brainstorm/update_discarded_list/${stateRef.current.meetingInstanceId}`,
        {
          discardedList: stateRef.current.discardedList,
        },
      )
    } catch (error) {
      console.log('error update_discarded_list: ', error)
    }
    dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
    dispatch({ type: 'SET_IS_GENERATING_MM_REC', payload: false })
  }

  const generateTopics = async (meetingInstanceId) => {
    // console.log('generateTopics: isUpdatingMMRef.current: ', isUpdatingMMRef.current)
    if (stateRef.current.progressState !== 'exploration') return
    if (
      stateRef.current.isPaused ||
      stateRef.current.isUpdatingMM ||
      stateRef.current.isMindMapLocked
    )
      return
    console.log('generateTopics')

    const divBeforeMindMap = document.querySelector('#mindmap-beginning')
    if (divBeforeMindMap) {
      const rect = divBeforeMindMap.getBoundingClientRect()
      const top = rect.top

      const mindmapParent = document.querySelector('.floatingedges')
      if (mindmapParent) {
        mindmapParent.style.height = `calc(100vh - ${top}px)`
      }
    }

    dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/map_summary_to_problems/${meetingInstanceId}`,
        {
          hasTopicsLimit: stateRef.current.hasTopicsLimit,
          language_setting: stateRef.current.language,
        },
      )
      // console.log('topics: ', response)
      if (response.problems && !stateRef.current.isMindMapLocked) {
        // console.log('GEMINI_INPUT - SYSTEM MSG:', response.debug_output.SYSTEM_MESSAGE)
        // console.log('GEMINI_INPUT - USER MSG:', response.debug_output.USER_MESSAGE)

        // Find out which nodes were added, before we update the Reactflow component.
        const nodes = getNodes()
        let newUUIDs = findNewUuids(response.problems.exploratory_questions[0].action_plans, nodes)

        // We need to mark these new nodes in the original JSON response, because this will the be
        // passed on to the renderer, where we will create the highlighted border.
        response.problems.exploratory_questions[0].action_plans = markNewItems(
          response.problems.exploratory_questions[0].action_plans,
          newUUIDs,
        )

        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.problems.exploratory_questions[0],
          auto: true,
        })

        // Doing this in a timeout for now because it needs to run after the above dispatch() has
        // been fully executed.
        setTimeout(() => {
          handleZoomToRandomNode(newUUIDs)
        }, 500)
      }
    } catch (error) {
      console.error('Error generating topics:', error)
    }

    try {
      await postHttpRequest(`/brainstorm/update_discarded_list/${meetingInstanceId}`, {
        discardedList: stateRef.current.discardedList,
      })
    } catch (error) {
      console.log('error update_discarded_list: ', error)
    }

    dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
  }

  // const revertBrainstormMap = async () => {
  //   if (stateRef.current.isPaused) return
  //   isReverting.current = true
  //   try {
  //     const response = await postHttpRequest(
  //       `/brainstorm/revert_brainstorm_map/${stateRef.current.meetingInstanceId}`,
  //     )
  //     console.log(response.debug_dict)
  //   } catch (error) {
  //     console.error('Error reverting brainstorm map:', error)
  //   }
  //   isReverting.current = false
  // }

  const cleanup = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()

      audioContextRef.current.close()
    }

    if (sendingIntervalRef.current) {
      clearInterval(sendingIntervalRef.current)
    }
    if (dataIntervalRef.current) {
      clearInterval(dataIntervalRef.current)
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (shortTermSummaryIntervalRef.current) {
      clearInterval(shortTermSummaryIntervalRef.current)
    }
    if (problemGenerationIntervalRef.current) {
      clearInterval(problemGenerationIntervalRef.current)
    }
    if (deviatedConversationDetectionTimeoutRef.current) {
      clearTimeout(deviatedConversationDetectionTimeoutRef.current)
    }
    if (topicsGenerationIntervalRef.current) {
      clearInterval(topicsGenerationIntervalRef.current)
    }
    if (mindMapRecommendationTimeoutRef.current) {
      clearTimeout(mindMapRecommendationTimeoutRef.current)
    }
    if (initialDelayRef.current) {
      clearTimeout(initialDelayRef.current)
    }

    if (displayStreamRef.current) {
      displayStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop())
    }
  }

  const stopRecording = async () => {
    cleanup()

    const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
      isStopRecording: true,
      meetingInstanceId: stateRef.current.meetingInstanceId,
    })
    if (response.error) {
      console.error('Error stopping recording:', response.error)
    }

    // reset to false
    isProcessingDataQueue.current = false
    dispatch({
      type: 'STOP_RECORDING',
    })
    dispatch({ type: 'STOP_RECORDING' })
    localStorage.removeItem('mets')
  }

  const accumulateDataChunks = () => {
    if (chunksRef.current.length === 0 || headerChunkRef.current === null) return

    const formData = new FormData()
    const blobParts = [headerChunkRef.current].concat(chunksRef.current.map((chunk) => chunk.data))
    const combinedBlob = new Blob(blobParts, { type: 'audio/webm' })
    formData.append('audio', combinedBlob, 'combined_chunks.webm')
    formData.append('timestamp_start', chunksRef.current[0].timestamp.toString())
    formData.append(
      'timestamp_end',
      chunksRef.current[chunksRef.current.length - 1].timestamp.toString(),
    )
    formData.append('mode', 'stream')
    formData.append('language_setting', stateRef.current.language)
    dataQueue.current = []
    dataQueue.current.push({ data: formData })
    if (stateRef.current.isPaused) {
      dataQueue.current = []
    }
    console.log('dataQueue.current: ', dataQueue.current)
  }

  useEffect(() => {
    let eventSource = null
    if (state.encodedRequest) {
      console.log('state.encodedRequest lock: ', state.encodedRequest)

      eventSource = new EventSource(
        `${BASE_URL}/brainstorm/get_audio_data/${state.encodedRequest}`,
        {
          withCredentials: true, // This enables sending cookies with the EventSource request
        },
      )

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data)
        console.log('newMessage: ', newMessage)
        if (newMessage.type === 'tts_segment' && newMessage.audio) {
          dispatch({ type: 'SET_ODIN_PENDING_SPEAKING', isOdinPendingSpeaking: true })
          onAudioChunkOutput([
            { audio: newMessage.audio, isOpeningSentence: true, isFromStreaming: true },
          ])
          dispatch({ type: 'SET_RECOMMENDATION', recommendation: newMessage?.text ?? '' })
        } else if (newMessage.type === 'final_string') {
          console.log('lock final_string: ', newMessage.text)
          if (!newMessage.incomplete_request) {
            dispatch({
              type: 'SET_QUESTION_IDEAS_STORAGE',
              recommendation: newMessage.text,
              questionNum: selectedQuestionNumber,
            })
          }
          setSelectedQuestionNumber(-1)
          // I htink it's safe to release lock here
          if (sourceIdEventSource.current) {
            releaseLock(sourceIdEventSource.current)
            sourceIdEventSource.current = null
          }
        }
      }

      eventSource.onerror = (error) => {
        console.error('EventSource xxx failed:', error)
        setSelectedQuestionNumber(-1)
        if (sourceIdEventSource.current) {
          releaseLock(sourceIdEventSource.current)
          sourceIdEventSource.current = null
        }
        eventSource.close()
      }
    }

    return () => {
      if (eventSource) {
        dispatch({ type: 'SET_RECOMMENDATION', reset: true })
        eventSource.close()
      }
    }
  }, [state.encodedRequest, counter])

  // this won;t be called in readOnly mode because vad stream is not set in this case..
  // or startRecording is not called in readOnly mode so no more inteval, mediaRecorder etc..
  const sendAudioData = async (
    meetingInstanceId,
    user_init = false,
    calledBeforeButtonClick = false,
  ) => {
    try {
      console.log('123')
      if (
        dataQueue.current.length === 0 ||
        (isProcessingDataQueue.current && !calledBeforeButtonClick) ||
        (stateRef.current.isBotAudioPlaying && !stateRef.current.isMuted) ||
        stateRef.current.isPaused
      )
        return

      if (isProcessingDataQueue.current && calledBeforeButtonClick) {
        console.log('wwwww into new timeout')
        if (sendAduioTimeoutRef.current) {
          clearTimeout(sendAduioTimeoutRef.current)
        }
        sendAduioTimeoutRef.current = setTimeout(() => {
          sendAudioData(meetingInstanceId, user_init, calledBeforeButtonClick)
        }, 300)
      }

      let formData = dataQueue.current.shift()
      console.log('321')
      isProcessingDataQueue.current = true

      // we don't need lock for deviation detetion endpoint because deviation detetion audio is added in batch
      // so it's impossible to have user-init audio added in the middle of the batch

      // some cases to justifying why not moving it to the if below:
      // 1. let's say immediately after user says the words (processing), deviation detection returns audios,
      // since it's not locked, it will first say 'may I jump in', then it could be locked because audio_v2/event_source now returns with quesiton_ideas
      // at this point, it will start adding user-init audio to the queue but what about the steer audio?
      // will the steer audio be added to the queue? before lock is released?
      // unless we update the logic of when to release steerAudio?

      // handle case where auto sendAudioData will overwrite the lock sourceid of user-init
      if (user_init) {
        sourceIdEventSource.current = requestQueueAccess(true)
        // always overwrite the lock if it's user-init.. so that sourceIdEventSource.current is always the latest
        lockAccess(sourceIdEventSource.current)
      }

      lastAudioApiCall.current = Math.floor(Date.now() / 1000)
      let response = await postHttpRequest(
        `/meeting/process_audio_chunks_v2/${meetingInstanceId}/${user_init}`,
        formData.data,
      )

      console.log('response.request: ', response.request)
      // explicitly check for all these conditions
      if (
        response.encoded_user_request_str &&
        response.request === 'question_ideas' &&
        (response.question_number !== null || response.question_number !== undefined)
      ) {
        dispatch({ type: 'SET_ENCODED_REQUEST', encodedRequest: response.encoded_user_request_str })
        let num = parseInt(response.question_number) - 1
        setSelectedQuestionNumber(num >= stateRef.current.subQuestions.length ? 0 : num)
      } else {
        if (user_init) {
          // release lock if it's user-init and not question_ideas
          if (sourceIdEventSource.current) {
            releaseLock(sourceIdEventSource.current)
            sourceIdEventSource.current = null
          }
        } else {
          if (response.request === 'auto') {
            console.log('auto')
          } else {
            // this will cancel eventsource
            // not sure if it's needed here thoough
            dispatch({ type: 'SET_ENCODED_REQUEST', encodedRequest: '' })
          }
        }
      }
      isProcessingDataQueue.current = false
      console.log('Chunks sent successfully')

      // return response.recommendation
      // dispatch({ type: 'SET_RECOMMENDATION', recommendation: response?.recommendation ?? '' })
    } catch (error) {
      console.error('Error sending chunks to API:', error)
      console.log(error.response.status)
      isProcessingDataQueue.current = false
      dispatch({ type: 'SET_RECOMMENDATION', recommendation: '' })
      if (sourceIdEventSource.current) {
        releaseLock(sourceIdEventSource.current)
        sourceIdEventSource.current = null
      }
    }
  }

  const rescheduleDetectDeviatedConversation = () => {
    if (deviatedConversationDetectionTimeoutRef.current) {
      clearTimeout(deviatedConversationDetectionTimeoutRef.current)
      deviatedConversationDetectionTimeoutRef.current = setTimeout(() => {
        detectDeviatedConversation(stateRef.current.meetingInstanceId)
      }, DEVIATED_CONVERSATION_DETECTION_INTERVAL)
    }
  }

  const detectDeviatedConversation = async (meetingInstanceId) => {
    if (
      stateRef.current.isPaused ||
      stateRef.current.progressState !== 'exploration' ||
      stateRef.current.shouldStopInterruptionTemporarily
    ) {
      rescheduleDetectDeviatedConversation()
      return
    }
    console.log('detectDeviatedConversation check lock??')
    dispatch({ type: 'SET_INTERVENTION_ACTUAL_AUDIO', interventionActualAudio: null })
    await detectDeviationMain(meetingInstanceId)
  }

  const detectDeviationMain = async (meetingInstanceId) => {
    try {
      const existingParkingLotTopic = stateRef.current.brainstormMap.action_plans.find(
        (item) => item.is_parking_lot,
      )
      const actionPlans_without_parking_lot = stateRef.current.brainstormMap.action_plans.filter(
        (item) => !item.is_parking_lot,
      )
      const response = await postHttpRequest(
        `/brainstorm/detect_deviated_conversation/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
          existing_parking_lot: existingParkingLotTopic ?? null,
          actionPlans_without_parking_lot: actionPlans_without_parking_lot,
        },
      )
      // if at the time of stateRef.current.shouldStopInterruptionTemporarily is set to true, there's alreayd a new request sent
      // we should ignore it
      if (stateRef.current.shouldStopInterruptionTemporarily) {
        throw new Error('stateRef.current.shouldStopInterruptionTemporarily is true')
      }
      if (response.data) {
        let highlightedQuestions =
          response.data?.customData?.highlightedTexts?.map((i) => parseInt(i)) ?? []
        console.log('highlightedQuestions: ', highlightedQuestions)
        dispatch({ type: 'SET_HIGHLIGHTED_QUESTIONS', highlightedQuestions: highlightedQuestions })
      }
      // add check here
      if (response.audio && response.audio_steer && !state.stopIntervention) {
        addSteerAudio(
          response.audio,
          response.audio_steer,
          response.data?.steerConversation,
          meetingInstanceId,
        )
      } else {
        // else means there is no deviated conversation beacuse no audio
        // dispatch({ type: 'REMOVE_FROM_AUDIO_QUEUE'})
        rescheduleDetectDeviatedConversation()
      }
      //update parking lot topic
      if (response.data?.parkinglotContent) {
        dispatch({
          type: 'UPDATE_PARKING_LOT_TOPIC',
          parkingLotTopic: response.data.parkinglotContent,
        })
      }
    } catch (error) {
      console.log('Error detecting deviated conversation:', error)
      rescheduleDetectDeviatedConversation()
    }
  }

  const addSteerAudio = (audio, audio_steer, steerConversation, meetingInstanceId) => {
    let lock = requestQueueAccess()
    if (!lock) {
      console.log('lock is being used')
      clearTimeout(deviatedConversationDetectionTimeoutRef.current)
      deviatedConversationDetectionTimeoutRef.current = setTimeout(() => {
        addSteerAudio(audio, audio_steer, steerConversation, meetingInstanceId)
      }, 800)
    } else {
      console.log('lock is not being used')
      dispatch({ type: 'SET_STEER_CONVERSATION', steerConversation: steerConversation ?? '' })
      dispatch({ type: 'SET_ODIN_PENDING_SPEAKING', isOdinPendingSpeaking: true })

      onAudioChunkOutput([
        { audio: audio, isOpeningSentence: true },
        // {audio: audio_steer, isOpeningSentence: false, isAudioSteer: true},
      ])
      dispatch({ type: 'SET_INTERVENTION_ACTUAL_AUDIO', interventionActualAudio: audio_steer })
      // re-schedule next detection time
      rescheduleDetectDeviatedConversation()
    }
  }

  const exploreProblems = async (badQuestion = null, isRefreshQuestions = false) => {
    console.log('questions: ', stateRef.current.problems)
    let ts = Math.floor(Date.now() / 1000) - 3 // hacky way....
    // accumulateDataChunks()
    // await sendAudioData(stateRef.current.meetingInstanceId)
    console.log('qqqqqqqqqqqqq')
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/expand_questions/${stateRef.current.meetingInstanceId}`,
        {
          questions: stateRef.current.primaryProblem,
          timestamp: ts,
          questionToBeRefreshed: badQuestion,
          language_setting: stateRef.current.language,
        },
      )
      console.log('ppppppppppppppppp')
      // if(response.problems){

      // }

      if (response.sub_questions?.length > 0) {
        // console.log('debug_dict: ', response.debug_dict)
        dispatch({ type: 'SET_SUB_QUESTIONS', subQuestions: response.sub_questions })
        if (stateRef.current.progressState === 'exploration') {
          console.log('second time')
        } else {
          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'exploration' })
          // dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: JSON.parse(response.problems)})
        }
        if (!isRefreshQuestions) {
          let placeholder = {
            problem: stateRef.current.primaryProblem,
            action_plans: [],
          }
          dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: placeholder })
        }
      }
    } catch (error) {
      console.error('Error generating problems:', error)
    }
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: false })
  }

  const generateClarifications = async () => {
    let ts = Math.floor(Date.now() / 1000) - 3 // hack..
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: true })

    try {
      // NOTE: !!!!!!!!! since there's possibily no api call, isGeneratingProblems will be set to true then swifty to false
      // which means, it useEffect() dependency might not be triggered if it's set to true then false immediately
      // so setIsThinking() will not be triggered..
      // one way to tacke this is to give a sleep time
      if (stateRef.current.clarifications.length === 0) {
        const response = await postHttpRequest(
          `/brainstorm/generate_clarification/${stateRef.current.meetingInstanceId}`,
          {
            problem: stateRef.current.primaryProblem,
            timestamp: ts,
            language_setting: stateRef.current.language,
          },
        )
        let clarifications_list = response.clarification ? [response.clarification] : []
        if (clarifications_list.length === 0) {
          throw new Error('No clarifications generated')
        }
        dispatch({ type: 'SET_CLARIFICATIONS', clarifications: clarifications_list })
      } else {
        await sleep(1500)
      }
      dispatch({ type: 'INCREMENT_REFINEMENT_COUNT' })
      dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'refinement' })
      dispatch({ type: 'SET_BUTTON_TEXT', buttonTextState: 1 })
    } catch (error) {
      console.log('Error generating clarifications: 888', error)
      // only possible if no clarifications generated which happens in the first call.. which means it's still in problems state
      dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'problems' })
    }
    console.log('888')
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: false })
  }

  const generateProblems = async (meetingInstanceId) => {
    console.log('generateProblems: ')
    // accumulateDataChunks()
    // await sendAudioData(stateRef.current.meetingInstanceId)
    if (stateRef.current.progressState === 'exploration' || isGeneratingProblems.current) return
    isGeneratingProblems.current = true
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: true })
    accumulateDataChunks()
    console.log('wwww jhjhkhkjh')
    await sendAudioData(meetingInstanceId, false, true)
    console.log('wwww nnnnn')
    // dispatch({ type: 'IS_GENERATING_PROBLEMS', isGeneratingProblems: true })
    try {
      const response = await postHttpRequest(`/brainstorm/identify_problems/${meetingInstanceId}`, {
        isProblemRefinement: numCallsToProblemGeneration.current > 0,
        problemStatement: stateRef.current.primaryProblem,
        clarification:
          stateRef.current.clarifications.length > 0 ? stateRef.current.clarifications[0] : '',
        language_setting: stateRef.current.language,
      })

      if (response.status !== 200) {
        throw new Error(response.error)
      }

      if (response.problems) {
        const res_json = JSON.parse(response.problems)['statements']
        //   {
        //     "statements": {
        //         "primary_problem": "Unclear how to use a stack linear attribution model for PMM content.",
        //         "additional_problems": [],
        //     }
        // }
        // problem refinement stage
        if (numCallsToProblemGeneration.current > 0) {
          //   {
          //     "statements": [
          //         {
          //             "problem": "statement 1"
          //         }
          //     ]
          // }
          let problems = res_json.map((q) => q['problem']).filter((q) => q !== '') ?? []
          if (problems.length > 0) {
            dispatch({ type: 'UPDATE_PRIMARY_PROBLEM', problem: problems[0] })
          }
          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'refinement' })
          dispatch({ type: 'SET_BUTTON_TEXT', buttonTextState: 0 })
        } else {
          let allProblems = [
            ...[res_json['primary_problem']].filter((p) => p !== ''),
            ...res_json['additional_problems'],
          ]
          console.log('allProblems: ', allProblems)
          if (allProblems.length === 0) {
            throw new Error('No problems generated')
          }

          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'problems' })
          if (allProblems.length === 1) {
            // dispatch({ type: 'SET_CLARIFICATIONS', clarifications: [res_json['clarification']] })
            dispatch({ type: 'UPDATE_PRIMARY_PROBLEM', problem: allProblems[0] })
          }
          // problem defnition stage
          dispatch({ type: 'UPDATE_PROBLEMS', problems: allProblems })
        }

        // dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: {problem: problems[0], action_plans: []}})
        numCallsToProblemGeneration.current += 1
      }
    } catch (error) {
      console.error('Error generating problems:', error)
      if (numCallsToProblemGeneration.current === 0) {
        dispatch({ type: 'SET_CLARIFICATIONS', clarifications: [] })
        dispatch({ type: 'UPDATE_PRIMARY_PROBLEM', problem: '' })
        dispatch({ type: 'UPDATE_PROBLEMS', problems: [] })
      } else {
        dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'refinement' })
      }
    }
    // dispatch({ type: 'IS_GENERATING_PROBLEMS', isGeneratingProblems: false })
    isGeneratingProblems.current = false
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: false })
  }

  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'audio/mpeg' })
  }

  const onAudioChunkOutput = async (audio_chunks) => {
    let chunks = []
    for (let i = 0; i < audio_chunks.length; i++) {
      const data = audio_chunks[i].audio
      const base64Audio = data
      const audioBlob = base64ToBlob(base64Audio)
      const audioUrl = URL.createObjectURL(audioBlob)

      chunks.push({
        url: audioUrl,
        isOpeningSentence: audio_chunks[i].isOpeningSentence,
        isAudioSteer: audio_chunks[i]?.isAudioSteer ?? false,
        isFromStreaming: audio_chunks[i]?.isFromStreaming ?? false,
      })
    }

    dispatch({ type: 'ADD_TO_AUDIO_QUEUE', audioUrls: chunks })
  }

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  return (
    <BrainstormContext.Provider
      value={{
        state,
        dispatch,
        startRecording,
        stopRecording,
        generateProblems,
        exploreProblems,
        // revertBrainstormMap,
        accumulateDataChunks,
        sendAudioData,
        onAudioChunkOutput,
        generateClarifications,
        detectDeviatedConversation,
        generateMMRecommendation,
      }}
    >
      {children}
    </BrainstormContext.Provider>
  )
}

const useBrainstorm = () => {
  const context = React.useContext(BrainstormContext)
  if (!context) {
    throw new Error('useBrainstorm must be used within a BrainstormProvider.')
  }
  return context
}

export { BrainstormProvider, useBrainstorm }
