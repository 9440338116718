import React from 'react'
import Modal from '../Modal'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import TextArea from 'rc-textarea'
import AssigneeActivity from '../../pages/MeetingMultiView/components/MeetingTopics/common/assigneeActivity'
import Toast from '../../common/toast'
import { shortenName } from '../../utils/general'
import Swal from 'sweetalert2'

const ManageTagsModal = ({ closeModal }) => {
  const [tags, setTags] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [inputDescription, setInputDescription] = React.useState('')
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = React.useState(false)
  const [assigneeList, setAssigneeList] = React.useState([])
  const [selectedTagId, setSelectedTagId] = React.useState(null)

  const { t } = useTranslation(['MeetingHub', 'Common'])

  React.useEffect(() => {
    fetchTags()
  }, [])

  const fetchTags = async () => {
    setIsLoading(true)
    try {
      let companyTags = await getHttpRequest(`/get_company_tags`)
      setTags(companyTags.tags)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const tagNameExists = (tags, newTagName) => {
    return tags.some((tag) => tag.tagName.trim().toLowerCase() === newTagName.trim().toLowerCase())
  }

  const addNewTag = async (tagName) => {
    console.log(tagName)
    if (tagNameExists(tags, tagName)) {
      Toast.fire({
        icon: 'error',
        title: t('tagManagement.tagAlreadyExists'),
      })
      return
    }

    try {
      await postHttpRequest('/add_new_tag', {
        tagName,
      })
      loadAgain()
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const loadAgain = () => {
    setSelectedTagId(null)
    fetchTags()
  }

  const deleteTag = async (tagId) => {
    try {
      const result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureDeleteThis'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (result.isConfirmed) {
        await postHttpRequest(`/edit_tag/${tagId}`, {
          isDelete: true,
        })
        loadAgain()
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function updateTagName(value, index) {
    const dataArray = [...tags]
    const tag = dataArray[index]

    tag.tagName = value

    dataArray[index] = tag
    setTags(dataArray)
  }

  const saveTag = async (tag) => {
    if (tag.tagName === '') {
      return
    }

    try {
      let response = await postHttpRequest(`/edit_tag/${tag.tagId}`, {
        tagName: tag.tagName,
        isDelete: false,
      })
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      title={t('tagManagement.title')}
      width={'45%'}
      height={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '80%' }}
    >
      {isAssignmentModalOpen && selectedTagId && (
        <AssigneeActivity
          objectiveId={-1}
          setModelView={setIsAssignmentModalOpen}
          view={isAssignmentModalOpen}
          assigneeList={assigneeList}
          loadAgain={loadAgain}
          meetingId={selectedTagId}
          isTag={true}
        />
      )}
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              padding: '10px',
              boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
              borderRadius: '4px',
              backgroundColor: 'white',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <TextArea
                  rows={1}
                  type="text"
                  placeholder={t('Common:formPlaceHolder.typeToAdd')}
                  style={{
                    width: '97%',
                    resize: 'none',
                    border: '1px solid rgb(238, 238, 238)',
                    backgroundColor: '#f1f0f0',
                    borderRadius: '2.5px',
                    fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                  }}
                  onChange={(e) => {
                    setInputDescription(e.target.value)
                  }}
                  value={inputDescription}
                  spellCheck={false}
                />
              </div>
              <div
                class="fitted-button blue"
                style={{ marginTop: 'auto', marginBottom: 'auto', padding: '0rem' }}
                onClick={() => {
                  if (inputDescription.trim() !== '') {
                    addNewTag(inputDescription)
                    setInputDescription('')
                  }
                }}
              >
                <span class="material-symbols-outlined">add</span>
              </div>
            </div>
          </div>
          {tags.length > 0 ? (
            <div style={{ marginTop: '2rem', width: '100%' }}>
              {tags.map((tag, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        width: '80%',
                        wordBreak: 'break-word',
                      }}
                    >
                      <TextArea
                        style={{
                          border: 'none',
                          fontWeight: '600',
                          fontFamily: '"Open Sans", "Arial", "Helvetica", "system-ui", sans-serif',
                          height: '17px',
                          fontSize: '15px',
                        }}
                        autoSize
                        className="body_bottom_text"
                        value={tag.tagName}
                        onChange={(e) => updateTagName(e.target.value, index)}
                        onBlur={() => saveTag(tag)}
                      ></TextArea>
                      {/* {meeting.meetingName} */}

                      <div
                        style={{
                          margin: 'auto 0',
                          color: 'grey',
                          fontWeight: 'normal',
                          fontSize: '14px',
                        }}
                      >
                        {tag?.viewers
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((viewer, viewerIndex) => (
                            <React.Fragment key={viewerIndex}>
                              <i>{shortenName(viewer.name)}</i>
                              {viewerIndex !== tag?.viewers.length - 1 && <span>, </span>}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                      <div
                        style={{ marginTop: 'auto', marginBottom: 'auto' }}
                        class="fitted-button blue tooltip"
                        onClick={(e) => {
                          setAssigneeList(tag.viewers)
                          setIsAssignmentModalOpen(true)
                          console.log(tag.tagId)
                          setSelectedTagId(tag.tagId)
                        }}
                      >
                        <span
                          class="material-symbols-outlined icon-size"
                          style={{ fontSize: '15px' }}
                        >
                          person_add
                        </span>
                        <span class="tooltiptext" style={{ fontSize: '16px' }}>
                          {' '}
                          {t('Common:tooltip.assignToOthers')}{' '}
                        </span>
                      </div>
                      <div
                        class="fitted-button red icon-size"
                        style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '0.1rem' }}
                        onClick={(e) => deleteTag(tag.tagId)}
                      >
                        <span
                          class="material-symbols-outlined icon-size"
                          style={{ fontSize: '15px' }}
                        >
                          close
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div style={{ marginTop: '2rem' }}>{t('tagManagement.noMeetings')}</div>
          )}
        </>
      )}
    </Modal>
  )
}

export default ManageTagsModal
