import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import '../KPIModal/styles.scss'
import { shortenName } from '../../../utils/general'
import { objectiveColors, hexToRGBA, levelOpacity } from '../../../utils/colors'

const KpiHierarchyGrid = ({
  kpiList,
  selectedKpis = [],
  onKpiSelectionChange,
  initialExpanded = true,
}) => {
  const { t } = useTranslation(['Common'])
  const [expandedItems, setExpandedItems] = useState(new Set())
  const [selectedKpiIds, setSelectedKpiIds] = useState(new Set(selectedKpis))

  const getExpandableIds = (objList) => {
    const ids = new Set()

    const processObj = (obj, parentPath = '') => {
      if (!obj) return
      const uniqueId = `${parentPath}${obj.objective.id}`

      if (obj.objective?.id && (obj.objList?.length > 0 || obj.kpiList?.length > 0)) {
        ids.add(uniqueId)
      }
      if (obj.objList?.length > 0) {
        obj.objList.forEach((childObj, index) => processObj(childObj, `${uniqueId}-${index}-`))
      }
    }

    objList?.forEach((obj, index) => processObj(obj, `root-${index}-`))
    return ids
  }

  useEffect(() => {
    if (initialExpanded) {
      setExpandedItems(getExpandableIds(kpiList))
    }
  }, [kpiList, initialExpanded])

  useEffect(() => {
    setSelectedKpiIds(new Set(selectedKpis))
  }, [selectedKpis])

  const toggleExpand = (id, parentPath = '') => {
    const uniqueId = `${parentPath}${id}`
    setExpandedItems((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(uniqueId)) {
        newSet.delete(uniqueId)
      } else {
        newSet.add(uniqueId)
      }
      return newSet
    })
  }

  const toggleKpiSelection = (kpiId) => {
    const newSelection = new Set(selectedKpiIds)
    if (newSelection.has(kpiId)) {
      newSelection.delete(kpiId)
    } else {
      newSelection.add(kpiId)
    }
    setSelectedKpiIds(newSelection)
    onKpiSelectionChange?.(Array.from(newSelection))
  }

  const renderRows = (obj, depth = 0, parentPath = '') => {
    if (!obj) return []

    const uniqueId = `${parentPath}${obj.objective.id}`
    const isExpanded = expandedItems.has(uniqueId)
    const hasChildren = obj.objList?.length > 0
    const hasKpis = obj.kpiList?.length > 0
    const hasAnyChildren = hasChildren || hasKpis
    const rows = []

    // Objective row
    rows.push(
      <div
        key={uniqueId}
        className="grid-row objective-row"
        style={{
          alignItems: 'flex-start',
          backgroundColor: 'white',
        }}
      >
        <div className="grid-cell">
          <div
            className="objective-cell"
            style={{ paddingLeft: `${depth * 24}px`, alignItems: 'flex-start' }}
          >
            {hasAnyChildren ? (
              <div
                onClick={() => toggleExpand(obj.objective.id, parentPath)}
                className="expand-button"
              >
                <span className="material-symbols-outlined">
                  {isExpanded ? 'arrow_drop_down' : 'arrow_right'}
                </span>
              </div>
            ) : (
              <span className="expand-button-spacer"></span>
            )}
            <div className="objective-content">
              <div className="objective-statement">
                <div
                  className="color-bar"
                  style={{
                    backgroundColor: `${hexToRGBA(
                      objectiveColors[obj.objective.color],
                      levelOpacity(obj.objective.level),
                    )}`,
                  }}
                />
                <div>{obj.objective.statement}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-cell"></div>
        <div className="grid-cell"></div>
        <div className="grid-cell"></div>
      </div>,
    )

    if (isExpanded) {
      // KPIs
      if (hasKpis) {
        obj.kpiList.forEach((kpi, kpiIndex) => {
          rows.push(
            <div key={`${uniqueId}-kpi-${kpiIndex}`} className="grid-row kpi-row">
              <div className="grid-cell">
                <div className="kpi-cell" style={{ paddingLeft: `${(depth + 1) * 24}px` }}>
                  <span className="spacer"></span>
                  {kpi.isCompleted && (
                    <span className="material-symbols-outlined" style={{ color: 'green' }}>
                      check
                    </span>
                  )}
                  <div className="kpi-content">
                    <div className="kpi-name">{kpi.name}</div>
                  </div>
                </div>
              </div>
              <div className="grid-cell">
                {(kpi.kpiType === 1 || kpi.kpiType === 2) && (
                  <span>
                    {kpi.target}
                    {kpi.kpiType === 1 ? '%' : ' ' + kpi?.unit}
                    &nbsp;
                    {t('kpi.by')}&nbsp;{kpi.targetDate}
                  </span>
                )}
              </div>
              <div className="grid-cell">{shortenName(kpi.ownerName)}</div>
              <div className="grid-cell checkbox-cell">
                <input
                  type="checkbox"
                  checked={selectedKpiIds.has(kpi.kpiId)}
                  onChange={() => toggleKpiSelection(kpi.kpiId)}
                />
              </div>
            </div>,
          )
        })
      }

      // Child objectives
      if (hasChildren) {
        obj.objList.forEach((childObj, index) => {
          rows.push(...renderRows(childObj, depth + 1, `${uniqueId}-${index}-`))
        })
      }
    }

    return rows
  }

  return (
    <div className="select-kpis-container">
      <div className="kpi-content">
        <div className="grid-select-kpis-table-container">
          <div className="grid-section">
            <div className="kpi-status-table-header">{t('kpi.kpi')}</div>
            <div className="kpi-status-table-header">{t('kpi.target')}</div>
            <div className="kpi-status-table-header">{t('kpi.owner')}</div>
            <div className="kpi-status-table-header">{t('kpi.include')}</div>
          </div>
          <div className="grid-body">
            {kpiList?.map((obj, index) => renderRows(obj, 0, `root-${index}-`))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KpiHierarchyGrid
