import React, { useEffect, useState, useRef } from 'react'
import BrainstormAudioButton from '../../../components/BrainstormAudioButton'
import { useTranslation } from 'react-i18next'
import { BrainstormProvider, useBrainstorm } from '../Recorder/BrainstormContext'
import ChatBoxBrainstorm from '../ChatBoxPoc'
import DoneButton from './DoneButton'
import TextArea from 'rc-textarea'
import RefineProblemButton from './RefineProblemButton'
//import ObjectiveMapBrainstorm from '../../objectiveFlow/BrainstormMode'
import { useMicVAD } from '@ricky0123/vad-react'
import MindMap from '../../../components/MindMap'
import SpeakingAnimationCircle from '../../../components/SpeakingAnimations/SpeakingAnimationCircle'
import odinRobot from '../../../assets/Robot.jpg'
import InPageLoader from '../../../components/InPageLoader'
import OdinCircle from './OdinCircle'
import './style.css'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCompanyConfigSettings, getConsolidatedConfigSettings } from '../../../utils/auth'
import SelectField from '../../../components/CustomMultipleSelect/singleOption'
import Toast from '../../../common/toast'
import { routes } from '../../../utils/routes'
import BrainstormMeetingTagModal from '../Component/Modal/BrainstormMeetingTagModal'
import { convertTimeStamToMMDDYYYYDash } from '../../../utils/time'
import Select from 'react-select'
import { getSummarizedActionPlans } from '../../../api/services/brainstorm.services'

const translations = {
  sentenceOnPageLoad: {
    en: "Hi, welcome to Decision+! We're here to help you structure and solve problems. Click 'Start' to begin!",
    es: "Hola, bienvenido a Decision+! Estamos aquí para ayudarte a estructurar y resolver problemas. Haz clic en 'Comenzar' para empezar.",
  },
  problemStatement: {
    en: "Let's define your problem statement. You can type it in the space on the left and click 'Continue'.",
    es: "Definamos la declaración del problema. Puedes escribirla en el espacio de la izquierda y hacer clic en 'Continuar",
  },
  exploration: {
    en: "Up here, I've identified some questions you should explore. You can refresh one of them if it isn't quite what you're looking for.",
    es: 'Aquí arriba, he identificado algunas preguntas que deberías explorar. Puedes actualizar una si no es exactamente lo que estás buscando.',
  },
  draftAgain: {
    en: "Here's your updated problem statement, anything else to change? Otherwise click finalize.",
    es: 'Aquí tienes tu enunciado del problema actualizado, ¿hay algo más que cambiar? Si no, haz clic en Finalizar.',
  },
  imThinking: {
    en: "I'm thinking...",
    es: 'Estoy pensando...',
  },
  letMeThink: {
    en: 'let me think',
    es: 'Déjame pensar',
  },
  giveMeASecond: {
    en: 'Give me a second',
    es: 'Dame un segundo',
  },
  justAMoment: {
    en: 'Just a moment',
    es: 'Un momento',
  },
  letMeProcessThat: {
    en: 'Let me process that',
    es: 'Déjame procesarlo',
  },
  refine: {
    en: "Here's what I've gathered as your problem statement. You can refine it or go ahead with this one. Click below.",
    es: 'Esto es lo que he reunido como tu enunciado del problema. Puedes refinarlo o seguir adelante con esta opción. Haz clic abajo.',
  },
  update: {
    en: 'How would you like to update it?',
    es: '¿Cómo te gustaría actualizarlo?',
  },
}

const SpeechBubble = ({
  text,
  sender,
  index,
  shouldNotHaveAudio,
  isQuestionsCollapsed,
  isSpanish,
}) => {
  const { state, dispatch, onAudioChunkOutput } = useBrainstorm()
  // const isSpanish = getCompanyConfigSettings('languageSettings') === 'es'

  React.useEffect(() => {
    generateAudioContent(text)
  }, [text, state.isBrainstormingStarted, index])

  const generateAudioContent = async (text) => {
    if (
      text &&
      sender !== 'me' &&
      state.isBrainstormingStarted &&
      index !== 0 &&
      index < 12 &&
      !shouldNotHaveAudio
    ) {
      try {
        const audioResponse = await getHttpRequest('/brainstorm/audio', {
          params: {
            language: isSpanish ? 'es' : 'en',
            text: text,
          },
        })
        if (audioResponse.audio) {
          dispatch({ type: 'SET_ODIN_PENDING_SPEAKING', isOdinPendingSpeaking: true })
          onAudioChunkOutput([{ audio: audioResponse.audio, isOpeningSentence: true }])
        }
      } catch (error) {
        console.log('error: ', error)
      }
    }
  }

  const bubbleStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    marginBottom: '8px',
  }

  const messageStyle = {
    padding: '12px',
    borderRadius: '8px',
    maxWidth: '100%',
    ...(sender === 'me'
      ? {
          backgroundColor: '#3b82f6',
          color: 'white',
          alignSelf: 'flex-end',
        }
      : {
          backgroundColor: '#e5e7eb',
          color: '#1f2937',
          alignSelf: 'flex-start',
        }),
  }

  const textStyle = {
    fontSize: '14px',
    margin: 0,
  }

  const pointerStyle = {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '8px',
    ...(sender === 'me'
      ? {
          borderColor: '#3b82f6 transparent transparent #3b82f6',
          alignSelf: 'flex-end',
          marginRight: '8px',
        }
      : {
          borderColor: '#e5e7eb transparent transparent #e5e7eb',
          alignSelf: 'flex-end',
          marginRight: '8px',
        }),
  }

  if (isQuestionsCollapsed) {
    return <></>
  }

  return (
    <div style={bubbleStyle}>
      <div style={messageStyle}>
        <p style={textStyle}>{text}</p>
      </div>
      <div style={pointerStyle}></div>
    </div>
  )
}

const BrainstormVadCompomnent = ({
  stream = undefined,
  onSpeechStartcallBack = () => {},
  onSpeechEndcallBack = () => {},
  isPaused = false,
  setHasStarted = () => {},
}) => {
  const brainstormVad = useMicVAD({
    stream: stream,
    ortConfig: (ort) => {
      ort.env.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/'
    },
    startOnLoad: false,
    onSpeechStart: (audio) => {
      console.log('**** Speech start detected ***')
      setHasStarted(true)
      onSpeechStartcallBack()
    },
    onSpeechEnd: (audio) => {
      console.log('**** Speech end detected ***')
      if (!isPaused) {
        onSpeechEndcallBack()
      } else {
        console.log('isPaused: ', isPaused)
      }
    },
    redemptionFrames: 10,
    positiveSpeechThreshold: 0.85,
    negativeSpeechThreshold: 0.6,
  })
  // vadRef.current = brainstormVad
  // setVad(brainstormVad)
  // setIsVadStarted(true)

  if (brainstormVad && !brainstormVad.listening) {
    brainstormVad.start()
  }

  return <></>
}

const areEqual = (prevProps, nextProps) => {
  const propsChanged = Object.entries(nextProps).some(([key, val]) => prevProps[key] !== val)
  if (propsChanged) {
    console.log(
      'Changed prop:',
      Object.fromEntries(Object.entries(nextProps).filter(([key, val]) => prevProps[key] !== val)),
    )
  }
  return !propsChanged
}

const MemoBrainstormVadCompomnent = React.memo(BrainstormVadCompomnent, areEqual)

function BrainstormComponent() {
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const isSpanish = getCompanyConfigSettings('languageSettings') === 'es'

  // const [cancelVoice, setCancelVoice] = useState(false)
  const audioRef = React.useRef(null)

  const {
    state,
    dispatch,
    accumulateDataChunks,
    sendAudioData,
    exploreProblems,
    onAudioChunkOutput,
    generateMMRecommendation,
  } = useBrainstorm()

  const languageSetting = getCompanyConfigSettings('languageSettings')
  const [language] = React.useState(languageSetting ? languageSetting : 'en')

  useEffect(() => {
    dispatch({ type: 'SET_LANGUAGE', language: language })
  }, [language])

  const [bubbleTextIndex, setBubbleTextIndex] = useState(0)
  const [hasStarted, setHasStarted] = useState(false)
  const [isThinking, setIsThinking] = useState(false)

  const [userStopsSpeaking, setUserStopsSpeaking] = useState(false)

  const timeoutRef = useRef(null)
  const interventionTimeoutRef = useRef(null)
  const isTimeoutCancelled = useRef(false)

  const [isGeneratingPromptSuggestion, setIsGeneratingPromptSuggestion] = useState(false)

  const [refreshButtonLoading, setRefreshButtonLoading] = useState({})
  const [collapseStates, setCollapseStates] = useState({})
  // const audioTimeout = useRef(null)
  const shouldNotHaveAudio = useRef(false)

  const [hoverIndex, setHoverIndex] = useState(-1)
  const [sessionsHoverIndex, setSessionsHoverIndex] = useState(-1)

  const numberList = [5, 6, 7, 8, 9]
  const [messages, setMessages] = useState([
    {
      text: t('Common:brainstorming.sentenceOnPageLoad'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.problemStatement'),
      sender: 'other',
    },
    {
      text: '',
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.exploration'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.draftAgain'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.imThinking'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.letMeThink'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.giveMeASecond'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.justAMoment'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.letMeProcessThat'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.refine'),
      sender: 'other',
    },
    {
      text: t('Common:brainstorming.update'),
      sender: 'other',
    },
    // { text: `I've organized your actions and decisions here, take a look!`, sender: 'other' },
  ])
  // const hasMessageUpdate = useRef(0)
  const [isBrainstormMeetingTagModalOpen, setIsBrainstormMeetingTagModalOpen] = useState(false)
  // things that users have access to
  const [availableTags, setAvailableTags] = useState([])
  const [availableMeetings, setAvailableMeetings] = useState([])
  // tags/meetings that are selected by the user in the current brainstorm session when save
  const [selectedTagsCurrentSession, setSelectedTagsCurrentSession] = useState([])
  const [selectedMeetingsCurrentSession, setSelectedMeetingsCurrentSession] = useState([])

  // things that are attached to the past brainstorm sessions
  const [tagsOnSessions, setTagsOnSessions] = useState({})
  const [meetingsOnSessions, setMeetingsOnSessions] = useState({})
  // tags/meetings that are selected by the user to filter the brainstorm sessions
  const [selectedTagsOnSessions, setSelectedTagsOnSessions] = useState([])
  const [selectedMeetingsOnSessions, setSelectedMeetingsOnSessions] = useState([])

  const shouldHideTag = getConsolidatedConfigSettings('disable_tag_brainstorming') === true
  const allowBulkConvertToMH =
    getConsolidatedConfigSettings('categorize_brainstorming_topics_and_save_to_mh') === true

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (state.language) {
      setMessages((currentMessages) =>
        currentMessages.map((message) => {
          // Find the translation key for the current message
          const translationKey = Object.keys(translations).find(
            (key) => translations[key].en === message.text || translations[key].es === message.text,
          )
          // If a translation key is found, update the message text
          if (translationKey) {
            return {
              ...message,
              text: translations[translationKey][state.language],
            }
          }
          // If no matching translation is found, return the original message
          return message
        }),
      )
    }
  }, [state.language])

  const dotStyle = {
    // fontSize: '24px',
    fontWeight: 'bold',
    // color: '#3B82F6', // a shade of blue
    opacity: 0,
    transition: 'opacity 0.2s',
    //margin: '0 2px',
  }

  const activeDotStyle = {
    ...dotStyle,
    opacity: 1,
  }

  const currentLocation = useLocation()
  const navigate = useNavigate()
  const [primaryProblemState, setPrimaryProblemState] = useState(state.primaryProblem)
  const userId = localStorage.getItem('tfei')
  const [brainstormSessions, setBrainstormSessions] = React.useState([])
  const [filteredBrainstormSessions, setFilteredBrainstormSessions] = React.useState([])
  const [selectedBrainstormSession, setSelectedBrainstormSession] = React.useState(null)
  const [isQuestionsCollapsed, setIsQuestionsCollapsed] = React.useState(false)
  const [hasSaved, setHasSaved] = React.useState(false)
  const [subQuestionState, setSubQuestionState] = React.useState(state.subQuestions)

  useEffect(() => {
    setPrimaryProblemState(state.primaryProblem)
  }, [state.primaryProblem])

  useEffect(() => {
    setSubQuestionState(state.subQuestions)
  }, [state.subQuestions])

  useEffect(() => {
    const wasmFile =
      'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/ort-wasm-simd-threaded.wasm' // Function to preload the WASM file
    const preloadWasm = async () => {
      try {
        const response = await fetch(wasmFile)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        // Wait for the download to complete
        await response.arrayBuffer()
      } catch (error) {
        console.error('Error loading WASM file:', error)
        // Optionally, handle error state here
      }
    }

    // Call the function to preload the WASM file
    preloadWasm()

    // const interval = setInterval(() => {
    //   setActiveDot((prevDot) => (prevDot + 1) % 3)
    // }, 300)

    getBrainstormSessions()
    // https://stackoverflow.com/questions/40099431/how-do-i-clear-location-state-in-react-router-on-page-reload
    // navigate(currentLocation.pathname, { replace: true })

    return () => {
      // clearInterval(interval)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      // if(audioTimeout.current){
      //   clearTimeout(audioTimeout.current)
      // }
    }
  }, [])

  React.useEffect(() => {
    // If the folks keep talking and don't let Odin interrupt, after 1 min,
    // stop trying to interrupt. Wait for another 5 minutes before trying again.
    if (state.interventionActualAudio) {
      if (!interventionTimeoutRef.current) {
        interventionTimeoutRef.current = setTimeout(() => {
          isTimeoutCancelled.current = true
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          timeoutRef.current = null

          dispatch({ type: 'SET_INTERVENTION_ACTUAL_AUDIO', interventionActualAudio: null })
          dispatch({ type: 'SET_SHOULD_STOP_INTERRUPTION', payload: true })
          // don't reset 'steerConversation', remember we add '.' after each steerConversation which
          // will trigger [state.steerConversation]
          // dispatch({ type: 'SET_STEER_CONVERSATION', steerConversation: '' })
        }, 60000)
      }
    }

    return () => {
      if (interventionTimeoutRef.current) {
        clearTimeout(interventionTimeoutRef.current)
        interventionTimeoutRef.current = null
      }
    }
  }, [state.interventionActualAudio])

  const getBrainstormSessions = async (selectedBrainstormId = null) => {
    try {
      const response = await getHttpRequest(`/brainstorm/get_brainstorm_sessions/${userId}`, {
        params: {
          brainstormId: selectedBrainstormId,
        },
      })
      if (selectedBrainstormId && response) {
        return response
      } else {
        setBrainstormSessions(response.topLevelProblems)
        setFilteredBrainstormSessions(response.topLevelProblems)

        const meetingsMap = new Map()
        const tagsMap = new Map()

        response.topLevelProblems.forEach((problem) => {
          if (problem.meetings) {
            problem.meetings.forEach((meeting) => {
              if (!meetingsMap.has(meeting.meetingId)) {
                meetingsMap.set(meeting.meetingId, meeting)
              }
            })
          }

          if (problem.tags) {
            problem.tags.forEach((tag) => {
              if (!tagsMap.has(tag.tagId)) {
                tagsMap.set(tag.tagId, tag)
              }
            })
          }
        })

        setMeetingsOnSessions(Array.from(meetingsMap.values()))
        setTagsOnSessions(Array.from(tagsMap.values()))
      }
    } catch (error) {
      console.log('getBrainstormSessions error', error)
    }
    return null
  }

  React.useEffect(() => {
    ;(async () => {
      if (selectedBrainstormSession) {
        setHasSaved(true)
        let response = await getBrainstormSessions(selectedBrainstormSession)
        //   navigate(`/${routes.brainstorm}`, {
        //     state: {
        //         brainstormData: response
        //       }
        // })
        dispatch({
          type: 'RESTORE_BRAINSTORM_SESSION',
          brainstormSession: response,
        })
        setBubbleTextIndex(3)
      }
    })()
  }, [selectedBrainstormSession])

  React.useEffect(() => {
    const index = 2
    if (state.clarifications?.[0]) {
      let newText = ''
      if (state.language === 'es') {
        newText = `Aquí tienes algo para considerar y ser aún más claro: '${state.clarifications[0]}'. Siéntete libre de discutir más y refinar el enunciado del problema, o si te parece que está bien, haz clic en Finalizar.`
      } else {
        newText = `Here's something to consider to be even more clear: '${state.clarifications[0]}' Feel free to discuss further and refine the problem statement, or if it looks good, click Finalize.`
      }
      const newMessages = [...messages]
      newMessages[index] = { ...newMessages[index], text: newText } // Modify the specific item
      setMessages(newMessages)
      // hasMessageUpdate.current = 1
    }
  }, [state.clarifications])

  const onSpeechStartcallBack = React.useCallback(() => {
    isTimeoutCancelled.current = true
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = null
    setUserStopsSpeaking(false)
  }, [setUserStopsSpeaking])

  const onSpeechEndcallBack = React.useCallback(async () => {
    timeoutRef.current = setTimeout(() => {
      if (!isTimeoutCancelled.current) {
        console.log('** timeout **')
        // DOUBLE CHECK HERE
        if (state.interventionActualAudio && state.steerConversation) {
          setUserStopsSpeaking(true)
          console.log('** interventionActualAudio **')
          // dispatch({ type: 'ADD_TO_AUDIO_QUEUE', audio: state.interventionActualAudio })
          onAudioChunkOutput([{ audio: state.interventionActualAudio, isOpeningSentence: false }])
          dispatch({ type: 'SET_ODIN_PENDING_SPEAKING', isOdinPendingSpeaking: true })
          setMessages((prev) => [...prev, { text: state.steerConversation, sender: 'other' }])
          dispatch({ type: 'SET_INTERVENTION_ACTUAL_AUDIO', interventionActualAudio: null })
          // user allows Odin to interrupt, so clear the timeout
          if (interventionTimeoutRef.current) {
            clearTimeout(interventionTimeoutRef.current)
            interventionTimeoutRef.current = null
          }
        }
      }
    }, 1000)

    isTimeoutCancelled.current = false
    accumulateDataChunks()
    await sendAudioData(state.meetingInstanceId, true)
  }, [
    state.progressState,
    state.meetingInstanceId,
    setUserStopsSpeaking,
    state.interventionActualAudio,
    state.steerConversation,
  ])

  React.useEffect(() => {
    if (state.recommendation.trim()) {
      console.log('add recommendation to messages list', state.recommendation)
      setMessages((prev) => [...prev, { text: state.recommendation, sender: 'other' }])
    }
  }, [state.recommendation])

  React.useEffect(() => {
    if (state.steerConversation) {
      let newTexs = [
        {
          text: state.language === 'es' ? 'Permíteme intervenir' : 'Hey, let me step in here',
          sender: 'other',
        },
      ]
      setMessages((prev) => [...prev, ...newTexs])
    }
  }, [state.steerConversation])

  const playNextAudio = () => {
    if (state.audioQueue.length > 0) {
      dispatch({ type: 'SET_IS_PLAYING', payload: true })
      const nextAudioUrl = state.audioQueue[0].url
      // isFromStreaming is only set for recommendation audio because they come in chunks
      audioRef.current.src = nextAudioUrl
      if (state.stopIntervention) {
        dispatch({ type: 'SET_IS_MUTED', isMuted: true })
        audioRef.current.muted = true
      }
      audioRef.current.play()
    }
  }

  useEffect(() => {
    console.log('userStopsSpeaking: ', userStopsSpeaking)
    console.log('state.isOdinPendingSpeaking: ', state.isOdinPendingSpeaking)
    console.log('state.audioQueue: ', state.audioQueue)
    console.log('state.isBotAudioPlaying: ', state.isBotAudioPlaying)
    if (
      state.audioQueue.length > 0 &&
      (userStopsSpeaking || state.audioQueue[0].isOpeningSentence) &&
      !state.isBotAudioPlaying
    ) {
      playNextAudio()
      console.log('Playing next audio')
    }
    if (state.audioQueue.length === 0 && !state.isBotAudioPlaying) {
      dispatch({ type: 'SET_ODIN_PENDING_SPEAKING', isOdinPendingSpeaking: false })
    }
  }, [state.audioQueue, state.isBotAudioPlaying, state.isOdinPendingSpeaking, userStopsSpeaking])

  const sendMessages = (text) => {}

  const handleAudioEnded = () => {
    URL.revokeObjectURL(state.audioQueue[0].url)
    setMessages((prev) => {
      if (prev.length > 12) {
        // Remove the first custom message after initial fixed messages
        // This assumes the message at index 6 or greater corresponds to the first audio
        let newMessages = [...prev]
        newMessages.splice(12, 1)
        console.log('newMessages: ', newMessages)
        return newMessages
      }
      return prev
    })
    let shouldMuteNextAudio =
      state.audioQueue.length > 1 &&
      state.audioQueue[0].isFromStreaming &&
      state.audioQueue[1].isFromStreaming &&
      state.isMuted
    console.log('mute 405: ', shouldMuteNextAudio)
    dispatch({ type: 'SET_IS_MUTED', isMuted: shouldMuteNextAudio })
    dispatch({
      type: 'REMOVE_FROM_AUDIO_QUEUE',
    })
    console.log('messages.length: ', messages.length)
  }

  React.useEffect(() => {
    console.log('messages ^^^^^^^^: ', messages)
    console.log('state.audioQueue ^^^^^^^^: ', state.audioQueue)
    console.log('bubbleTextIndex ^^^^^^^^: ', messages[bubbleTextIndex])
  }, [messages, state.audioQueue, bubbleTextIndex])

  const refreshQuestion = async (questionNeededRefresh, ind) => {
    console.log('refreshQuestion')
    setRefreshButtonLoading((prev) => ({ ...prev, [ind]: true }))
    try {
      await exploreProblems(questionNeededRefresh, true)
    } catch (error) {
      console.log('error: ', error)
    }
    setRefreshButtonLoading((prev) => ({ ...prev, [ind]: false }))
  }

  const generateProblemStatementSuggestion = async (problemStatement) => {
    setIsGeneratingPromptSuggestion(true)
    try {
      let result = await getHttpRequest(
        `/brainstorm/generate_problem_statement_suggestion/${problemStatement}`,
      )
      setIsGeneratingPromptSuggestion(false)
      if (result.response) {
        dispatch({
          type: 'SET_STATEMENT_RECOMMENDATION',
          recommendationRationale: result.response['rationale'],
          statementSuggestion: result.response['revised_statement'],
        })
      }
    } catch (err) {
      setIsGeneratingPromptSuggestion(false)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const toggleCollapseState = (ind) => {
    setCollapseStates((prev) => ({
      ...prev,
      [ind]: prev[ind] === undefined ? false : !prev[ind],
    }))
  }

  React.useEffect(() => {
    updateBubbleTextIndex(messages.length)
  }, [
    messages,
    state.progressState,
    state.isBrainstormingStarted,
    state.audioQueue,
    state.clarifications,
    state.isGeneratingProblems,
    state.problems,
    state.buttonTextState,
    state.primaryProblem,
  ])

  React.useEffect(() => {
    if (isThinking) {
      pause_audio()
      const randomIndex = Math.floor(Math.random() * numberList.length)
      setBubbleTextIndex(numberList[randomIndex])
    }
  }, [isThinking])

  React.useEffect(() => {
    if (!state.isGeneratingProblems) {
      setIsThinking(false)
    }
    // setIsThinking(false)
  }, [state.isGeneratingProblems])

  const updateBubbleTextIndex = (messageLength) => {
    if (!state.isBrainstormingStarted) {
      return
    }
    shouldNotHaveAudio.current = false
    console.log('bubbleTextIndex ~~', bubbleTextIndex)
    console.log('messages ccccc ~~', messages)
    console.log('state.isGeneratingProblems ~~', state.isGeneratingProblems)
    if (bubbleTextIndex === 0) {
      setBubbleTextIndex(bubbleTextIndex + 1)
    } else if (
      state.progressState === 'idle' &&
      !state.isGeneratingProblems &&
      numberList.includes(bubbleTextIndex) &&
      state.clarifications.length === 0
    ) {
      // in case problem genration failed..
      console.log('@@ ~~')
      setBubbleTextIndex(1)
    } else if (
      state.progressState === 'problems' &&
      !state.isGeneratingProblems &&
      state.clarifications.length === 0 &&
      state.primaryProblem
    ) {
      // when user clicks on 'draft' button for the very first time and it succedded with ONLY one problem
      console.log('$$% ~~')
      setBubbleTextIndex(10)
    } else if (
      state.progressState === 'problems' &&
      bubbleTextIndex === 1 &&
      state.clarifications.length > 0
    ) {
      // problem generation success, move to problem refinement step
      console.log('$$!! ~~')
      setBubbleTextIndex(bubbleTextIndex + 1)
    } else if (
      state.progressState === 'problems' &&
      !state.isGeneratingProblems &&
      numberList.includes(bubbleTextIndex) &&
      (state.clarifications.length > 0 || state.problems.length > 1)
    ) {
      console.log('## ~~')
      setBubbleTextIndex(2)
    } else if (
      state.progressState === 'refinement' &&
      !state.isGeneratingProblems &&
      numberList.includes(bubbleTextIndex) &&
      state.clarifications.length >= 0
    ) {
      console.log('@@@ ~~')
      // problem refinement success, show the refinement success message
      // when buttonTextState is one, is showing 'draft again' button
      // so we show the problem with clarification. otherwise show 'refine''
      if (state.buttonTextState === 1) {
        console.log(';;;; ~~')
        if (state.clarifications.length > 0 && state.refinementCount > 1) {
          console.log(';;;; 11 ~~')
          setBubbleTextIndex(11)
        } else {
          console.log(';;;; 22 ~~')
          setBubbleTextIndex(2)
        }
      } else {
        console.log('.... ~~')
        setBubbleTextIndex(4)
      }
    } else if (state.progressState === 'exploration' && bubbleTextIndex === 2) {
      setBubbleTextIndex(bubbleTextIndex + 1)
    } else if (state.progressState === 'exploration' && numberList.includes(bubbleTextIndex)) {
      setBubbleTextIndex(3)
    } else if (state.progressState === 'exploration' && bubbleTextIndex === 4) {
      // this condition is not used?
      console.log('++++ ~~')
      setBubbleTextIndex(bubbleTextIndex + 1)
    } else if (state.progressState === 'exploration' && messageLength >= 13) {
      // there are odin-init/user-init messages in the list, use them
      console.log('[][][][] ~~')
      setBubbleTextIndex(12)
    } else if (
      state.progressState === 'exploration' &&
      messageLength === 12 &&
      bubbleTextIndex === 12 &&
      state.audioQueue.length === 0
    ) {
      // new messages consumed completely, go back to step 3
      console.log('()()()() ~~')
      shouldNotHaveAudio.current = true
      setBubbleTextIndex(3)
    } else if (
      state.progressState === 'exploration' &&
      messageLength === 12 &&
      bubbleTextIndex === 12 &&
      state.audioQueue.length > 0 &&
      state.audioQueue[0].isAudioSteer
    ) {
      // steer conversation waiting... don't updae anything....
      console.log('----- ~~')
    }
    console.log('!!!! ~~')
  }

  React.useEffect(() => {
    console.log('bubbleTextIndex: ', bubbleTextIndex)
  }, [bubbleTextIndex])

  // console.log('bubbleTextIndex: ', bubbleTextIndex)

  const pause_audio = () => {
    if (audioRef.current && audioRef.current.currentTime) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      dispatch({ type: 'REMOVE_FROM_AUDIO_QUEUE' })
      console.log('mute 539: ', false)
      dispatch({ type: 'SET_IS_MUTED', isMuted: false })
    }
  }

  useEffect(() => {
    // update the question ideas storage to db
    if (
      state.isBrainstormingStarted &&
      state.meetingInstanceId &&
      state.questionIdeasStorage &&
      Object.keys(state.questionIdeasStorage).length > 0
    ) {
      ;(async () => {
        await postHttpRequest(`/brainstorm/update_brainstorm_instance/${state.meetingInstanceId}`, {
          questionIdeas: JSON.stringify(state.questionIdeasStorage),
        })
      })()
    }
  }, [state.questionIdeasStorage])

  useEffect(() => {
    const index = 2
    if (state.problems.length > 1) {
      // not called anymore
      let newMsg = t('Common:brainstorming.multipleProblems')
      setMessages((prev) => {
        let newMessages = [...prev]
        newMessages[index] = { ...newMessages[index], text: newMsg }
        return newMessages
      })
    }
  }, [state.problems])

  const filterSessions = (targetsArray, isMeeting) => {
    console.log('filterSessions: ', targetsArray)
    let filteredSessions = []
    setSelectedMeetingsOnSessions([])
    setSelectedTagsOnSessions([])

    if (targetsArray.length === 0) {
      setFilteredBrainstormSessions(brainstormSessions)
      return
    }

    if (isMeeting) {
      setSelectedMeetingsOnSessions(targetsArray)
      filteredSessions = brainstormSessions.filter((session) => {
        return targetsArray.some((item) =>
          session.meetings.some((meeting) => meeting.meetingId == item.meetingId),
        )
      })
    } else {
      setSelectedTagsOnSessions(targetsArray)
      filteredSessions = brainstormSessions.filter((session) => {
        return targetsArray.some((item) => session.tags.some((tag) => tag.tagId == item.tagId))
      })
    }
    setFilteredBrainstormSessions(filteredSessions)
  }

  const saveBrainstormSession = async (selectedTags = [], selectedMeetings = []) => {
    try {
      await postHttpRequest(`/brainstorm/save_brainstorm_session`, {
        meetingInstanceId: state.meetingInstanceId,
        discardedList: state.discardedList,
        highlightedSubProblems: state.highlightedQuestions,
        selectedMeetings: selectedMeetings.map((item) => item?.meetingId).filter((item) => item),
        selectedTags: selectedTags.filter((item) => item),
      })
      Toast.fire({
        icon: 'success',
        title: 'Brainstorming session saved successfully',
      })
      setHasSaved(true)
    } catch (error) {
      console.log('saveBrainstormSession error: ', error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
    }
  }

  const saveBrainstormSessionCheck = async () => {
    const result = await Swal.fire({
      title:
        state.language === 'es'
          ? 'Guardar sesión de lluvia de Ideas'
          : 'Save Brainstorming Session',
      text:
        state.language === 'es'
          ? '¿Quieres guardar esta sesión de lluvia de ideas?'
          : 'Do you want to save this brainstorming session?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    })

    if (result.isConfirmed) {
      let tempAvailableTags = []
      let tempAvailableMeetings = []
      try {
        if (!shouldHideTag) {
          let response = await getHttpRequest('/get_company_tags')
          // setAvailableTags(response.tags)
          tempAvailableTags = response.tags.map((tag) => ({
            value: tag.tagId,
            label: tag.tagName,
          }))
          setAvailableTags(tempAvailableTags)
          // to handle case when user creates a new tag, saves, closes the modal then open modal again.
          // replace the -1 tag with the actual tag
          setSelectedTagsCurrentSession((prev) => {
            // let previous_new_tags = prev.filter(tag => tag.value == -1)
            return prev
              .map((tag) => {
                if (tag.value == -1) {
                  let existingTag = tempAvailableTags.find(
                    (t) => t.label.trim() == tag?.label?.trim(),
                  )
                  // users save it then open again
                  if (existingTag) {
                    return existingTag
                  }
                  // users don't save it, then open again
                  return tag
                }
                return tag
              })
              .filter((tag) => tag)
          })
        }
        let response = await getHttpRequest('/get_meetings_by_user')
        setAvailableMeetings(response.meetings)
        tempAvailableMeetings = response.meetings
      } catch (error) {
        console.log('error saveBrainstormSessionCheck: ', error)
      }
      // first check - if users can't see any tags or meetings, save the session directly
      if (
        !allowBulkConvertToMH &&
        tempAvailableTags.length === 0 &&
        tempAvailableMeetings.length === 0
      ) {
        await saveBrainstormSession()
      } else {
        setIsBrainstormMeetingTagModalOpen(true)
      }
    }
  }

  const sendNote = async () => {
    console.log('state.brainstormMap: ', state.brainstormMap)
    //set Loading
    setIsLoading(true)
    // find the parking lot topic and remove it from the action_plans
    const parking_lot_topic = state.brainstormMap.action_plans.find((topic) => topic.is_parking_lot)
    if (parking_lot_topic) {
      state.brainstormMap.action_plans = state.brainstormMap.action_plans.filter(
        (topic) => topic.topic !== parking_lot_topic.topic,
      )
    }
    const summarizedActionPlans = await getSummarizedActionPlans({
      action_plans: state.brainstormMap.action_plans,
      language_setting: state.language,
    })
    console.log('summarizedActionPlans: ', summarizedActionPlans)
    let copyOfBrainstormMap = JSON.parse(JSON.stringify(state.brainstormMap))
    //filter is_parking_lot topics
    copyOfBrainstormMap.action_plans = summarizedActionPlans.summarized_action_plans.action_plans
    // remove the comments of every topic in action_plans and add one cmment in each topic as summary_comment in the summarized_action_plans
    //check if there is at least 1 summary_comment in the summarized_action_plans is not empty
    copyOfBrainstormMap.action_plans = copyOfBrainstormMap.action_plans.map((topic) => {
      if (topic.summary_comment?.trim() !== '') {
        return {
          ...topic,
          comments: [
            ...topic.comments,
            {
              id: 'summary_comment_' + topic.topic,
              isAccepted: true,
              text: topic?.summary_comment, // Each topic has its own summary comment
              isAddedInLastRound: true,
              is_converted: false,
              is_summary_comment: true,
            },
          ],
        }
      } else {
        return topic
      }
    })
    // add parking lot topic back to the mindmap
    if (parking_lot_topic) {
      copyOfBrainstormMap.action_plans.push(parking_lot_topic)
    }
    console.log('copyOfBrainstormMap: ', copyOfBrainstormMap)
    setIsLoading(false)
    navigate(`/${routes.meetingMinutes}`, {
      state: {
        mindmap: copyOfBrainstormMap,
        brainstormInstanceId: state.brainstormInstanceId,
        meetingInstanceId: state.meetingInstanceId,
        language: state.language,
      },
    })
  }

  function isMacUser() {
    let isBrian = true
    if (!isBrian) return false

    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('macintosh') || userAgent.includes('mac os')
  }

  const updateSelectedTagsCurrentSession = (tags) => {
    setSelectedTagsCurrentSession(tags)
  }

  const updateSelectedMeetingsCurrentSession = (meetings) => {
    setSelectedMeetingsCurrentSession(meetings)
  }

  const updateSubQuestion = async (newQuestion, ind) => {
    if (newQuestion.trim() === '') return

    try {
      let response = await postHttpRequest(`/brainstorm/update_sub_question`, {
        meetingInstanceId: state.meetingInstanceId ?? '',
        subQuestion: newQuestion,
        subQuestionIndex: ind,
      })
      if (response.sub_questions) {
        dispatch({ type: 'SET_SUB_QUESTIONS', subQuestions: response.sub_questions })
      }
    } catch (error) {
      console.log('updateSubQuestion error: ', error)
    }
  }

  return (
    <>
      {isLoading && <InPageLoader />}
      {!isLoading && (
        <>
          {isBrainstormMeetingTagModalOpen &&
            (availableMeetings.length > 0 || availableTags.length > 0 || allowBulkConvertToMH) && (
              <BrainstormMeetingTagModal
                availableMeetings={availableMeetings}
                availableTags={availableTags}
                closeModal={() => setIsBrainstormMeetingTagModalOpen(false)}
                saveBrainstormSession={saveBrainstormSession}
                shouldHideTag={shouldHideTag}
                setSelectedTagsCurrentSession={updateSelectedTagsCurrentSession}
                setSelectedMeetingsCurrentSession={updateSelectedMeetingsCurrentSession}
                selectedMeetingsCurrentSession={selectedMeetingsCurrentSession}
                selectedTagsCurrentSession={selectedTagsCurrentSession}
                meetingInstanceId={state.meetingInstanceId}
                discardedList={state.discardedList}
                highlightedSubProblems={state.highlightedQuestions}
                allowBulkConvertToMH={allowBulkConvertToMH}
              />
            )}
          {/* To push down the contents of this page to avoid overlapping with the header, 
      we add a div with a margin-top of 3rem. */}
          <div style={{ marginTop: '3rem' }}></div>
          <div>
            {isMacUser() && (
              <>
                <div style={{ backgroundColor: '#e1f1fa', padding: '3px', fontSize: 'small' }}>
                  {t('Common:modalMessages.detectedUsingAMac')} &nbsp;
                  <a
                    href="https://teams.microsoft.com/v2/Calendar"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <u>{t('Common:modalMessages.clickHere')}</u>
                  </a>{' '}
                  &nbsp;
                  {t('Common:modalMessages.openMeetingFromCalendar')}
                </div>
              </>
            )}
          </div>
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '0.5rem',
            }}
          >
            {state.stream && (
              <MemoBrainstormVadCompomnent
                stream={state.stream}
                onSpeechStartcallBack={onSpeechStartcallBack}
                onSpeechEndcallBack={onSpeechEndcallBack}
                // isGeneratingProblems={state.isGeneratingProblems}
                isPaused={state.isPaused}
                setHasStarted={setHasStarted}
              />
            )}

            <audio
              ref={audioRef}
              style={{ display: 'block' }}
              onEnded={handleAudioEnded}
              muted={state.isMuted}
            ></audio>
            <div
              style={{
                width: isQuestionsCollapsed ? '90%' : '70%',
                overflowX: 'hidden',
                height: '90vh',
              }}
            >
              <span
                style={{
                  fontSize: '1.5rem',
                  color: 'rgb(68, 114, 196)',
                  fontWeight: 'bold',
                }}
              >
                {/* {t('Common:navigation.brainstorming')} */}
                {state.language === 'es' ? 'Facilitador IA' : 'AI Facilitator'}
              </span>
              {/* {state.progressState === 'idle' && <div>Let's define your problem statement</div>} */}

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {state.isBrainstormingStarted && state.primaryProblem === '' && (
                  <div>
                    <span
                      style={{
                        fontSize: '1rem',
                        color: 'rgb(170, 194, 241)',
                        padding: '0px 0px 0px 1rem',
                      }}
                    >
                      {/* {t('Common:brainstorming.problemStatementWillAppearHere')} */}
                      {state.language === 'es'
                        ? 'Tu declaración del problema aparecerá aquí. Descríbela a Odin y haz clic en el botón Enunciado preliminar cuando termines.'
                        : 'Your problem statement will appear here. Describe it to Odin and click the Draft button when you are done.'}
                    </span>
                  </div>
                )}
                {!state.isBrainstormingStarted && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      minWidth: '18rem',
                      marginTop: '2rem',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {!selectedBrainstormSession && (
                        <h3
                          style={{
                            color: 'rgb(91,132,203)',
                            textAlign: 'left',
                            marginBlockEnd: 'auto',
                          }}
                        >
                          <span>
                            {state.language === 'es'
                              ? 'Seleccione una sesión anterior'
                              : 'Select a previous session'}
                          </span>
                        </h3>
                      )}
                      {selectedBrainstormSession && (
                        <div
                          style={{
                            color: '#0926D5',
                            textDecoration: 'underline',
                            marginLeft: '0.5rem',
                            margin: 'auto 10px',
                          }}
                        >
                          <span
                            style={{ cursor: 'pointer', fontSize: '14px' }}
                            onClick={() => {
                              navigate(0)
                            }}
                          >
                            {state.language === 'es'
                              ? 'Haga clic aquí para obtener uno nuevo'
                              : 'Click here for a new one'}
                          </span>
                        </div>
                      )}
                    </div>

                    {!selectedBrainstormSession && (
                      <>
                        {brainstormSessions.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                gap: '1rem',
                                margin: '0.5rem 0rem 1rem 0rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '0.5rem',
                                  minWidth: '15rem',
                                  maxWidth: '25rem',
                                }}
                              >
                                <label>
                                  <span>{state.language === 'es' ? 'Reunión' : 'Meeting'}</span>
                                </label>
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  name="selectMeeting"
                                  value={selectedMeetingsOnSessions}
                                  isMulti
                                  onChange={(e) => {
                                    filterSessions(e, true)
                                  }}
                                  getOptionLabel={(option) => option.meetingName}
                                  getOptionValue={(option) => option.meetingId}
                                  options={meetingsOnSessions}
                                  menuPortalTarget={document.body}
                                  width="35%"
                                />
                              </div>

                              {!shouldHideTag && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5rem',
                                    minWidth: '15rem',
                                    maxWidth: '25rem',
                                  }}
                                >
                                  <label>
                                    <span>Tag</span>
                                  </label>
                                  <Select
                                    placeholder={t('Common:filter.select') + '...'}
                                    name="selectTag"
                                    value={selectedTagsOnSessions}
                                    isMulti
                                    onChange={(e) => {
                                      filterSessions(e, false)
                                    }}
                                    getOptionLabel={(option) => option.tagName}
                                    getOptionValue={(option) => option.tagId}
                                    options={tagsOnSessions}
                                    menuPortalTarget={document.body}
                                    width="35%"
                                  />
                                </div>
                              )}
                            </div>

                            <div className="brainstorm-sessions-table">
                              <div
                                className={
                                  shouldHideTag
                                    ? 'brainstorm-sessions-row-hide-tag'
                                    : 'brainstorm-sessions-row'
                                }
                              >
                                <div className="brainstorm-sessions-header">
                                  {state.language === 'es'
                                    ? 'Planteamiento del problema'
                                    : 'Problem Statement'}
                                </div>
                                <div className="brainstorm-sessions-header">
                                  {state.language === 'es' ? 'En la fecha' : 'Date Held'}{' '}
                                  (MM-DD-YYYY)
                                </div>
                                <div className="brainstorm-sessions-header">
                                  {state.language === 'es'
                                    ? 'Reunión asociada'
                                    : 'Associated Meeting'}
                                </div>
                                {!shouldHideTag && (
                                  <div className="brainstorm-sessions-header">
                                    {state.language === 'es'
                                      ? 'Etiqueta asociada'
                                      : 'Associated Tag'}
                                  </div>
                                )}
                              </div>
                              {filteredBrainstormSessions.map((session, sessionIndex) => {
                                return (
                                  <div
                                    key={sessionIndex}
                                    className={`${shouldHideTag ? 'brainstorm-sessions-row-hide-tag' : 'brainstorm-sessions-row'}`}
                                    style={{
                                      backgroundColor:
                                        sessionsHoverIndex === sessionIndex
                                          ? 'rgb(220, 220, 220)'
                                          : 'rgb(243, 244, 246)',
                                    }}
                                    onClick={() => {
                                      setSelectedBrainstormSession(session.brainstormId)
                                    }}
                                    onMouseEnter={() => setSessionsHoverIndex(sessionIndex)} // Set hover index to current index
                                    onMouseLeave={() => setSessionsHoverIndex(-1)} // Reset hover index
                                  >
                                    <div className="chatbot-team-performance-item">
                                      {session.problemStatement}
                                    </div>
                                    <div className="chatbot-team-performance-item">
                                      {convertTimeStamToMMDDYYYYDash(session.createdAt)}
                                    </div>
                                    <div className="chatbot-team-performance-item">
                                      <div
                                        style={{
                                          paddingTop: '0.2rem',
                                          marginBottom: '0.3rem',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {' '}
                                        {session.meetings.map((meeting, meetingInd) => {
                                          return (
                                            <div
                                              key={meetingInd}
                                              style={{
                                                backgroundColor: '#e6e6e6',
                                                color: 'black',
                                                padding: '0.1rem 0.2rem',
                                                borderRadius: '0.5rem',
                                                marginLeft: '0.5rem',
                                                marginBottom: '0.3rem',
                                              }}
                                            >
                                              {meeting.meetingName}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    {!shouldHideTag && (
                                      <div className="chatbot-team-performance-item">
                                        <div
                                          style={{
                                            paddingTop: '0.2rem',
                                            marginBottom: '0.3rem',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                          }}
                                        >
                                          {session.tags.map((tag, tagIndex) => {
                                            return (
                                              <div
                                                key={tagIndex}
                                                style={{
                                                  backgroundColor: '#e6e6e6',
                                                  color: 'black',
                                                  padding: '0.1rem 0.2rem',
                                                  borderRadius: '0.5rem',
                                                  marginLeft: '0.5rem',
                                                  marginBottom: '0.3rem',
                                                }}
                                              >
                                                {tag.tagName}
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        ) : (
                          <div>
                            <b>
                              {state.language === 'es'
                                ? 'No hay sesiones previas'
                                : 'There are no previous sessions'}
                            </b>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {state.progressState !== 'exploration' && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {state.primaryProblem === '' && (
                        <>
                          {state.problems.map((problem, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  border: '1px solid lightgray',
                                  borderRadius: '10px',
                                  margin: '0.5rem',
                                  padding: '0.5rem',
                                  backgroundColor:
                                    hoverIndex === index && state.clarifications.length === 0
                                      ? 'rgb(220, 220, 220)'
                                      : 'rgb(243, 244, 246)',
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: 'UPDATE_PRIMARY_PROBLEM',
                                    problem: problem,
                                  })
                                }}
                                onMouseEnter={() => setHoverIndex(index)} // Set hover index to current index
                                onMouseLeave={() => setHoverIndex(-1)} // Reset hover index
                              >
                                <b>{t('Common:brainstorming.problemstatement')}:</b> {problem}
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>

                    {state.isBrainstormingStarted && (
                      <>
                        <div
                          style={{
                            border: '1px solid lightgray',
                            borderRadius: '10px',
                            margin: '0.5rem',
                            padding: '0.5rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                          }}
                          onBlur={() => {
                            if (
                              primaryProblemState !== '' &&
                              primaryProblemState !== state.primaryProblem
                            ) {
                              // generateProblemStatementSuggestion(primaryProblemState)
                            }
                          }}
                        >
                          <b>
                            {state.language === 'es'
                              ? 'Declaración del Problema'
                              : 'Problem Statement'}
                            :
                          </b>
                          <div
                            className="review-generated-topics-list-item"
                            style={{ width: '100%' }}
                          >
                            <TextArea
                              type="text"
                              onChange={(e) => {
                                // I think it's fine fow now...  the state is small at 'problems', 'refinement' states
                                setPrimaryProblemState(e.target.value)
                              }}
                              onBlur={() => {
                                dispatch({
                                  type: 'UPDATE_PRIMARY_PROBLEM',
                                  problem: primaryProblemState,
                                })
                              }}
                              value={primaryProblemState}
                              style={{
                                height: '2.5rem',
                                backgroundColor: 'white',
                                width: '100%',
                                border: `1px solid #77aaff`,
                              }}
                              // disabled={isFinalized}
                              spellCheck={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {isGeneratingPromptSuggestion && (
                      <div>
                        <InPageLoader inComponent={true} />
                      </div>
                    )}
                    {state.recommendationRationale && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            border: '1px solid lightgray',
                            borderRadius: '10px',
                            margin: '0.5rem',
                            padding: '0.5rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                          }}
                        >
                          <b>{t('Common:brainstorming.odinsRecommendation')}:</b>
                          <div>{state.statementSuggestion}</div>
                          <br />
                          {state.recommendationRationale && (
                            <div>
                              <b>{t('Common:brainstorming.rationale')}:</b>
                              <div>{state.recommendationRationale}</div>
                            </div>
                          )}
                        </div>
                        <div
                          className="sleek-button sleek-blue"
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() => {
                            dispatch({
                              type: 'UPDATE_PRIMARY_PROBLEM',
                              problem: state.statementSuggestion,
                            })
                            dispatch({
                              type: 'SET_STATEMENT_RECOMMENDATION',
                              recommendationRationale: null,
                              statementSuggestion: null,
                            })
                          }}
                        >
                          {state.language === 'es' ? 'Aceptar' : 'Accept'}
                        </div>
                      </div>
                    )}

                    {/* {state.problems.length > 0 && <span style={{margin:"1rem"}}>We will only focus on the first one, </span>} */}

                    {state.clarifications.length > 0 && (
                      <div
                        style={{
                          border: '1px solid lightgray',
                          borderRadius: '10px',
                          margin: '0.5rem',
                          padding: '0.5rem',
                          backgroundColor: 'rgb(243, 244, 246)',
                        }}
                      >
                        <b>{t('Common:brainstorming.improveClarity')}</b>
                        <br />
                        {state.clarifications[0]}
                      </div>
                    )}
                  </>
                )}
              </div>

              {state.progressState === 'exploration' && state.brainstormMap && (
                <MindMap
                  jsonData={state.brainstormMap}
                  // ok we need key to hardrefresh the mindmap, for now I set it to the problem statement
                  // so when the selection changes, the mindmap will refresh
                  key={JSON.stringify(state.brainstormMap.problem)}
                  readOnly={state.readOnly}
                  brainstormId={selectedBrainstormSession}
                  allowBulkConvertToMH={allowBulkConvertToMH}
                />
              )}
            </div>
            <div
              className={`right-panel ${isQuestionsCollapsed ? 'collapsed' : 'expanded'}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                borderLeft: '1px solid lightgray',
                // marginTop: '3rem',
                // marginLeft: '1rem',
                // borderLeft: '1px solid grey',
                // borderRight: '1px solid grey',
              }}
            >
              {isQuestionsCollapsed ? (
                <div class="fitted-button" onClick={() => setIsQuestionsCollapsed(false)}>
                  <span class="material-symbols-outlined">right_panel_open</span>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div class="fitted-button" onClick={() => setIsQuestionsCollapsed(true)}>
                    <span class="material-symbols-outlined">chevron_right</span>
                  </div>
                  {!state.readOnly && (
                    <div
                      className="review-generated-topics-list-item"
                      style={{
                        width: '350px',
                        display: 'flex',
                        flexDirection: 'row',
                        // alignItems: 'flex-end',
                        gap: '0.3rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', marginRight: '0.5rem' }}>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                          {state.language === 'es' ? 'No interrumpas' : "Don't interrupt"}&nbsp;
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            // setToggleLanguage(true)
                            dispatch({ type: 'TOGGLE_STOP_INTERVENTION' })
                          }}
                        >
                          {!state.stopIntervention && (
                            <span
                              style={{
                                fontSize: '35px',
                                color: 'rgb(176, 173, 173)',
                                cursor: 'pointer',
                              }}
                              class="material-symbols-outlined"
                            >
                              toggle_off
                            </span>
                          )}

                          {state.stopIntervention && (
                            <span
                              style={{
                                fontSize: '35px',
                                color: 'rgb(0, 173, 173)',
                                cursor: 'pointer',
                              }}
                              class="material-symbols-outlined"
                            >
                              toggle_on
                            </span>
                          )}
                        </div>
                      </div>
                      {state.isBrainstormingStarted === false && (
                        <>
                          <span
                            style={{
                              display: 'inline-block',
                              width: '1.5px',
                              backgroundColor: 'black',
                              height: '1em',
                            }}
                          />
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                              style={{
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                marginLeft: '0.5rem',
                              }}
                            >
                              {t('Common:aiMemo.esp')}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                              onClick={() => {
                                // setToggleLanguage(true)
                                dispatch({
                                  type: 'SET_LANGUAGE',
                                  language: state.language === 'es' ? 'en' : 'es',
                                })
                              }}
                            >
                              {state.language === 'es' && (
                                <span
                                  style={{
                                    fontSize: '35px',
                                    color: 'rgb(0, 173, 173)',
                                    cursor: 'pointer',
                                  }}
                                  class="material-symbols-outlined"
                                >
                                  toggle_off
                                </span>
                              )}

                              {state.language !== 'es' && (
                                <span
                                  style={{
                                    fontSize: '35px',
                                    color: 'rgb(0, 173, 173)',
                                    cursor: 'pointer',
                                  }}
                                  class="material-symbols-outlined"
                                >
                                  toggle_on
                                </span>
                              )}
                              <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                {t('Common:aiMemo.eng')}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {!isQuestionsCollapsed && (
                <span style={{ margin: '0.5rem', fontSize: '18px', color: 'rgb(68, 114, 196)' }}>
                  {state.language === 'es'
                    ? 'Odin, tu consultor de IA'
                    : 'Odin, your AI Consultant'}
                </span>
              )}

              <div
                style={{
                  display: isQuestionsCollapsed ? 'none' : 'flex',
                  flexDirection: 'column',
                  alignSelf: 'flex-start',
                  justifySelf: 'flex-start',
                  paddingTop: '1rem',
                  width: '100%',
                }}
              >
                {subQuestionState.map((question, ind) => {
                  return (
                    <div
                      key={ind}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '0.5rem',
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                        margin: '0.2rem',
                        padding: '0.5rem',
                        backgroundColor: 'rgb(243, 244, 246)',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ fontWeight: 'bold' }}>Q{ind + 1}: </span> &nbsp;
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div
                                style={{
                                  color: state.highlightedQuestions.includes(ind + 1)
                                    ? 'red'
                                    : 'black',
                                  width: '100%',
                                }}
                              >
                                {/* {question} */}
                                <div
                                  className="review-generated-topics-list-item"
                                  style={{ width: '100%' }}
                                >
                                  <TextArea
                                    type="text"
                                    onChange={(e) => {
                                      setSubQuestionState((prev) => {
                                        let newSubQuestions = [...prev]
                                        newSubQuestions[ind] = e.target.value
                                        return newSubQuestions
                                      })
                                    }}
                                    onBlur={async () => {
                                      await updateSubQuestion(question, ind)
                                    }}
                                    value={question}
                                    style={{
                                      height: '4.5rem',
                                      backgroundColor: 'white',
                                      width: '100%',
                                      color: state.highlightedQuestions.includes(ind + 1)
                                        ? 'red'
                                        : 'black',
                                      // border: `1px solid #77aaff`,
                                    }}
                                    disabled={state.readOnly}
                                    spellCheck={false}
                                  />
                                </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {state.questionIdeasStorage?.[ind]?.length > 0 && (
                                  <div
                                    className="box_header_wrapper NOT_STARTED_box"
                                    style={{ display: 'flex', marginLeft: '0' }}
                                  >
                                    <span class="title_count">
                                      {state.questionIdeasStorage?.[ind]?.length}
                                    </span>
                                  </div>
                                )}
                                {!state.readOnly && (
                                  <div
                                    className="sleek-button sleek-blue"
                                    onClick={async () => {
                                      await refreshQuestion(question, ind)
                                    }}
                                    style={{
                                      marginTop: 'auto',
                                      height: '1.5rem',
                                    }}
                                  >
                                    <span
                                      className="material-symbols-outlined icon-size"
                                      style={{
                                        display: 'inline-block',
                                        transform: refreshButtonLoading?.[ind]
                                          ? 'rotate(360deg)'
                                          : 'rotate(0deg)',
                                        transition: 'transform 1s linear',
                                        transformOrigin: 'center',
                                        ...(refreshButtonLoading?.[ind] === true && {
                                          transform: 'rotate(360deg)',
                                          transition: 'none',
                                          animation: 'infinite-rotation 0.5s linear infinite',
                                        }),
                                        fontSize: '15px',
                                      }}
                                    >
                                      refresh
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            {state.questionIdeasStorage?.[ind]?.length > 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginLeft: '1rem',
                                }}
                              >
                                {collapseStates?.[ind] !== undefined && !collapseStates?.[ind] && (
                                  <span
                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                    class="material-symbols-outlined"
                                    onClick={() => {
                                      toggleCollapseState(ind)
                                    }}
                                  >
                                    arrow_drop_up
                                  </span>
                                )}

                                {(collapseStates?.[ind] === undefined || collapseStates?.[ind]) && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={() => {
                                      toggleCollapseState(ind)
                                    }}
                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                  >
                                    arrow_drop_down
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {collapseStates?.[ind] !== undefined && !collapseStates?.[ind] && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            marginLeft: '1rem',
                          }}
                        >
                          <ul>
                            {state.questionIdeasStorage?.[ind]
                              ?.filter((i) => i !== undefined)
                              ?.map((idea, ideaIndex) => {
                                return (
                                  <li key={ideaIndex}>
                                    <span>{idea}</span>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }}>
                {/* odin bubble image */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifySelf: 'flex-end',
                  }}
                >
                  <SpeechBubble
                    text={messages[bubbleTextIndex]?.text}
                    sender={messages[bubbleTextIndex]?.sender}
                    index={bubbleTextIndex}
                    shouldNotHaveAudio={shouldNotHaveAudio.current || state.stopIntervention}
                    isQuestionsCollapsed={isQuestionsCollapsed}
                    isSpanish={state.language === 'es'}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      width: '100%',
                      justifyContent: 'flex-end',
                      maxWidth: '300px',
                    }}
                  >
                    {/* (hasStarted && !state.debugDictQuestions && !state.isPaused && !isThinking) &&  */}
                    {hasStarted && !state.isPaused && !isThinking && !isQuestionsCollapsed && (
                      // <div
                      //   style={{
                      //     display: 'flex',
                      //     width: '100%',
                      //     justifyContent: 'center',
                      //     alignItems: 'center',
                      //   }}
                      // >
                      //   <SpeakingAnimationCircle />
                      // </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          // paddingLeft: '6rem',
                          alignItems: 'flex-end',
                        }}
                      >
                        <span> Listening </span> &nbsp;
                        {/* {[0, 1, 2].map((index) => (
                    <span key={index} style={index === activeDot ? activeDotStyle : dotStyle}>
                      {'\u2022'}
                    </span>
                  ))} */}
                      </div>
                    )}

                    {!isQuestionsCollapsed && <OdinCircle isThinking={isThinking} />}
                  </div>
                </div>
                {/* buttons */}
                {
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      className="review-generated-topics-list-item"
                      style={{
                        width: '350px',
                        display: 'flex',
                        flexDirection: isQuestionsCollapsed ? 'column' : 'row',
                        // alignItems: 'flex-end',
                        gap: '0.3rem',
                        marginTop: '1rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {state.isBrainstormingStarted === false && !state.readOnly && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <BrainstormAudioButton />
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>
                              {state.language === 'es'
                                ? 'Estoy realizando una reunión en línea'
                                : "I'm holding an online meeting"}
                            </span>
                            <div style={{ margin: 'auto' }}>
                              <input
                                type="checkbox"
                                checked={!state.useMicOnly}
                                onChange={() => dispatch({ type: 'TOGGLE_USE_MIC_ONLY' })}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {state.isBrainstormingStarted === true && !state.readOnly && !isThinking && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            onClick={() =>
                              dispatch({
                                type: 'SET_IS_PAUSED',
                                isPaused: !state.isPaused,
                              })
                            }
                            className={`sleek-button ${state.isPaused ? 'play-circle' : 'pause-circle'}`}
                            style={{
                              display: 'flex',
                              width: 'fit-content',
                              height: '2rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              // border: 'none',
                              color: 'rgb(255, 255, 255)',
                              backgroundColor: state.isPaused ? '#70AD47' : '#FFC000',
                              border: '1px solid rgb(255, 255, 255)',
                            }}
                          >
                            {state.isPaused ? (
                              <span style={{ fontSize: '2rem' }} class="material-symbols-outlined">
                                play_circle
                              </span>
                            ) : (
                              <span style={{ fontSize: '2rem' }} class="material-symbols-outlined">
                                pause_circle
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {state.meetingInstanceId &&
                        !isThinking &&
                        state.primaryProblem &&
                        (state.progressState === 'problems' ||
                          state.progressState === 'refinement') && (
                          <RefineProblemButton setIsThinking={setIsThinking} />
                        )}

                      {((state.meetingInstanceId &&
                        !isThinking &&
                        state.progressState !== 'exploration') ||
                        (state.primaryProblem !== '' && state.progressState !== 'exploration')) && (
                        // The div below hosts draft statement and continue buttons, logic in this if needs to be refined
                        <DoneButton
                          setIsThinking={setIsThinking}
                          primaryProblemState={primaryProblemState}
                        />
                      )}

                      {state.progressState === 'exploration' &&
                        !state.readOnly &&
                        state.brainstormMap && (
                          <div
                            onClick={generateMMRecommendation}
                            className={`sleek-button play-circle`}
                            style={{
                              display: 'flex',
                              width: '6rem',
                              fontSize: '0.8rem',
                              height: '2rem',
                              //height: '1.5rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              color: 'rgb(255, 255, 255)',
                              backgroundColor: 'rgb(68, 114, 196)',
                              border: '1px solid rgb(255, 255, 255)',
                            }}
                          >
                            {state.isGeneratingMMRec ? (
                              <div
                                style={{
                                  width: '0.5rem',
                                  height: '0.5rem',
                                  margin: '0',
                                  borderTopColor: 'white',
                                }}
                                className="loading-spinner icon-size-l"
                              ></div>
                            ) : null}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                              <span style={{ textAlign: 'center' }}>
                                {state.language === 'es' ? 'Sugerir Acciones' : 'Suggest Actions'}
                              </span>
                            </div>
                          </div>
                        )}

                      {state.progressState === 'exploration' &&
                        !state.readOnly &&
                        state.brainstormMap && (
                          <div
                            onClick={saveBrainstormSessionCheck}
                            className={`sleek-button play-circle`}
                            style={{
                              display: 'flex',
                              width: '5rem',
                              fontSize: '0.8rem',
                              height: '2rem',
                              //height: '1.5rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              color: 'rgb(255, 255, 255)',
                              backgroundColor: 'rgb(68, 114, 196)',
                              border: '1px solid rgb(255, 255, 255)',
                            }}
                          >
                            {state.language === 'es' ? 'Guardar' : 'Save'}
                          </div>
                        )}

                      {state.progressState === 'exploration' && state.brainstormMap && hasSaved && (
                        <div
                          onClick={sendNote}
                          className={`sleek-button play-circle`}
                          style={{
                            display: 'flex',
                            width: '8rem',
                            fontSize: '0.8rem',
                            height: '2rem',
                            //height: '1.5rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgb(68, 114, 196)',
                            border: '1px solid rgb(255, 255, 255)',
                          }}
                        >
                          {state.language === 'es'
                            ? 'Enviar por correo electrónico'
                            : 'Send Via Email'}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BrainstormComponent
