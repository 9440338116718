import React, { useState, useEffect } from 'react'
import generateUniqueId from 'generate-unique-id'

import Modal from '../../../../components/Modal/index'
import { customFieldList, dynamicLists } from '../../../../utils/activityStatus'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption'

import validate from '../../schemas/customValidation'

import useGetListItemsGet from '../../../../api/services/customTemplate.service'

import './style.scss'
import CustomFieldListModal from './CustomFieldListModal'
import InPageLoader from '../../../../components/InPageLoader'

const initialValues = {
  fieldId: '',
  name: '',
  fieldType: '',
  required: false,
  defaultForActivityName: false,
  isDeleted: false,
}

const AddCustomField = ({
  view,
  setModelView,
  setCustomFieldData,
  customFieldData,
  setEditCustomData,
  editCustomData,
  mode,
}) => {
  const [form, updateForm] = useState(initialValues)
  const [customFieldListModalIsOpen, setCustomFieldListModalIsOpen] = useState(false)

  const [contentList, setContentList] = useState([])
  const [selectedContentList, setSelectedContentList] = useState([])
  const [selectedOptionExisting, setSelectedOptionExisting] = useState('')
  const [listName, setListName] = useState('')

  const [createNewList, setCreateNewList] = useState(false)

  const [validationErrors, setValidationErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [selectedList, setSelectedList] = useState(null)

  useEffect(() => {
    if (editCustomData != '') {
      updateForm(editCustomData)
      getCustomTemplate()
    }
  }, [])

  const {
    data: result,
    isLoading: resultIsLoading,
    isFetching: resultIsFetching,
  } = useGetListItemsGet()

  useEffect(() => {
    getCustomTemplate()
  }, [result])

  const getCustomTemplate = async () => {
    setIsLoading(true)
    setIsLoading(false)
    if (result?.list) {
      let modifiedArr = result.list.map(function (element) {
        return { value: element.listId, name: element.listName }
      })
      setContentList(modifiedArr)
      setSelectedContentList(result.list)

      if (editCustomData.fieldType === 4) {
        for (let i = 0; i < modifiedArr.length; i++) {
          if (modifiedArr[i].value === editCustomData.listId) {
            setSelectedOptionExisting(modifiedArr[i])
          }
        }
      } else if (editCustomData.fieldType === 6) {
        for (let i = 0; i < dynamicLists.length; i++) {
          if (dynamicLists[i].value === editCustomData.listId) {
            setSelectedOptionExisting(dynamicLists[i])
          }
        }
      }
    }
  }

  function fieldLabelInputHandler(e, data) {
    let name
    if (data) {
      name = data.name
    } else {
      name = e.target.value
    }
    updateForm({ ...form, name: name })
  }

  function fieldTypeInputHandler(e, data) {
    let value
    if (data) {
      value = e.type
    } else {
      value = !form.required
    }
    updateForm({
      ...form,
      fieldType: value,
      listId: '',
      defaultForActivityName:
        value === 4 || value === 6 ? false : (form.defaultForActivityName ?? false),
    })
  }

  function fieldRequiredInputHandler(e) {
    updateForm({ ...form, required: e.target.checked })
  }

  const submitForm = async () => {
    const formData = { ...form }
    const temp_data = [...customFieldData]

    if (formData.fieldType === 6) {
      formData.listName = listName
      formData.listId = selectedOptionExisting?.value ?? null

      const errors = validate(formData)
      if (Object.keys(errors).length > 0) {
        setValidationErrors({ ...errors })
        return
      } else {
        setValidationErrors({})
      }
      if (editCustomData == '') {
        const id = generateUniqueId({
          length: 6,
          useLetters: false,
        })
        formData.fieldId = id
        //temp_data.push(formData); ////// This line duplicates custom filed when it is a dynamic list
      } else {
        formData.fieldId = editCustomData.fieldId
        const index = temp_data.findIndex((e) => e.fieldId == editCustomData.fieldId)
        temp_data[index] = formData
      }
      setModelView(false)
      setCustomFieldData(temp_data)
      clearData()
    }
    if (formData.fieldType == 4) {
      formData.listName = listName
      formData.listId = selectedOptionExisting?.value ?? null

      const errors = validate(formData)
      if (Object.keys(errors).length > 0) {
        setValidationErrors({ ...errors })
        return
      } else {
        setValidationErrors({})
      }
      if (editCustomData == '') {
        const id = generateUniqueId({
          length: 6,
          useLetters: false,
        })
        formData.fieldId = id
        temp_data.push(formData)
      } else {
        formData.fieldId = editCustomData.fieldId
        const index = temp_data.findIndex((e) => e.fieldId == editCustomData.fieldId)
        temp_data[index] = formData
      }
      setModelView(false)
      setCustomFieldData(temp_data)
      clearData()
    } else {
      delete formData.options

      const errors = validate(formData)
      if (Object.keys(errors).length > 0) {
        setValidationErrors({ ...errors })
        return
      } else {
        setValidationErrors({})
      }
      if (editCustomData == '') {
        const id = generateUniqueId({
          length: 6,
          useLetters: false,
        })
        formData.fieldId = id
        temp_data.push(formData)
      } else {
        formData.fieldId = editCustomData.fieldId
        const index = temp_data.findIndex(
          (e) => parseInt(e.fieldId) === parseInt(editCustomData.fieldId),
        )
        temp_data[index] = formData
      }
      setModelView(false)
      setCustomFieldData(temp_data)
      clearData()
    }
  }

  function clearData() {
    updateForm(initialValues)
    setEditCustomData('')
    setListName('')
  }

  function dropDownChangedHandlerExistingOption(event, type) {
    setSelectedOptionExisting(event)
    const index = selectedContentList.findIndex((e) => e.listId == event?.value)

    if (index >= 0 && type === 4) {
      updateForm((prevState) => {
        return {
          ...prevState,
          fieldId: event?.itemKey,
          fieldType: type,
          listId: event?.value,
        }
      })
      setSelectedList(selectedContentList[index])
    } else if (type === 6) {
      updateForm((prevState) => {
        return {
          ...prevState,
          fieldId: event?.value,
          fieldType: type,
          listId: event?.value,
        }
      })
    } else {
      setSelectedOptionExisting('')
      updateForm((prevState) => {
        return {
          ...prevState,
          fieldId: '',
          fieldType: '',
          listId: '',
        }
      })
    }
  }

  async function closeModel() {
    clearData()
    setModelView(false)
  }

  return (
    <>
      <div className="custom-create-template-modal" style={{ overlay: { zIndex: 10 } }}>
        <Modal
          title={editCustomData === '' ? 'Add Field' : 'Edit Field'}
          isModalOpen={view}
          data-testid="Custom Objective"
          closeModal={() => closeModel()}
          width="700px"
        >
          {customFieldListModalIsOpen && (
            <CustomFieldListModal
              isModalOpen={customFieldListModalIsOpen}
              closeModal={() => {
                setCustomFieldListModalIsOpen(false)
                setCreateNewList(false)
              }}
              listId={createNewList === true ? null : form?.listId}
              list={selectedList}
            />
          )}
          {resultIsLoading === true && <InPageLoader inComponent={true} />}
          {resultIsLoading === false && (
            <>
              <div className="form-field form-field action-field--kanban ">
                <label>Field Label</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={form.name}
                  onChange={fieldLabelInputHandler}
                  className="custom-input"
                />
                <span className="error error-add-commnet">{validationErrors.name}</span>
              </div>

              <div className={`form-field action-field action-field--kanban create-template-field`}>
                <label>Type</label>
                <SelectSingleField
                  placeholder="Select Option"
                  name="fieldType"
                  value={customFieldList.filter((item) => item.type == form?.fieldType)}
                  options={customFieldList}
                  onChange={fieldTypeInputHandler}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.type}
                  isDisabled={mode === 'Edit' && editCustomData !== ''}
                  width={'100%'}
                />
                <span className="error error-add-commnet">{validationErrors.fieldType}</span>
              </div>

              <div className="form-field form-field action-field--kanban create-template-checkbox">
                <label>Required</label>
                <input
                  type="checkBox"
                  id="required"
                  name="required"
                  checked={form.required}
                  onChange={fieldRequiredInputHandler}
                  className="custom-input"
                  disabled={form.defaultForActivityName === true || mode === 'Edit'}
                />
              </div>
              {form?.fieldType == 4 && (
                <>
                  <div className="row-kanban-inputs">
                    <div className="form-field action-field action-field--kanban ">
                      <label>List </label>

                      <div
                        className="form-field action-field action-field--kanban"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '1rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <SelectSingleField
                          placeholder="Select Option"
                          name="contentOption"
                          value={contentList.filter((item) => item.value == form?.listId)}
                          options={contentList}
                          onChange={(e) => {
                            dropDownChangedHandlerExistingOption(e, 4)
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          isClearable
                          isDisabled={mode === 'Edit' && editCustomData !== ''}
                          width={'100%'}
                        />
                        {form?.listId && (
                          <div
                            className="check-wrapper"
                            onClick={() => {
                              setCustomFieldListModalIsOpen(true)
                              const index = selectedContentList.findIndex(
                                (e) => e.listId == form.listId,
                              )
                              setSelectedList(selectedContentList[index])
                            }}
                          >
                            <span class="material-symbols-outlined icon-size">edit_note</span>
                          </div>
                        )}
                        <span> OR </span>
                        <button
                          className="add-btn-comment"
                          type="button"
                          onClick={() => {
                            setCreateNewList(true)
                            setCustomFieldListModalIsOpen(true)
                          }}
                        >
                          Create New List
                        </button>
                      </div>
                    </div>
                  </div>

                  <span className="error error-add-commnet">{validationErrors.listId}</span>
                </>
              )}
              {form?.fieldType == 6 && (
                <>
                  <div className="form-field action-field action-field--kanban ">
                    <label>Pre-built List </label>

                    <div
                      className="form-field action-field action-field--kanban"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '1rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <SelectSingleField
                        placeholder="Select Option"
                        name="contentOption"
                        value={dynamicLists.filter((item) => item.value == form?.listId)}
                        options={dynamicLists}
                        onChange={(e) => {
                          dropDownChangedHandlerExistingOption(e, 6)
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        isClearable
                        isDisabled={mode === 'Edit' && editCustomData !== ''}
                        width={'100%'}
                      />
                    </div>
                    <span className="error error-add-commnet">{validationErrors.listId}</span>
                  </div>
                </>
              )}
            </>
          )}
          <div className="modal-dual-btn" style={{ gap: '1rem' }}>
            <button
              className="fitted-button modal-save-button blue"
              type="button"
              onClick={() => closeModel()}
            >
              CANCEL
            </button>
            <button
              className="fitted-button modal-save-button blue"
              type="button"
              onClick={() => submitForm()}
            >
              ADD
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}
export default AddCustomField
