import React from 'react'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  cursor: pointer;
`

const Label = styled.span`
  margin-left: 15px;
`

const SelectionModal = ({ closeModal, setTypeSelection }) => {
  const { t } = useTranslation(['Common', 'MeetingHub'])
  const [selected, setSelected] = React.useState(0)

  const handleSelect = (index) => {
    setSelected(index)
  }

  const items = [
    t('MeetingHub:meetingTopics.objective'),
    t('MeetingHub:meetingTopics.topic'),
    t('MeetingHub:meetingTopics.action'),
    t('MeetingHub:meetingTopics.comment'),
  ]

  return (
    <Modal title={t('Common:brainstorming.selectionModalTitle')} closeModal={closeModal}>
      <Container>
        {items.map((item, index) => (
          <ListItem key={index} onClick={() => handleSelect(index)}>
            <input type="radio" name="selection" checked={selected === index} onChange={() => {}} />
            <Label>{item}</Label>
          </ListItem>
        ))}
      </Container>
      <div className="modal-dual-btn" style={{ gap: '1rem' }}>
        <button
          className="fitted-button modal-save-button blue"
          onClick={() => {
            closeModal()
          }}
        >
          CANCEL
        </button>
        <button
          className="fitted-button modal-save-button blue"
          onClick={() => {
            setTypeSelection(selected)
            closeModal()
          }}
        >
          SAVE
        </button>
      </div>
    </Modal>
  )
}

export default SelectionModal
