const colors = {
  orange: '#ed522e',
  grey: '#c8c8c8',
  red: '#e92020',
  blue: '#0926d5',
  white: '#fff',
  black: '#000',
  green: '#70AD47',
}

const KanbanCardHexColors = ['FFFFFF', 'D9EFD3', 'CBE3F9', 'F6E9C1', 'EDB8C4', 'DEBBEA', 'DEDEDB']

const objectiveColors = {
  1: '#E9A332', // Golden Amber
  2: '#A65721', // Brown Ginger
  3: '#B1001A', // Burgundy Red
  4: '#A8516E', // Rose Pink
  5: '#3B1147', // Dark Purple
  6: '#2661B8', // Lapis Lazuli Blue
  7: '#0C6B54', // Emerald Green
  8: '#565D6B', // Slate Grey
  9: '#0A0A0A', // Rich Black
}

// Helper function to convert hex to rgba
const hexToRGBA = (hex, opacity) => {
  // Remove the hash if present
  hex = hex?.replace('#', '')

  // Parse the hex values
  const r = parseInt(hex?.substring(0, 2), 16)
  const g = parseInt(hex?.substring(2, 4), 16)
  const b = parseInt(hex?.substring(4, 6), 16)

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const objectiveColorsArray = [
  '#E9A332',
  '#A65721',
  '#B1001A',
  '#A8516E',
  '#3B1147',
  '#2661B8',
  '#0C6B54',
  '#565D6B',
  '#0A0A0A',
]

function enumToObjColor(enumColor) {
  return objectiveColors[enumColor]
}
function levelOpacity(level) {
  let opacity = 1
  switch (level) {
    case 1:
      opacity = 1
      break
    case 2:
      opacity = 0.7
      break
    case 3:
      opacity = 0.5
      break
    default:
      opacity = 1
  }
  return opacity
}

export {
  colors,
  KanbanCardHexColors,
  enumToObjColor,
  objectiveColorsArray,
  objectiveColors,
  hexToRGBA,
  levelOpacity,
}
