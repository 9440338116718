import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { getCompanyConfigSettings } from './utils/auth'

const languageSettings = getCompanyConfigSettings('languageSettings')
const companyId = localStorage.getItem('tfci')
const companyTranslationList = ['44']

i18next
  .use(
    resourcesToBackend(async (language, namespace) => {
      // Load default translations
      const defaultTranslations = await import(`./locales/${language}/${namespace}.json`)

      // Try to load company-specific translations if companyId exists
      let companyTranslations = {}
      if (companyTranslationList.includes(companyId)) {
        try {
          companyTranslations = await import(
            `./locales/${language}/companies/${companyId}/${namespace}.json`
          )
        } catch (e) {
          // Company-specific translations file doesn't exist, that's ok
        }
      }

      // Merge default translations with company-specific ones
      // Deep merge to handle nested objects while preserving defaults
      const mergeDeep = (target, source) => {
        if (!source) return target
        const output = { ...target }

        Object.keys(source).forEach((key) => {
          if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
            // If value is an object, recursively merge
            output[key] = mergeDeep(target[key] || {}, source[key])
          } else {
            // For primitive values or arrays, override directly
            output[key] = source[key]
          }
        })

        return output
      }

      return mergeDeep(defaultTranslations.default, companyTranslations.default || {})
    }),
  )
  .init({
    lng: languageSettings !== false ? languageSettings : 'en',
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
  })

export default i18next
