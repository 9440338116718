import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import { shortenName } from '../../../utils/general'
import { useTranslation } from 'react-i18next'

function IngestionNode({ data, isConnectable }) {
  const { t } = useTranslation(['Common'])
  return (
    <div className="nodrag text-updater-node">
      {data.main === 1 || data.main === 2 || data.main === 3 ? (
        <>
          {' '}
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
      <div
        className={`custom-box-node box-level-${data.main} ${data.isSearchedObjective ? 'searched-border' : ''}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ul
          className="node-text-list"
          style={{
            height: '60%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <li style={{ flex: '1', overflow: 'hidden' }}>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              <div
                className="owner"
                style={{
                  color: `${data?.invalid ? 'rgba(237, 82, 46, 1)' : ''}`,
                }}
              >
                {data?.invalid ? t('Common:aiIngestion.missingOwner') : shortenName(data.owner)}
              </div>
            </div>
          </li>
          <li className="single-node" style={{ flex: '1', overflow: 'hidden' }}>
            <p className="goal-desc" style={{ maxHeight: '100%', overflowY: 'auto' }}>
              {data.goal}
            </p>
          </li>
        </ul>
        <div className="icons-list-node-box" style={{ height: '40%' }}>
          <div
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              gap: '0.313rem',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0.1rem .313rem 0.1rem',
              padding: '0',
            }}
          >
            {data.main === 1 && (
              <div class="fitted-button blue">
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowAddModal(data, data.main)
                  }}
                >
                  add
                </span>
              </div>
            )}
            {
              <div class="fitted-button blue">
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowEditModal(data, data.main)
                  }}
                >
                  edit
                </span>
              </div>
            }

            {
              <div class="fitted-button blue">
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.DeleteCard(data, data.main)
                  }}
                >
                  delete
                </span>
              </div>
            }
          </div>
        </div>
      </div>

      {data.main != 3 ? (
        <>
          <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default IngestionNode
