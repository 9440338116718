import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  floatColumn,
  isoDateColumn,
  keyColumn,
} from 'react-datasheet-grid'
import _uniqueId from 'lodash/uniqueId'
import 'react-datasheet-grid/dist/style.css'
import { useLocation } from 'react-router-dom'
import { CreateTaskButton } from '../kanban/style'
import trashicon from '../../assets/trash_icon.svg'
import chroma from 'chroma-js'
import './spreadSheet.scss'
import { check_spreadhsheet_updates } from '../../api/services/spreadsheet.srvices'
import { bool } from 'yup'

const Spreadsheet = ({ mainResult, saveData, showReload = false }) => {
  const { state } = useLocation()
  const [result, setResult] = useState(mainResult)

  const [loadingError, setLoadingError] = useState(false)
  const [columns, setColumns] = useState([])
  const [actdata, setActData] = useState([])

  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const [errorList, setErrorList] = useState({})
  const [deletedRow, setDeletedRow] = useState([])

  const [columnMetadata, setColumnMetadata] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [defaultRowValues, setDefaultRowValues] = useState({ progress: 5 })

  const updatedRowIds = React.useMemo(() => new Set(), [])
  const createdRowIds = React.useMemo(() => new Set(), [])
  const deletedRowIds = React.useMemo(() => new Set(), [])

  const [idMapping, setIdMapping] = useState({})
  const counter = useRef(0)
  const genId = () => counter.current++

  useEffect(() => {
    setResult(mainResult)
  }, [mainResult, mainResult?.id])

  useEffect(() => {
    getObjectiveTask()
  }, [result])

  async function getObjectiveTask() {
    if (result) {
      setErrorList(result.errors)

      const mainColumn = []
      const columns = result.columns
      const columnMetadata = result.columnMetadata
      let localDefaultRowValues = defaultRowValues
      if (
        columns === null ||
        columns === undefined ||
        columnMetadata === null ||
        columnMetadata === undefined
      ) {
        setLoadingError(true)
        return
      }
      setColumnMetadata(result.columnMetadata)

      for (let i = 0; columns.length > i; i++) {
        for (let j = 0; Object.entries(columnMetadata).length > j; j++) {
          if (
            Object.entries(columnMetadata)[j][0] === null ||
            Object.entries(columnMetadata)[j][0] === undefined ||
            columns[i].title === null ||
            columns[i].title === undefined
          ) {
            setLoadingError(true)
            return
          }

          if (columns[i].title === Object.entries(columnMetadata)[j][0]) {
            let typeValue = Object.entries(columnMetadata)[j][1]['type']
            let keyColumnType = textColumn
            let required = Object.entries(columnMetadata)[j][1]['required']
            let title = columns[i].title.toUpperCase()
            let has_color = Object.entries(columnMetadata)[j][1]['has_color']

            if (
              typeValue === null ||
              typeValue === undefined ||
              required === null ||
              required === undefined ||
              title === null ||
              title === undefined
            ) {
              setLoadingError(true)
              return
            }
            if (typeValue === 1) {
              keyColumnType = textColumn
            } else if (typeValue === 2) {
              keyColumnType = floatColumn
            } else if (typeValue === 3) {
              keyColumnType = isoDateColumn
            } else if (typeValue === 4) {
            } else if (typeValue === 5) {
              keyColumnType = checkboxColumn
              localDefaultRowValues[title] = false
            }

            if (required) {
              title = title + ' *'
            }
            let column = {
              ...keyColumn(columns[i].title, keyColumnType),
              title: title,
              minWidth: 150,
              // minHeight: 700
            }

            if (typeValue === 4 || typeValue === 6) {
              let multiselect = Object.entries(columnMetadata)[j][1].multiselect
              let options = Object.entries(columnMetadata)[j][1].options

              if (
                multiselect === undefined ||
                multiselect === null ||
                options === undefined ||
                options === null
              ) {
                setLoadingError(true)
                return
              }

              column = {
                ...keyColumn(
                  columns[i].title,
                  selectColumn({
                    title: columns[i].title,
                    choices: options,
                    disabled: false,
                    isMulti: multiselect,
                    has_color,
                  }),
                ),
                // ...selectColumn({ title: columns[i].title, choices: options, disabled: false, isMulti: multiselect, has_color }),
                title: title,
                minWidth: multiselect ? 450 : 150,
              }
            }
            mainColumn.push(column)
          }
        }
      }

      setColumns(mainColumn)

      let mapping = {}
      for (let i = 0; i < result.data.length; i++) {
        let id = genId()
        result.data[i].id = id
        let mId = result.data[i].mId__
        mapping[mId] = id
      }
      setIdMapping(mapping)
      setActData(result.data)
      setDefaultRowValues(localDefaultRowValues)
    }
  }

  const SelectComponent = React.memo(
    ({ active, rowData, setRowData, focus, stopEditing, columnData }) => {
      const ref = React.useRef(null)

      React.useLayoutEffect(() => {
        if (focus) {
          ref.current?.focus()
        } else {
          ref.current?.blur()
        }
      }, [focus])

      const getValue = () => {
        if (!rowData) return

        if (columnData.isMulti) {
          const rowValue = rowData || []

          if (rowValue?.[columnData.title]) {
            const rowData = rowValue?.[columnData.title]
            return columnData.choices.filter(({ value }) => rowData.includes(value))
          } else {
            return columnData.choices.filter(({ value }) => rowValue.includes(value))
          }
        } else {
          const rowValue = rowData || null
          return columnData.choices.find(({ value }) => value === rowValue)
        }
      }

      const handleChange = (selectedValues) => {
        const selected = selectedValues || []
        if (columnData.isMulti) {
          // const value = selected.map((item) => item.value);
          // setRowData({ ...rowData, [columnData.title]: value });
          const value = selected.map((item) => item.value)
          setRowData(value)
        } else {
          // setRowData({ ...rowData, [columnData.title]: selected.value });
          let d = { ...rowData }
          d[columnData.title] = selected.value
          setRowData(d)
        }
        setTimeout(stopEditing, 0)
      }

      const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          const color = chroma(data.color)
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
                ? data.color
                : isFocused
                  ? color.alpha(0.1).css()
                  : undefined,
            color: isDisabled
              ? '#ccc'
              : isSelected
                ? chroma.contrast(color, 'white') > 2
                  ? 'white'
                  : 'black'
                : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? data.color
                  : color.alpha(0.3).css()
                : undefined,
            },
          }
        },
        multiValue: (styles, { data }) => {
          const color = chroma(data.color)
          return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
          }
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          ':hover': {
            backgroundColor: data.color,
            color: 'white',
          },
        }),
      }

      let style = {
        container: (provided) => ({
          ...provided,
          flex: 1,
          alignSelf: 'stretch',
          pointerEvents: focus ? undefined : 'none',
        }),
        control: (provided) => ({
          ...provided,
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          background: 'none',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
        placeholder: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
      }

      if (columnData.has_color) {
        style = {
          container: (provided) => ({
            ...provided,
            flex: 1,
            alignSelf: 'stretch',
            pointerEvents: focus ? undefined : 'none',
          }),
          ...colourStyles,
          control: (provided) => ({
            ...provided,
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            background: 'none',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          placeholder: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
        }
      }

      return (
        <Select
          ref={ref}
          styles={style}
          isDisabled={columnData.disabled}
          value={getValue()}
          menuPortalTarget={document.body}
          menuIsOpen={focus}
          isMulti={columnData.isMulti}
          onChange={handleChange}
          onMenuClose={() => stopEditing({ nextRow: false })}
          options={columnData.choices}
          // style={columnData.isMulti ? colourStyles : null}
        />
      )
    },
  )

  const selectColumn = (options) => ({
    component: SelectComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) =>
      options.choices.find((choice) => choice.value === rowData)?.label ?? null,
    pasteValue: ({ value }) =>
      options.choices.find((choice) => choice.label === value)?.value ?? null,
  })

  const checkDataValidity = (refreshErrorList = false) => {
    let errorRecord = {}
    let errorData = errorList

    if (refreshErrorList) errorData = {}

    for (let i = 0; i < Object.entries(columnMetadata).length; i++) {
      let required = Object.entries(columnMetadata)[i][1].required
      let columnName = Object.entries(columnMetadata)[i][0]

      for (let j = 0; j < actdata.length; j++) {
        if (deletedRowIds.has(actdata[j].id)) {
          continue
        }

        let value = actdata[j][columnName]
        let seqId = actdata[j].seqId ?? j

        if (required === true) {
          if (value === null || value === undefined || value === '') {
            errorData[`${seqId},${columnName.toLowerCase()}`] = true
            if (errorRecord[j + 1]) {
              let existingArray = errorRecord[j + 1]
              existingArray.push(String(columnName))
            } else {
              // errorData[`${seqId},${columnName.toLowerCase()}`] = true;

              let newArray = [columnName]
              errorRecord[j + 1] = newArray
            }
          }
        }
      }
    }

    setErrorList(errorData)

    if (Object.keys(errorRecord).length > 0) {
      let allErrors = 'Please fill the following required fields: '

      for (let i = 0; i < Object.keys(errorRecord).length; i++) {
        let key = Object.keys(errorRecord)[i]
        let resultPerRow = ''
        for (let l = 0; l < errorRecord[key].length; l++) {
          if (l === 0) {
            resultPerRow += errorRecord[key][l]
          } else if (l == errorRecord[key].length - 1) {
            resultPerRow += ' & ' + errorRecord[key][l]
          } else {
            resultPerRow += ', ' + errorRecord[key][l]
          }
        }
        resultPerRow = resultPerRow.replace(', &', ' &')
        resultPerRow += ` field(s) in Row ${key}`

        if (i === 0) {
          allErrors += resultPerRow
        } else {
          allErrors += '; ' + resultPerRow
        }
        allErrors = allErrors.replace(', &', ' &')
      }

      setErrorMessage(allErrors)
    } else {
      setErrorMessage(null)
      let responseUnsavedChanges = saveData(
        createdRowIds,
        updatedRowIds,
        deletedRowIds,
        idMapping,
        actdata,
        columnMetadata,
      )
      // showReload(false)
      setUnsavedChanges(responseUnsavedChanges)
    }
  }

  const cellRenderer = (cell) => {
    const className = cell.isValid ? 'valid-cell' : 'invalid-cell'
    return <div className={className}>{cell.value}</div>
  }

  const validate = (rowData, rowIndex, columnId) => {
    let rIndex = rowData.seqId ?? rowIndex
    if (columnId) {
      const hasError = errorList[`${rIndex},${columnId.toLowerCase()}`]
      return hasError ? 'spread_sheet_cell_warning' : ''
    }
  }

  const updateErrorList = (newValue, operations) => {}

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          margin: '1rem 30px 2rem 0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '25%',
            margin: '1rem 30px 2rem 0.5rem',
          }}
        ></div>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '2rem' }}>
            {showReload && (
              <>
                <div style={{ color: 'red' }}>Someone else saved a copy !</div>
                <div style={{ marginRight: '0.5rem' }}>
                  <CreateTaskButton onClick={() => checkDataValidity()}>
                    Save and Reload Data
                  </CreateTaskButton>
                </div>
              </>
            )}
            {!showReload && (
              <>
                {unsavedChanges === true && (
                  <span className="validation-error">You have unsaved changes!</span>
                )}

                <div style={{ marginRight: '0.5rem' }}>
                  <div className="spreadsheet-action-wrapper">
                    <button
                      onClick={() => checkDataValidity(true)}
                      className="move-btn-table import_export_btn btn_cmn"
                    >
                      Save Data
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {loadingError && <span> There was an error loading the data. A</span>}
      {actdata && !loadingError && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '30px' }}>
            {errorMessage && <div className="validation-error">{errorMessage}</div>}
            {columns.length > 0 && (
              <DataSheetGrid
                cellClassName={({ rowData, rowIndex, columnId }) => {
                  return validate(rowData, rowIndex, columnId)
                }}
                stickyRightColumn={{
                  component: ({ deleteRow, rowData }) => (
                    <span
                      className="trash_btn"
                      onClick={() => {
                        if (deletedRowIds.has(rowData.id)) {
                          deletedRowIds.delete(rowData.id)
                          setActData((actdata) => actdata)
                        } else {
                          deleteRow()
                        }
                      }}
                    >
                      <img src={trashicon} />
                    </span>
                  ),
                }}
                rowClassName={({ rowData }) => {
                  if (deletedRowIds.has(rowData.id)) {
                    return 'spread_sheet_cell_warning'
                  }
                }}
                value={actdata}
                columns={columns}
                cellRenderer={cellRenderer}
                height={(window.innerHeight * 0.8 * 85) / 100}
                createRow={() => Object.assign({ id: genId() }, defaultRowValues)}
                duplicateRow={({ rowData }) => ({
                  ...rowData,
                  id: genId(),
                })}
                rowHeight={70}
                //disableContextMenu

                onChange={(newValue, operations) => {
                  setUnsavedChanges(true)
                  for (const operation of operations) {
                    if (operation.type === 'CREATE') {
                      newValue
                        .slice(operation.fromRowIndex, operation.toRowIndex)
                        .forEach(({ id }) => createdRowIds.add(id))
                    }

                    if (operation.type === 'UPDATE') {
                      newValue
                        .slice(operation.fromRowIndex, operation.toRowIndex)
                        .forEach(({ id }) => {
                          if (!createdRowIds.has(id)) {
                            updatedRowIds.add(id)
                          }
                        })

                      updateErrorList(newValue, operations)
                    }
                    if (operation.type === 'DELETE') {
                      let keptRows = 0

                      actdata
                        .slice(operation.fromRowIndex, operation.toRowIndex)
                        .forEach(({ id }, i) => {
                          updatedRowIds.delete(id)

                          if (createdRowIds.has(id)) {
                            createdRowIds.delete(id)
                          } else {
                            deletedRowIds.add(id)
                            newValue.splice(
                              operation.fromRowIndex + keptRows++,
                              0,
                              actdata[operation.fromRowIndex + i],
                            )
                          }
                        })
                    }
                  }

                  setActData(newValue)
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Spreadsheet
