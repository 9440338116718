import { useMicVAD } from '@ricky0123/vad-react'

const BrainstormVadCompomnent = ({
  stream = undefined,
  onSpeechStartcallBack = () => {},
  onSpeechEndcallBack = () => {},
  isPaused = false,
  setHasStarted = () => {},
}) => {
  // console.log('isPaused: ', isPaused)

  const brainstormVad = useMicVAD({
    stream: stream,
    ortConfig: (ort) => {
      ort.env.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/'
    },
    startOnLoad: false,
    onSpeechStart: (audio) => {
      console.log('**** Speech start detected ***')
      setHasStarted(true)
      onSpeechStartcallBack()
    },
    onSpeechEnd: (audio) => {
      console.log('**** Speech end detected ***')
      if (!isPaused) {
        onSpeechEndcallBack()
      } else {
        console.log('isPaused: ', isPaused)
      }
    },
    redemptionFrames: 10,
    positiveSpeechThreshold: 0.85,
    negativeSpeechThreshold: 0.6,
  })
  // vadRef.current = brainstormVad
  // setVad(brainstormVad)
  // setIsVadStarted(true)

  if (brainstormVad && !brainstormVad.listening) {
    console.log('ccccccc')
    brainstormVad.start()
  }

  return <></>
}

export default BrainstormVadCompomnent
