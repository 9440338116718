import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import { GET_SUMMARIZED_ACTION_PLANS, API_ERROR_MESSAGE } from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getSummarizedActionPlans(postData) {
  try {
    const data = await postHttpRequest(`${GET_SUMMARIZED_ACTION_PLANS}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}
