import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import { useTranslation } from 'react-i18next'
import Toast from '../../../common/toast'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import KpiHierarchyGrid from './kpiHierarchyTable'

const SelectKPIsModal = ({ kpiDashboardId, kpiDashboardName, closeModal }) => {
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [kpiList, setKpiList] = useState(null) // stands for obj kpi data structure
  const [originalKpiList, setOriginalKpiList] = useState(null) // stands for obj kpi data structure
  const [expandedItems, setExpandedItems] = useState(new Set())
  const [selectedKpis, setSelectedKpis] = useState([])
  const [currentSelectedKpis, setCurrentSelectedKpis] = useState([])
  const [shouldShowCompleted, setShouldShowCompleted] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredKpiList, setFilteredKpiList] = useState([])
  console.log('selectedKpis', selectedKpis)
  console.log('currentSelectedKpis', currentSelectedKpis)

  // Get all objective IDs that can be expanded (have children or KPIs)
  const getExpandableIds = (objList) => {
    const ids = new Set()

    const processObj = (obj) => {
      if (!obj) return

      // Add ID if the objective has children or KPIs
      if (obj.objective?.id && (obj.objList?.length > 0 || obj.kpiList?.length > 0)) {
        ids.add(obj.objective.id)
      }

      // Process child objectives
      if (obj.objList?.length > 0) {
        obj.objList.forEach(processObj)
      }
    }

    objList?.forEach(processObj)
    return ids
  }

  async function getCurrentKpis() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(`/get_kpi_dashboard_kpis?kpiDashboardId=${kpiDashboardId}`)
      if (data?.kpis) {
        let kpiArray = data.kpis.map((kpi) => kpi.kpiId)

        setCurrentSelectedKpis(kpiArray)
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (searchTerm.trim() !== '') {
      // Create a deep copy of the original list
      const copyKpiList = JSON.parse(JSON.stringify(kpiList))

      // Combine all three filtering conditions
      const filteredResults = filterKpiListByAllCriteria(copyKpiList, searchTerm)
      setFilteredKpiList(filteredResults)
    } else {
      setFilteredKpiList(kpiList)
    }
  }, [searchTerm])

  const filterKpiListByAllCriteria = (objList, searchTerm) => {
    if (!objList) return []

    const filteredList = objList.flatMap((obj) => {
      // Check if current objective matches
      const matchesObjective = obj.objective.statement
        .toLowerCase()
        .includes(searchTerm.toLowerCase())

      // Check if any KPIs match name or owner
      const matchingKpis =
        obj.kpiList?.filter(
          (kpi) =>
            kpi.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            kpi.ownerName.toLowerCase().includes(searchTerm.toLowerCase()),
        ) || []

      // Recursively check children
      const filteredChildren = filterKpiListByAllCriteria(obj.objList, searchTerm)

      if (matchesObjective) {
        // If objective matches, return everything under it
        return [
          {
            ...obj,
            objList: obj.objList, // Keep all children
          },
        ]
      } else if (matchingKpis.length > 0 || filteredChildren.length > 0) {
        // If KPIs match or children have matches, return filtered version
        return [
          {
            ...obj,
            kpiList: matchingKpis,
            objList: filteredChildren,
          },
        ]
      }

      return [] // No matches in this branch
    })

    return filteredList
  }

  React.useEffect(() => {
    if (currentSelectedKpis?.length > 0) {
      setSelectedKpis(JSON.parse(JSON.stringify(currentSelectedKpis)))
    }
  }, [currentSelectedKpis])

  async function loadKpiList() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(`/get_all_kpis_grouped_by_obj`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (data?.objKpiList) {
        setKpiList(data.objKpiList)
        setFilteredKpiList(data.objKpiList)
        setOriginalKpiList(data.objKpiList)
        // Initialize all expandable items as expanded
        setExpandedItems(getExpandableIds(data.objKpiList))
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (originalKpiList && expandedItems) {
      if (shouldShowCompleted === true) {
        setKpiList(JSON.parse(JSON.stringify(originalKpiList)))
        setFilteredKpiList(JSON.parse(JSON.stringify(originalKpiList)))
        setExpandedItems(getExpandableIds(originalKpiList))
      } else {
        let newKpiList = JSON.parse(JSON.stringify(originalKpiList))
        // Process each top-level object
        newKpiList.forEach((obj) => {
          filterCompletedKpis(obj)
        })

        const newExpandedItems = getFilteredExpandableIds(newKpiList)

        setKpiList(newKpiList)
        setFilteredKpiList(newKpiList)
        setExpandedItems(newExpandedItems)
      }
    }
  }, [shouldShowCompleted])

  React.useEffect(() => {
    loadKpiList()
    getCurrentKpis()
  }, [])

  const filterCompletedKpis = (obj) => {
    // Filter KPIs if the object has a kpiList
    if (obj.kpiList) {
      obj.kpiList = obj.kpiList.filter((kpi) => !kpi.isCompleted)
    }

    // Recursively process nested objList if it exists
    if (obj.objList) {
      obj.objList.forEach((nestedObj) => {
        filterCompletedKpis(nestedObj)
      })
    }
  }

  const getFilteredExpandableIds = (objList) => {
    const ids = new Set()

    const processObj = (obj) => {
      if (!obj) return

      // Check if obj has any non-completed KPIs or has children with expandable content
      const hasNonCompletedKpis = obj.kpiList?.some((kpi) => !kpi.isCompleted)
      const hasChildren = obj.objList?.length > 0

      // Only add ID if the objective has non-completed KPIs or expandable children
      if (obj.objective?.id && (hasNonCompletedKpis || hasChildren)) {
        // Check if any child objectives have expandable content
        let hasExpandableChildren = false
        if (hasChildren) {
          hasExpandableChildren = obj.objList.some((childObj) => {
            const childHasNonCompletedKpis = childObj.kpiList?.some((kpi) => !kpi.isCompleted)
            const childHasChildren = childObj.objList?.length > 0
            return childHasNonCompletedKpis || childHasChildren
          })
        }

        // Only add the ID if there are non-completed KPIs or expandable children
        if (hasNonCompletedKpis || hasExpandableChildren) {
          ids.add(obj.objective.id)
        }
      }

      // Recursively process child objectives
      if (hasChildren) {
        obj.objList.forEach(processObj)
      }
    }

    objList?.forEach(processObj)
    return ids
  }

  async function saveKPIsForDashboard() {
    setIsLoading(true)
    try {
      const data = await postHttpRequest(`/edit_kpi_dashboard_kpi_list`, {
        kpiDashboardId: kpiDashboardId,
        kpiIdList: selectedKpis,
        previousKpiIdList: currentSelectedKpis,
      })
      if (data?.success) {
        Toast.fire({
          icon: 'success',
          title: 'KPIs selection saved successfully',
        })
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
        //title: API_ERROR_MESSAGE,
      })
    }
    setIsLoading(false)
  }

  const toggleExpand = (id) => {
    setExpandedItems((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(id)) {
        newSet.delete(id)
      } else {
        newSet.add(id)
      }
      return newSet
    })
  }

  const toggleKpiSelection = (kpiId) => {
    setSelectedKpis((prev) => {
      if (prev.includes(kpiId)) {
        return prev.filter((id) => id !== kpiId)
      } else {
        return [...prev, kpiId]
      }
    })
  }

  const renderRows = (obj, depth = 0) => {
    if (!obj) return []

    const isExpanded = expandedItems.has(obj.objective.id)
    const hasChildren = obj.objList?.length > 0
    const hasKpis = obj.kpiList?.length > 0
    const rows = []

    // Always render the objective row
    rows.push(
      <tr
        key={obj.objective.id + '-' + depth}
        className={`objective-row level-${obj.objective.level}`}
      >
        <td
          className={`objective-cell depth-${depth}`}
          style={{ cursor: hasChildren || hasKpis ? 'pointer' : 'default' }}
          onClick={() => (hasChildren || hasKpis ? toggleExpand(obj.objective.id) : null)}
        >
          <span className="chevron-icon" style={{ width: '24px' }}>
            {hasChildren || hasKpis ? (
              <span className="material-symbols-outlined">
                {isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}
              </span>
            ) : (
              <span className="material-symbols-outlined" style={{ opacity: 0 }}>
                arrow_drop_up
              </span>
            )}
          </span>
          <div className="objective-content">
            <div className="objective-statement">{obj.objective.statement}</div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>,
    )

    // Only add child content if expanded
    if (isExpanded) {
      // Add KPIs if any
      if (hasKpis) {
        obj.kpiList.forEach((kpi) => {
          rows.push(
            <tr key={kpi.kpiId} className="kpi-row">
              <td className={`kpi-cell depth-${depth + 1}`}>
                <span className="chevron-icon" style={{ width: '24px', opacity: 0 }}>
                  <span className="material-symbols-outlined">arrow_drop_up</span>
                </span>
                <div className="kpi-content">
                  <div className="kpi-name">{kpi.name}</div>
                </div>
              </td>
              <td className="kpi-target">
                {kpi.unit && (
                  <>
                    {kpi.kpiType === 3 ? '' : kpi.target.toLocaleString()} {kpi.unit}{' '}
                  </>
                )}
                {t('Common:kpi.by')}
                <br />
                <span style={{ wordBreak: 'keep-all' }}>{kpi.targetDate}</span>
              </td>
              <td className="kpi-date">{kpi.ownerName}</td>
              <td className="checkbox-cell">
                <input
                  type="checkbox"
                  checked={selectedKpis.includes(kpi.kpiId)}
                  onChange={() => toggleKpiSelection(kpi.kpiId)}
                />
              </td>
            </tr>,
          )
        })
      }

      // Add child objectives if any
      if (hasChildren) {
        obj.objList.forEach((childObj) => {
          rows.push(...renderRows(childObj, depth + 1))
        })
      }
    }

    return rows
  }

  const getAllKpiIds = (objList) => {
    let kpiIds = []
    objList.forEach((obj) => {
      if (obj.kpiList) {
        kpiIds.push(...obj.kpiList.map((kpi) => kpi.kpiId))
      }
      if (obj.objList) {
        kpiIds.push(...getAllKpiIds(obj.objList))
      }
    })
    return kpiIds
  }

  const selectAllKpis = (checked) => {
    // Get KPI IDs from the filtered list (or full list if no search term)
    const listToUse = searchTerm.trim() !== '' ? filteredKpiList : kpiList
    const kpiIds = getAllKpiIds(listToUse)

    if (checked) {
      setSelectedKpis((prev) => {
        const newSelection = new Set([...prev, ...kpiIds])
        return Array.from(newSelection)
      })
    } else {
      setSelectedKpis((prev) => prev.filter((id) => !kpiIds.includes(id)))
    }
  }

  return (
    <Modal
      style={{ zIndex: 103 }}
      modalContainerStyle={{ overflowY: 'hidden' }}
      title={'KPIs ' + t('kpi.for') + ' ' + kpiDashboardName}
      closeModal={closeModal}
      width={'80rem'}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      {isLoading && <InPageLoader inComponent={true} isLoading={isLoading} />}
      {!isLoading && (
        <div className="select-kpis-container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '0.5rem',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '0.5rem',
              }}
              onClick={() => {
                setShouldShowCompleted((prev) => !prev)
              }}
            >
              {!shouldShowCompleted && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_off
                </span>
              )}

              {shouldShowCompleted && (
                <span
                  style={{ fontSize: '35px', color: '#4472c4' }}
                  class="material-symbols-outlined"
                >
                  toggle_on
                </span>
              )}
              <span>{t('kpi.includeCompleted')}</span>
            </div>

            <div className="action-buttons">
              <div
                className="fitted-button blue tooltip"
                onClick={() => {
                  saveKPIsForDashboard()
                  closeModal()
                }}
              >
                {t('commonButtons.save')}
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginBottom: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                padding: '0.5rem',
                background: '#fff',
                border: '1px solid #ddd',
                borderRadius: '4px',
                width: '200px',
              }}
            >
              <span style={{ color: '#666' }} className="material-symbols-outlined icon-size">
                search
              </span>
              <input
                type="text"
                placeholder={t('searchBar.search')}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                  fontSize: '14px',
                }}
              />
            </div>
            <span style={{ fontSize: '14px', marginLeft: 'auto' }}>{t('kpi.selectAll')}</span>
            <input
              type="checkbox"
              onChange={(e) => selectAllKpis(e.target.checked)}
              checked={
                (searchTerm.trim() !== '' ? filteredKpiList : kpiList)?.length > 0 &&
                getAllKpiIds(searchTerm.trim() !== '' ? filteredKpiList : kpiList).every((id) =>
                  selectedKpis.includes(id),
                )
              }
              style={{
                cursor: 'pointer',
                width: '16px',
                height: '16px',
              }}
            />
          </div>

          <KpiHierarchyGrid
            kpiList={filteredKpiList}
            selectedKpis={selectedKpis}
            onKpiSelectionChange={setSelectedKpis}
            initialExpanded={true}
          />

          {(kpiList?.length <= 0 || !kpiList) && kpiList !== null && (
            <div className="no-kpis-message">{t('kpi.noKPIsMessage')}</div>
          )}
        </div>
      )}
    </Modal>
  )
}
export default SelectKPIsModal
