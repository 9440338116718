// Error Message
export const API_ERROR_MESSAGE = 'Something went wrong try again later !'

//Success Saving Data Spreadsheet
export const API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE = 'Your data was successfully saved !'

// Activity Task
export const CREATE_TASK = '/kanban_board_create_update_activity'
export const GET_SINGLE_ACTIVITY = '/kanban_board_single_activity_data' //PASS ID in queryParam
export const DELETE_ACTIVITY = '/kanban_board_delete_activity'

// SUB Activity Task
export const CREATE_SUB_ACTIVITY = '/kanban_board_create_update_sub_activities'
export const GET_SINGLE_SUB_ACTIVITY = '/kanban_board_sub_activity_data' //PASS ID in queryParam
export const DELETE_SUB_ACTIVITY = '/kanban_board_delete_sub_activity' //PASS ID in post
export const EDIT_SUB_ACTIVITY = '/kanban_board_edit_sub_activities' //PASS ID and startDate and/or dueDate
export const GET_SUB_ACTIVITIES = '/activity_sub_activities' //PASS ID in queryParam

// GET OBJECTIVE ACTIVITY
export const GET_LIST_OBJECTIVES = '/team_leader_objectives'
export const GET_KANBAN_BOARD_OBJECTIVE_DATA = '/kanban_board_objective_data'
export const GET_KANBAN_OBJECTIVES_LABEL_MODE = '/kanban_objectives_label_mode'
export const GET_OBJECTIVES_ACTIVITY = '/activities_for_objectives' //PASS ID in queryParam
export const TASK_COLUMN_UPDATE = '/TASK_COLUMN_UPDATE'
export const GET_TEAM_MEMBERS_FOR_ACTIVITY = '/kanban_board_list_team_members_for_activity'
export const GET_KANBAN_BOARD_ACTIVITIES = '/kanban_board_activity_data'
export const POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS = '/kanban_board_change_activity_status'
export const POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY = '/kanban_mark_activity_as_priority'

//OBJECTIVE MAP
export const OBJECTIVE_CANVAS_DATA_V2 = '/canvas_data_v3'
export const ACTIVITIES_PER_OBJECTIVE = '/activities_per_objective'

//TL WIZARD STATE
export const POST_TL_WIZARD_STATE = '/save_tl_wizard_state'
export const GET_TL_WIZARD_STATE = '/get_tl_wizard_state'

//MEETING_NOTES
export const POST_MEETING_NOTES = '/save_meeting_notes'
export const GET_MEETING_NOTES = '/get_meeting_notes'

export const UPDATE_WIZARD_ADD_ACTIVITY = '/update_wizard_add_activity'
export const UPDATE_WIZARD_DELETE_ACTIVITY = '/update_wizard_delete_activity'
//User / Employee Route
export const GET_TEAM_LIST = '/kanban_board_list_team_members_for_activity' // on OIbjective Id as a Param

export const GET_TEAM_ALLOCATION_DATA = '/employees_alloc_t'
export const GET_EMPLOYEES_BY_LEVEL = '/get_employees_by_level'
export const GET_TEAM_FLOW = '/manage_team_config/employees_for_area_leader' //PASS ID in queryParam
export const TEAM_UPDATE_Connection = '/manage_team_config/create_tl_connection'
export const TEAM_DELETE_Connection = '/manage_team_config/remove_tl_connection'
export const EMPLOYEE_UPDATE_RECEIVED_WIZARD = '/manage_team_config/toggle_wizard' // pass employeeId

export const GET_ALL_EMPLOYEE = '/get_employees?all'
export const GET_ALL_EMPLOYEE_EXCLUDE_ICS = '/get_employees?excludeIcs'
export const GET_EMPLOYEES_MAGIC_IDS = '/get_employees_magic_ids'
export const GOV_AUTH = '/gov_auth'

export const EMPLOYEE_DELETE = '/employee_delete'

export const EMPLOYEE_MOVE_EDIT = '/employee_edit'

export const EMPLOYEE_ADD_PEOPLETEAM = '/employee_add'
export const TEAM_LEADERS_FOR_AREA_LEADER = '/team_leaders_for_area_leader'
export const GET_SAME_LEVE_L2_LEADERS = '/get_same_level_l2_leaders'

//Comment Route
export const ADD_ACTIVITY_COMMENT = '/add_comments'

//Gantt Chart
export const GANTT_CHART_OBJ_TASK = '/gant_chart_objective_activities' //PASS ID in queryParam
export const GANTT_CHART_EMPLOYEE_TASK = '/gantt_chart_employee_activities' //PASS ID in queryParam

export const GANTT_CHART_TASK_UPDATE = '/gantt_change_milestone_dates' //PASS ID in queryParam

// CUSTOM TEMPLATE
export const GOAL_CUSTOM_TEMPLATE_GET = '/goal_template_get' //PASS ID in queryParam
export const GOAL_CUSTOM_TEMPLATE_ADD = '/goal_template_add' //PASS ID in queryParam
export const GOAL_CUSTOM_TEMPLATE_EDIT = '/goal_template_edit' //PASS ID in queryParam
export const LIST_ITEM_GET = '/list_items_get'

// Spreadsheet view
export const SEPREADSHEETS = '/spread_sheet'
export const MILESTONE_BY_FILE = '/milestone_by_file'
export const IMPORTED_SPREADSHEET = '/imported_spreadsheet'
// export const  SAVE_IMPORTED_SPREADSHEET = '/save_imported_spreadsheet';
export const SAVE_IMPORTED_SPREADSHEET = '/spread_sheet_save'
export const EXPORT_SPREADSHEET = '/export_spread_sheet'
export const CHECK_SPREADSHEET_UPDATES = '/check_spreadsheet_updates'

// Meeting log
export const LIST_MEETING_LOGS = '/list_meeting_logs'
export const UPDATE_MEETING_LOGS = '/update_meeting_logs'
export const MEETING_MILESTONE_ADD = '/milestone_add'
export const MEETING_COMMENT_ADD = '/comment_add'

export const COMMENT_UPDATE = '/comment_update'
export const MILESTONE_UPDATE = '/milestone_update'
export const GET_REPORTING_EMPLOYEES = '/get_reporting_employees'

//DASHBOARD ROUTES

export const MY_ACTION = '/actions_feed_producer'
export const MY_LATEST_MEETING = '/get_meeting_topics_dashboard'

export const ADMIN_GRAPH = '/comment_update'
export const USER_GRAPH = '/get_dashboard_charts'

//OBJECTIVES ROUTES
export const TEAM_LEADER_OBJECTIVES = '/team_leader_objectives'
export const AREA_LEADER_OBJECTIVES = '/area_leader_objectives'
export const EMPLOYEES_ALLOC_T = '/employees_alloc_t'
export const EMP_OBJ_ALLOC = '/emp_obj_alloc'
export const TEAM_CAPACITY = '/team_capacity'

export const UPLOAD_MEETING_DECK = '/upload_meeting_deck'
export const GET_SINGLE_MEETING_TOPIC_DETAILS = '/get_single_meeting_topic_details'

export const GET_RECENT_OBJECTIVE_ID = '/obj_decision_history'

export const ADD_BLOCKER = '/add_blocker'

export const TRANSFER_ACTIVITY = '/transfer_activity'

//GANTT CHART
export const GANTT_CHART_DATA = '/get_gantt_objectives'

//PICASSO
export const PICASSO_PROCESS = '/get_picasso_process'

//Save Picasso Objectives
export const SAVE_PICASSO_OBJECTIVES = '/save_picasso_objectives'

//PICASSO for TeamLeader
export const PICASSO_PROCESS_TeamLeader = '/get_tl_picasso_process'

//Employee Matching
export const Employee_Matching = '/employee_matching'

//Save Picasso Objectives for team leader
export const SAVE_PICASSO_OBJECTIVES_TL = '/save_picasso_objectives_team_leader'

//Get conversation history
export const SETUP_WIZARD_HISTORY = '/get_setup_wizard_history'

//Ingestion file
export const PARSE_DATA_FROM_FILE = '/ingest_objective_from_file'

export const GET_INGESTION_HISTORY = 'get_ingestion_history'

export const GET_SUMMARIZED_ACTION_PLANS = '/get_summarized_action_plans'
