import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import { getObjectivUserList } from '../../../api/services/employee.services'
import Toast from '../../../common/toast'
import { meetingMilestoneUpdate } from '../../../api/services/meetingTopic.services'
import InPageLoader from '../../../components/InPageLoader/index'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../components/CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../../../components/ModalSubmitButton'
import { postHttpRequest, getHttpRequest } from '../../../api/query/dynamicAPI'

const initialValues = {
  access: [],
}

const AccessKPIDashboardModal = ({
  closeModal,
  kpiDashboardId,
  currentAccessList,
  loadModalBackground = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [formValues, setFormValues] = useState(initialValues)
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (kpiDashboardId) {
      getUserList()
    }
  }, [kpiDashboardId])

  useEffect(() => {
    if (currentAccessList) {
      let tempCurrentAccessList = [...currentAccessList]
      tempCurrentAccessList = tempCurrentAccessList.filter((x) => x.eId !== 0) //removes non access with eId 0 issue coming from backend
      const obj = {
        access: tempCurrentAccessList.sort((a, b) => a.name.localeCompare(b.name)),
      }
      setFormValues(obj)
    }
  }, [currentAccessList])

  async function getUserList() {
    setIsLoading(true)
    const response = await getHttpRequest('/get_employees?all', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setIsLoading(false)
    if (response?.employees) setUserList(response.employees)
  }

  function inputChangedHandler(e, data) {
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'access') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit() {
    if (!formValues.access && !formValues.access.length === 0) {
      setError('Employee Required!')
      return
    }
    setIsLoading(true)
    try {
      const data = await postHttpRequest(
        `/edit_kpi_dashboard_access`,
        {
          kpiDashboardId: kpiDashboardId,
          accessList: formValues.access.map((x) => x.eId),
          previousAccessList: currentAccessList.map((x) => x.eId),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    } catch (e) {
      Toast.fire({
        icon: 'error',
        //title: API_ERROR_MESSAGE,
      })
    }

    setIsLoading(false)
    closeModal()
    setUserList([])
    setFormValues(initialValues)
    loadModalBackground()
  }
  // if (!formValues.access && !formValues.access.eId) {
  //   setError('Assign Required !')
  //   return
  // }

  // setIsLoading(true)
  // let result
  // if (!placeHolderOnly) {
  //   if (meetingId === undefined || meetingId === null) {
  //     result = await meetingMilestoneUpdate(milestoneId, {
  //       eIds: [...formValues.access.map((x) => x.eId)],
  //     })
  //   } else {
  //     let response = await postHttpRequest(`/update_meeting_managers/${meetingId}`, {
  //       eIds: [...formValues.access.map((x) => x.eId)],
  //     })
  //     result = response.success
  //   }
  // } else {
  //   result = true
  //   //cleanup([...formValues.access])
  // }

  // setIsLoading(false)
  // if (result) {

  //   closeModal(false)
  //   setUserList([])
  //   setFormValues(initialValues)
  //   loadAgain()

  //   Toast.fire({
  //     icon: 'success',
  //     title: 'Assignee updated successfully',
  //   })
  // } else {
  //   Toast.fire({
  //     icon: 'error',
  //     title: 'Something went wrong try again later!',
  //   })
  // }

  return (
    <div className="kanban-modal">
      <Modal
        style={{ zIndex: 103 }}
        title={t('kpi.selectEmployeesAccess')}
        data-testid="Assigne"
        closeModal={() => closeModal(false)}
        width={'35rem'}
      >
        {isLoading ? <InPageLoader /> : ''}

        {!isLoading > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('assigneeActivityModal.assignTo')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      isMulti={true}
                      placeholder={t('formPlaceHolder.select')}
                      name="access"
                      value={formValues.access}
                      options={userList}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error ? <span className="error error-add-commnet">{error}</span> : null}
                  </div>
                </div>
              </div>
              <div className="modal-dual-btn">
                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={t('Common:commonButtons.updateUpperCase')}
                  onClick={() => {
                    onCustomSubmit()
                  }}
                />
                {/* Bring back these buttons when we decide to change buttons again all at once in a few days */}
                {/* <button className="dual-btn positive-btn" type="button"
                    onClick={onCustomSubmit}>
                     {t("commonButtons.updateUpperCase")}
                    </button> */}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default AccessKPIDashboardModal
