import { useState, useEffect } from 'react'
import React, { useCallback } from 'react'
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow'
import Flow from './Flow'
import InPageLoader from '../../components/InPageLoader/index'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { hasRole } from '../../utils/auth'

export default function ObjectiveMapIngestion({
  objectiveList,
  expandedMode = false,
  setAddIngestionLevel1ModalOpen = () => {},
  setL1ObjectiveAddOrEdit = () => {},
  setAddEditLevel2Objective = () => {},
  setSelectedNode = () => {},
  deleteCard = () => {},
}) {
  const { t } = useTranslation(['ObjectiveMap', 'Common'])
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isLoading, setIsLoading] = useState(false)

  const [teamLeader, setTeamLeader] = useState([])

  const [objectiveData, setObjectiveData] = useState([])

  const [cardLevel10, setCardLevel10] = useState([])
  const [cardLevel1, setCardLevel1] = useState([])
  const [cardLevel2, setCardLevel2] = useState([])
  const [cardLevel3, setCardLevel3] = useState([])

  const [selectLevel10, setSelectLevel10] = useState([])
  const [selectLevel1, setSelectLevel1] = useState([])
  const [selectLevel2, setSelectLevel2] = useState([])
  const [selectLevel3, setSelectLevel3] = useState([])

  const [level10Value, setLevel10Value] = useState('')
  const [level1Value, setLevel1Value] = useState('')
  const [level2Value, setLevel2Value] = useState('')
  const [level3Value, setLevel3Value] = useState('')

  const [totalCardDisplay, setTotalCardDisplay] = useState([])
  const [main, setMain] = useState(0)

  const [zoomIndexLevel1, setZoomIndexLevel1] = useState([])
  const [zoomIndexLevel2, setZoomIndexLevel2] = useState([])

  const [childrenCount, setChildrenCount] = useState({})
  const [searchedObjectives, setSearchedObjectives] = useState([])
  const [isInSearchMode, setIsInSearchMode] = useState(false)
  const [searchedNodeAncestors, setSearchedNodeAncestors] = useState([])
  const [displayedTree, setDisplayedTree] = useState([])

  useEffect(() => {
    getObjectiveFlow()
  }, [objectiveList])

  useEffect(() => {
    if (expandedMode) {
      initializeZoomLevels()
    }
  }, [cardLevel1, cardLevel2])

  async function getObjectiveFlow() {
    const result = objectiveList

    let finalCard = []
    let childrenCountTmp = {}

    if (result && result.levelCards) {
      setObjectiveData(result.levelCards)
      for (let x = 0; result.levelCards.length > x; x++) {
        const obj = result.levelCards[x]
        // set drop down data point
        if (obj.level == 1)
          setLevelDropValue(obj.thisLevelSegementDisplayNames, obj.thisLevelSegementIds, obj.level)
        // TODO: If we decide to show level 10 conditionally (not send from backend in some cases),
        // this needs to be made conditional.
        if (obj.level == 10) setLevel10Value(obj.thisLevelSegementIds[0])
        // set cards array.
        if (obj.cardDisplayGroups && obj.cardDisplayGroups.length > 0) {
          const cards = obj.cardDisplayGroups
          let temp = []
          for (let y = 0; cards.length > y; y++) {
            let levelCards = cards[y].cards

            // hide BAU objective for AL/TL -> so objectives under BAU will not show edges.
            if ((hasRole('area') || hasRole('team')) && obj.level == 1) {
              levelCards = levelCards.filter((card) => card.objectiveId !== -1)
            }

            temp = temp.concat(levelCards)
            finalCard = finalCard.concat(levelCards)
          }

          if (obj.level == 10) {
            setCardLevel10(temp)
          } else if (obj.level == 1) {
            setCardLevel1(temp)
          } else if (obj.level == 2) {
            setCardLevel2(temp)
          } else if (obj.level == 3) {
            setCardLevel3(temp)
          }
        }
      }
    }
    setTotalCardDisplay(finalCard)

    const finalEdges = []
    // Create Edges
    for (let y = 0; finalCard.length > y; y++) {
      let mainObj = finalCard[y]
      mainObj.ignoreOwnerMatch = true
      if (mainObj.parentId != 0) {
        const dataEdges = {
          id: `e2-${mainObj.parentId}-${mainObj.id}`,
          source: `${mainObj.parentId}`,
          target: `${mainObj.id}`,
          // type: "step",
          style: {
            stroke: '#737373',
            strokeWidth: 4,
          },
        }
        finalEdges.push(dataEdges)

        // Add count to its parent
        if (mainObj.parentId in childrenCountTmp) {
          childrenCountTmp[mainObj.parentId] += 1
        } else {
          childrenCountTmp[mainObj.parentId] = 1
        }
      }
    }
    setEdges(finalEdges)
    setChildrenCount(childrenCountTmp)
  }

  function initializeZoomLevels() {
    // Set Level 1 to first card ownerId
    setLevel1Value(cardLevel1.map((card) => card.ownerId)[0])

    // const level1ZoomIndex = cardLevel1.map(card => parseInt(card.id));
    const level1ZoomIndex = [0]
    const level2ZoomIndex = cardLevel2.map((card) => parseInt(card.id))

    setZoomIndexLevel1(level1ZoomIndex)
    setZoomIndexLevel2(level2ZoomIndex)

    setDisplayedTree([...totalCardDisplay]) // Display all cards initially
  }

  function setLevelDropValue(titleArray, numberArray, levelType) {
    const finalArray = []
    for (let x = 0; titleArray.length > x; x++) {
      const value = titleArray[x]
      const id = numberArray[x]

      finalArray.push({ value, id })
    }

    if (levelType === 1) {
      setSelectLevel1(finalArray)
    }
    if (levelType === 2) {
      setSelectLevel2(finalArray)
    }
    if (levelType === 3) {
      setSelectLevel3(finalArray)
    }
  }

  useEffect(() => {
    setFlowData()
  }, [
    level1Value,
    level2Value,
    level3Value,
    main,
    totalCardDisplay,
    zoomIndexLevel1,
    zoomIndexLevel2,
  ])

  function setFlowData() {
    // In search mode, we only show the cards that are in the searchedObjectives list and their
    // parents. Their parents are added in the Zoom Index list.

    // If there is level 10, others will move down.
    let levelPositionsY =
      cardLevel10.length > 0
        ? {
            10: 10,
            1: 250,
            2: 500,
            3: 750,
          }
        : {
            1: 10,
            2: 250,
            3: 500,
          }

    let finalNode = []

    let nodesAdded10 = []
    let nodesAdded1 = []
    let nodesAdded2 = []
    let nodesAdded3 = []

    // Note: Always create a new object when adding to the list nodes, so that we can trigger a
    // re-render in ReactFlow. We don't always need a re-render for each card, but there's no easy
    // way to identify which cards need to be re-rendered  (e.g. when the zoom icon changes).

    let count = 0

    if (level10Value !== '') {
      // Level 10
      for (let x = 0; cardLevel10.length > x; x++) {
        const mainObj = cardLevel10[x]

        if (mainObj.ownerId == level10Value) {
          mainObj.main = 10
          mainObj.value = level10Value
          mainObj.hasChildren = true
          mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
          mainObj.expandedMode = expandedMode

          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'IngestionNode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[10] },
          }
          ++count
          nodesAdded10.push(dataNode)
        }
      }
    }

    let level1CardsPositions = {}
    if (level1Value !== '') {
      let count = 0
      for (let x = 0; cardLevel1.length > x; x++) {
        // If search mode is enabled, then we should only show cards which are in zoomIndexLevel1
        // (ancestor of searched cards) or searchedObjectives. OTOH if search mode is disabled,
        // then we should show all cards for the selected owner.
        if (
          isInSearchMode &&
          findCardIndexWithId(searchedNodeAncestors, cardLevel1[x].id) === -1 &&
          findCardIndexWithId(zoomIndexLevel1, cardLevel1[x].id) === -1
        ) {
          continue
        }

        const mainObj = { ...cardLevel1[x] }
        mainObj.main = 1
        mainObj.value = level1Value

        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel1
        mainObj.isInSearchMode = isInSearchMode
        mainObj.hasChildren = childrenCount[mainObj.id] > 0
        mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = showAddModal
        mainObj.DeleteCard = DeleteCard

        const dataNode = {
          id: `${mainObj?.id}`,
          type: 'IngestionNode',
          data: mainObj,
          position: { x: 220 * count + 60, y: levelPositionsY[1] },
        }

        ++count
        nodesAdded1.push(dataNode)
        level1CardsPositions[mainObj.id] = dataNode.position

        if (mainObj?.recommendations?.length > 0) {
          for (let recommendation of mainObj.recommendations) {
            const recNode = {
              id: `${mainObj?.id}-rec-${recommendation.length}`,
              type: 'recNode',
              data: recommendation,
              position: {
                x: 220 * count + 10,
                y: levelPositionsY[1],
              },
            }
            count++
            nodesAdded1.push(recNode)
          }
        }
      }
    }

    // Level 2, rendered only if we have zoomed in on a card in Level 1.
    //set meetingtopic node here
    let level2CardsPositions = {}
    if (zoomIndexLevel1.length > 0) {
      let unsortedLevel2CardsMeta = []
      let level2Topics = []
      count = 0
      for (let x = 0; cardLevel2.length > x; x++) {
        const mainObj = { ...cardLevel2[x] }

        if (findCardIndexWithId(displayedTree, mainObj.id) === -1) {
          continue
        }

        mainObj.main = 2
        mainObj.value = level2Value

        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel2
        mainObj.isInSearchMode = isInSearchMode

        mainObj.hasChildren = childrenCount[mainObj.id] > 0
        mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
        mainObj.ShowEditModal = ShowEditModal
        mainObj.DeleteCard = DeleteCard

        unsortedLevel2CardsMeta.push(mainObj)
      }

      // Sort unsortedLevel2CardsMeta by parent id. The assumption is that the parents are sorted by
      // IDs.
      unsortedLevel2CardsMeta.sort((a, b) => {
        return sortHelper(a, b, level1CardsPositions)
        // return level1CardsPositions[a.parentId].x - level1CardsPositions[b.parentId].x
      })
      for (let mainObj of unsortedLevel2CardsMeta) {
        let topicCount = 0
        const dataNode = {
          id: `${mainObj?.id}`,
          type: mainObj?.isRecommended ? 'recL3Node' : 'IngestionNode',
          data: mainObj,
          position: { x: 220 * count + 60, y: levelPositionsY[2] },
        }
        if (mainObj?.topicList?.length > 0) {
          for (let topic of mainObj.topicList) {
            topic.hideDropdown = true
            const topicNode = {
              id: `${mainObj?.id}-topic-${topicCount}`,
              type: 'topicNode',
              data: topic,
              position: {
                x: 220 * count + 10,
                y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
              },
            }
            nodesAdded2.push(topicNode)
            ++topicCount
          }
        }
        ++count
        nodesAdded2.push(dataNode)
        level2CardsPositions[mainObj.id] = dataNode.position
      }
    }

    // Level 3, rendered only if we have zoomed in on a card in Level 2 and (by extension, level 1).
    count = 0
    nodesAdded3 = []
    let unsortedLevel3CardsMeta = []

    for (let x = 0; cardLevel3.length > x; x++) {
      const mainObj = { ...cardLevel3[x] }

      if (findCardIndexWithId(displayedTree, mainObj.id) === -1) continue

      mainObj.main = 3
      mainObj.value = level3Value

      mainObj.hasChildren = childrenCount[mainObj.id] > 0
      mainObj.isInSearchMode = null
      mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0

      unsortedLevel3CardsMeta.push(mainObj)
    }

    // Sort unsortedLevel3CardsMeta by parent id. Once again, the assumption is that the parents are
    // sorted by IDs.
    unsortedLevel3CardsMeta.sort((a, b) => {
      return sortHelper(a, b, level2CardsPositions)
      // return level2CardsPositions[a.parentId].x - level2CardsPositions[b.parentId].x
    })

    for (let mainObj of unsortedLevel3CardsMeta) {
      // let topicCount = 0;
      const dataNode = {
        id: `${mainObj?.id}`,
        type: 'IngestionNode',
        data: mainObj,
        position: { x: 220 * (count + 1), y: levelPositionsY[3] },
      }
      ++count
      nodesAdded3.push(dataNode)
    }

    // Add Empty Node Index
    if (nodesAdded2.length === 0 && level2Value != '') {
      const dataNode = {
        id: `e2-1`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[2] },
      }
      nodesAdded2.push(dataNode)
    }

    if (nodesAdded3.length === 0 && level3Value != '') {
      const dataNode = {
        id: `e2-2`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[3] },
      }
      nodesAdded3.push(dataNode)
    }

    if (level1Value !== '') {
      let addLevel1Button = {
        id: `addLevel1ObjButton-1`,
        type: 'addLevel1ObjButton',
        view: 'objective',
        data: {
          label: 'button',
          view: 'button',
          addLevel1ObjButtonHandler: addLevel1ObjButtonHandler,
          main: 1,
        },
        position: { x: 10, y: levelPositionsY[1] + 60 },
      }
      finalNode.push(addLevel1Button)
    }

    finalNode = finalNode.concat(nodesAdded1)
    finalNode = finalNode.concat(nodesAdded2)
    finalNode = finalNode.concat(nodesAdded3)
    finalNode = finalNode.concat(nodesAdded10)

    setNodes(finalNode)
  }

  function addLevel1ObjButtonHandler() {
    setL1ObjectiveAddOrEdit('add')
    setAddIngestionLevel1ModalOpen(true)
  }

  async function ShowEditModal(data, level) {
    setSelectedNode(data)
    if (level == 2) {
      setAddEditLevel2Objective(true)
    }
    setL1ObjectiveAddOrEdit('edit')
    setAddIngestionLevel1ModalOpen(true)
  }

  async function showAddModal(data, level) {
    setSelectedNode(data)
    setL1ObjectiveAddOrEdit('add')
    setAddEditLevel2Objective(true)
    setAddIngestionLevel1ModalOpen(true)
  }

  async function DeleteCard(data, level) {
    await deleteCard(data.id, level)
  }

  function setZoomIndex(data, level, isZoomIn) {
    // Disabling this because we will not end the search mode when one zooms in.
    // setIsInSearchMode(false)
    // setSearchedObjectives([])

    let displayedTreeTmp = [...displayedTree]
    if (isZoomIn) {
      // If we are zooming, add the immediate children of this card to the displayTree.
      // We will use this to determine if a card is a child of a searched card.
      for (let obj of totalCardDisplay) {
        if (obj.parentId == data.id) {
          displayedTreeTmp.push(obj)
        }
      }
    } else {
      // If we are zooming out, delete all children and grandchildren of this card from the
      // displayedTree.
      let indicesToDelete = []
      for (let i = 0; i < displayedTreeTmp.length; i++) {
        if (displayedTreeTmp[i].parentId == data.id) {
          // Delete children of displayedTreeTmp[i] too.
          for (let j = 0; j < displayedTreeTmp.length; j++) {
            if (displayedTreeTmp[j].parentId == displayedTreeTmp[i].id) {
              // displayedTreeTmp.splice(j, 1)
              indicesToDelete.push(j)
            }
          }
          // displayedTreeTmp.splice(i, 1)
          // --i
          indicesToDelete.push(i)
        }
      }

      // Delete in reverse order so that the indices don't change.
      indicesToDelete.sort((a, b) => b - a)
      for (let idx of indicesToDelete) {
        displayedTreeTmp.splice(idx, 1)
      }
    }

    setDisplayedTree(displayedTreeTmp)

    if (level == 1) {
      let idx1 = findCardIndexWithId(zoomIndexLevel1, data.id)
      let zoomIndexLevel1Tmp = [...zoomIndexLevel1]

      if (idx1 >= 0) {
        zoomIndexLevel1Tmp.splice(idx1, 1)
        setZoomIndexLevel1(zoomIndexLevel1Tmp)

        // We need to turn off level 2 for the children of this card.
        let zoomIndexLevel2Tmp = [...zoomIndexLevel2]
        for (let i = 0; i < zoomIndexLevel2Tmp.length; i++) {
          if (zoomIndexLevel2Tmp[i].parentId == data.id) {
            zoomIndexLevel2Tmp.splice(i, 1)
            --i
          }
        }
        setZoomIndexLevel2(zoomIndexLevel2Tmp)
      } else {
        setZoomIndexLevel1([...zoomIndexLevel1Tmp, data])
      }
    } else if (level == 2) {
      let idx2 = findCardIndexWithId(zoomIndexLevel2, data.id)
      let zoomIndexLevel2Tmp = [...zoomIndexLevel2]

      if (idx2 >= 0) {
        zoomIndexLevel2Tmp.splice(idx2, 1)
        setZoomIndexLevel2(zoomIndexLevel2Tmp)
      } else {
        setZoomIndexLevel2([...zoomIndexLevel2Tmp, data])
      }
    }
  }

  if (isLoading == true) {
    return <InPageLoader />
  }

  function findCardIndexWithId(list, id) {
    if (!list) return -1

    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        return i
      }
    }
    return -1
  }

  function sortHelper(a, b, parentCardsPositions) {
    let mx = (parentCardsPositions ? parentCardsPositions.length : 1) * 1000
    let aX = a.parentId in parentCardsPositions ? parentCardsPositions[a.parentId].x : mx
    let bX = b.parentId in parentCardsPositions ? parentCardsPositions[b.parentId].x : mx

    if (aX != bX) {
      return aX - bX
    }

    return b?.isRecommended === a?.isRecommended ? 0 : b?.isRecommended ? 1 : -1
  }

  return (
    <>
      <div className="">
        <div className="add-objective">
          {nodes.length > 0 ? (
            <Flow
              initialNodes={nodes}
              initialEdges={edges}
              teamLeader={teamLeader}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}
