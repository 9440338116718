import React, { useState } from 'react'
import Modal from '../Modal'
import * as Yup from 'yup'
import { Formik, Field, Form, useFormikContext, useField } from 'formik'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Toast from '../../common/toast'
import './style.css'
import Select from 'react-select'
import { getCompanyConfigSettings, getConsolidatedConfigSettings, hasRole } from '../../utils/auth'

const UserSettingModal = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(['Common'])
  const initialValue = JSON.parse(localStorage.getItem('cfg'))
  const initialLandingPage = initialValue.landing_page
  const enablekpidashboards = getConsolidatedConfigSettings('enable_kpi_dashboards') === true
  const enbaleBrainStorming = getConsolidatedConfigSettings('enable_brainstorming') === true
  const enableReviewObjectiveMapView =
    getConsolidatedConfigSettings('enable_review_objective_map_view') === true
  const enableL2ReviewObjectiveMapView =
    getConsolidatedConfigSettings('enable_review_objective_map_l2_view') === true
  const productSettings = getCompanyConfigSettings('productSettings')
  const enableSummaryOfObjectives =
    getConsolidatedConfigSettings('enable_summary_of_objectives') === true

  const [landingPage, setLandingPage] = useState(initialLandingPage)

  const landingPageOptions = [
    { value: 'LANDING_PAGE_OBJECTIVE_MAP', label: t('landingPage.objectiveMap') },
    { value: 'LANDING_PAGE_MEETING_HUB', label: t('landingPage.meetingHub') },
    { value: 'LANDING_PAGE_KANBAN', label: t('landingPage.kanban') },
  ]

  if (
    (hasRole('team') || hasRole('area') || hasRole('cxo') || hasRole('cos') || hasRole('ceo')) &&
    enablekpidashboards
  ) {
    landingPageOptions.push({ value: 'LANDING_PAGE_KPI_REPORT', label: t('navigation.report') })
  }

  if (enbaleBrainStorming) {
    landingPageOptions.push({
      value: 'LANDING_PAGE_BRAINSTORMING',
      label: t('navigation.brainstorming'),
    })
  }

  if (enableReviewObjectiveMapView || enableL2ReviewObjectiveMapView) {
    landingPageOptions.push({ value: 'LANDING_PAGE_BIG_ROOM_PLANNING', label: 'Big Room Planning' })
  }

  if (productSettings || enableSummaryOfObjectives) {
    landingPageOptions.push({
      value: 'LANDING_PAGE_SUMMARY_OF_OBJECTIVES',
      label: t('navigation.summaryOfObjectives'),
    })
  }

  const updateLocalStorage = () => {
    let cv = localStorage.getItem('cfg')
    let config = JSON.parse(cv)
    config.landing_page = landingPage
    let updatedConfig = JSON.stringify(config)
    localStorage.setItem('cfg', updatedConfig)
  }

  return (
    <>
      <div className="tutorial-management-modal">
        <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'40rem'}>
          <div className="modal-gray-box">
            <div className="modal-inner-content">
              <Formik
                initialValues={{
                  landingPage: landingPageOptions.find((option) => option.value === landingPage),
                }}
                onSubmit={(values) => {
                  ;(async () => {
                    const data = {
                      landingPage: values.landingPageValue,
                    }
                    try {
                      updateLocalStorage()
                      await postHttpRequest('/edit_employees_user_preferences', data)
                    } catch (error) {
                      Toast.fire({
                        icon: 'error',
                      })
                    }
                  })()
                  closeModal()
                }}
              >
                {({ setFieldValue }) => (
                  <Form className="form-field">
                    <div className="user-setting-modal-title">{t('userSettingsModal.title')}</div>
                    <div className="tutorial-management-modal-text">
                      {' '}
                      {t('userSettingsModal.landingPage')}
                    </div>
                    <div className="tutorial-management-modal-text">
                      <Select
                        options={landingPageOptions}
                        value={landingPageOptions.find((option) => option.value === landingPage)}
                        onChange={(option) => {
                          setLandingPage(option.value)
                          setFieldValue('landingPageValue', option.value)
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '20rem',
                            fontSize: '1rem',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            position: 'absolute',
                            zIndex: 9999,
                            width: '20rem',
                            left: '0',
                            top: '100%',
                          }),
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                      />
                    </div>
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      <button
                        type="submit"
                        className="sleek-button sleek-blue"
                        style={{
                          cursor: 'pointer',
                          height: '2.2rem',
                          backgroundColor: 'white',
                          float: 'right',
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default UserSettingModal
