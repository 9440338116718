import React, { useState } from 'react'
import { CardContainerModel, CardHeader } from '../styles'

import { Formik, Field, Form } from 'formik'

import '../style.scss'
import Modal from '../../../../../components/Modal'
import { ADDEmployeePeopleManagment } from '../../../../../api/services/employee.services'

import Toast from '../../../../../common/toast'
import { AddSchema } from './schemas/index'

const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
}

const ManageLeaders = ({
  view,
  setAddMemberShow,
  selectedTeamLeader,
  teamLeaderList,
  setIsReset,
  setRefetchData,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues] = useState(initialValues)

  async function submitMove(values) {
    const postObj = values
    postObj.corpEmail = values?.email.toLowerCase()
    postObj.eLeaderId = selectedTeamLeader
    setIsLoading(true)
    const result = await ADDEmployeePeopleManagment(postObj)
    setIsLoading(false)
    if (result == 'True') {
      setAddMemberShow(false)
      setIsReset((old) => !old)
      setRefetchData((prev) => !prev)
      return
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Email Already Exist',
      })
    }
  }

  return (
    <>
      <Modal
        title={'Add Individual Contribitor'}
        isModalOpen={view}
        data-testid="Add Objective"
        closeModal={() => setAddMemberShow(false)}
        width="700px"
      >
        <div className="move-modal">
          <CardContainerModel>
            <div className="current-team-leader-wrapper">
              <CardHeader>
                Current Team Leader:
                <p className="current-team-leader-name">
                  {teamLeaderList.find((tl) => tl.eId == selectedTeamLeader)?.name ?? ''}
                </p>
              </CardHeader>
            </div>

            <Formik
              initialValues={formValues}
              validationSchema={AddSchema}
              onSubmit={submitMove}
              enableReinitialize
            >
              {({ values, errors, touched, resetForm, setFieldValue, validateForm }) => (
                <Form className="objective-form">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                      className="column-input-objective"
                      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    >
                      <div className="form-field action-field form-field action-field--kanban">
                        <label>First Name</label>
                        <Field
                          id="firstName"
                          name="firstName"
                          placeholder="Add First Name"
                          autoComplete="off"
                          value={values.firstName}
                          className={` ${
                            errors.firstName && touched.firstName ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.firstName && touched.firstName && (
                          <span className="validation-error">{errors.firstName}</span>
                        )}
                      </div>

                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Last Name</label>
                        <Field
                          id="lastName"
                          name="lastName"
                          placeholder="Add Last Name"
                          autoComplete="off"
                          value={values.lastName}
                          className={` ${
                            errors.lastName && touched.lastName ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.lastName && touched.lastName && (
                          <span className="validation-error">{errors.lastName}</span>
                        )}
                      </div>

                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Title </label>
                        <Field
                          id="title"
                          name="title"
                          placeholder="Add Title"
                          autoComplete="off"
                          value={values.title}
                          className={` ${
                            errors.title && touched.title ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.title && touched.title && (
                          <span className="validation-error">{errors.title}</span>
                        )}
                      </div>

                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Email </label>
                        <Field
                          id="email"
                          name="email"
                          placeholder="Add Email"
                          autoComplete="off"
                          value={values.email}
                          className={` ${
                            errors.email && touched.email ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.email && touched.email && (
                          <span className="validation-error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                    <button
                      className="fitted-button modal-save-button blue"
                      onClick={() => {
                        resetForm()
                        setAddMemberShow(false)
                      }}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button className="fitted-button modal-save-button blue" type="submit">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContainerModel>
        </div>
      </Modal>
    </>
  )
}

export default ManageLeaders
