import moment from 'moment'
import { getConsolidatedConfigSettings } from './auth'

const language = getConsolidatedConfigSettings('language_settings') || 'en'

const dateTimeFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY HH:mm')
}

const dateFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YY')
}

const dayMonthFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD')
}

const dateToUnix = (date) => {
  return moment(date).unix()
}

const yyyymmddNow = () => {
  return moment(new Date()).format('YYYY-MM-DD')
}

const dateToString = (timestamp) => {
  return moment(timestamp).format('MMMM D, YYYY')
}

const isDateInPast = (unixTimestamp) => {
  if (unixTimestamp === 0) {
    return false
  }
  const currentUnixTimestamp = Math.floor(Date.now() / 1000)

  return unixTimestamp < currentUnixTimestamp
}

const mmddyyyyToStringmmdd = (dateString) => {
  const [month, day] = dateString.split('/').slice(0, 2)
  return `${month}/${day}`
}
function formatDateToMMDD(inputDate) {
  var dateParts = inputDate.split('-')
  var formattedDate = dateParts[1] + '/' + dateParts[2]
  return formattedDate
}

function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp * 1000)
  return date.toISOString().slice(0, 10)
}

const convertTimeStamToMMDDYYYY = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY')
}

const convertTimeStamToMMDDYYYYDash = (timestamp) => {
  return moment.unix(timestamp).format('MM-DD-YYYY')
}

const formatTime = (secs) => {
  let hours = Math.floor(secs / 3600)
  let minutes = Math.floor(secs / 60) % 60
  let seconds = secs % 60
  return [hours, minutes, seconds]
    .map((v) => ('' + v).padStart(2, '0'))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
}

const formatUnixTimestampToDateTimeWithSeconds = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY HH:mm:ss')
}

const formatDateForDateDropdown = (inputDate) => {
  // this is for possible empty start date
  if (!inputDate) {
    return ''
  }
  const inputDateObj = new Date(inputDate)
  const year = inputDateObj.getFullYear()
  const month = (inputDateObj.getMonth() + 1).toString().padStart(2, '0')
  const day = inputDateObj.getDate().toString().padStart(2, '0')
  const formattedDateString = `${year}-${month}-${day}`
  return formattedDateString
}

const formatISODateTime = (isoDate) => {
  const date = new Date(isoDate)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const customFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
  console.log(customFormattedDate)
  return customFormattedDate
}

function getDayOfWeek(dateString) {
  const date = new Date(dateString)

  const dayIndex = date.getDay()

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  return daysOfWeek[dayIndex]
}

function getTimZoneOptions() {
  const timeZoneOptions = [
    { label: language === 'en' ? 'Toronto' : 'Toronto', value: 'America/Toronto' },
    { label: language === 'en' ? 'Mexico City' : 'Ciudad de México', value: 'America/Mexico_City' },
    { label: language === 'en' ? 'London' : 'Londres', value: 'Europe/London' },
    { label: language === 'en' ? 'New York' : 'Nueva York', value: 'America/New_York' },
    { label: language === 'en' ? 'Los Angeles' : 'Los Ángeles', value: 'America/Los_Angeles' },
    { label: language === 'en' ? 'Chicago' : 'Chicago', value: 'America/Chicago' },
    { label: language === 'en' ? 'Denver' : 'Denver', value: 'America/Denver' },
    { label: language === 'en' ? 'Sydney' : 'Sídney', value: 'Australia/Sydney' },
    { label: language === 'en' ? 'Tokyo' : 'Tokio', value: 'Asia/Tokyo' },
    { label: language === 'en' ? 'Shanghai' : 'Shanghái', value: 'Asia/Shanghai' },
    { label: language === 'en' ? 'Mumbai' : 'Bombay', value: 'Asia/Kolkata' },
    { label: language === 'en' ? 'Dubai' : 'Dubái', value: 'Asia/Dubai' },
    { label: language === 'en' ? 'Moscow' : 'Moscú', value: 'Europe/Moscow' },
    { label: language === 'en' ? 'Paris' : 'París', value: 'Europe/Paris' },
    { label: language === 'en' ? 'Berlin' : 'Berlín', value: 'Europe/Berlin' },
    { label: language === 'en' ? 'Rome' : 'Roma', value: 'Europe/Rome' },
    { label: language === 'en' ? 'Cape Town' : 'Ciudad del Cabo', value: 'Africa/Johannesburg' },
    { label: language === 'en' ? 'Rio de Janeiro' : 'Río de Janeiro', value: 'America/Sao_Paulo' },
    {
      label: language === 'en' ? 'Buenos Aires' : 'Buenos Aires',
      value: 'America/Argentina/Buenos_Aires',
    },
    { label: language === 'en' ? 'Honolulu' : 'Honolulu', value: 'Pacific/Honolulu' },
    { label: language === 'en' ? 'Auckland' : 'Auckland', value: 'Pacific/Auckland' },
    { label: language === 'en' ? 'Seoul' : 'Seúl', value: 'Asia/Seoul' },
    { label: language === 'en' ? 'Bangkok' : 'Bangkok', value: 'Asia/Bangkok' },
    { label: language === 'en' ? 'Jakarta' : 'Yakarta', value: 'Asia/Jakarta' },
  ]
  return timeZoneOptions
}

export {
  dateTimeFilter,
  dayMonthFilter,
  dateFilter,
  yyyymmddNow,
  dateToUnix,
  dateToString,
  isDateInPast,
  mmddyyyyToStringmmdd,
  formatDateToMMDD,
  convertTimestampToDate,
  convertTimeStamToMMDDYYYY,
  formatTime,
  formatUnixTimestampToDateTimeWithSeconds,
  formatDateForDateDropdown,
  formatISODateTime,
  getDayOfWeek,
  getTimZoneOptions,
  convertTimeStamToMMDDYYYYDash,
}
