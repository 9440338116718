import React from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../../../../components/Modal'
import * as Yup from 'yup'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { useTranslation } from 'react-i18next'

const EditMeetingTopicModal = ({ isModalOpen, closeModal, meetingTopic }) => {
  const { t } = useTranslation(['Common'])
  const { dispatch } = useTeamLeaderContext()
  const [isPriority, setIsPriority] = React.useState(meetingTopic?.isPriority ?? false)

  const handleSubmit = (values) => {
    let editedMT = { ...meetingTopic }
    editedMT.description = values.description
    editedMT.isPriority = isPriority

    dispatch({ type: 'EDIT_MEETING_TOPIC', meetingTopic: { ...editedMT } })
  }

  return (
    <>
      <Modal
        title={t('editMeetingTopicModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Formik
          enableReinitialize
          initialValues={{
            description: meetingTopic?.description ?? '',
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Required'),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            closeModal(true)
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, setFieldValue }) => (
            <Form className="objective-form">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="form-field">
                    <label>{t('editMeetingTopicModal.description')}</label>
                    <Field
                      type="text"
                      maxLength="200"
                      id="description"
                      name="description"
                      autoComplete="off"
                      style={{ width: '30rem' }}
                    />
                    {errors.description && touched.description && (
                      <div className="validation-error">{errors.description}</div>
                    )}
                  </div>
                  {!isPriority && (
                    <button class="icon-button-no-border" style={{ margin: '1.5rem' }}>
                      <span
                        class="material-symbols-outlined icon-size"
                        onClick={() => {
                          setIsPriority(true)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                  {isPriority && (
                    <button
                      class="icon-button-no-border"
                      style={{ display: 'block', margin: '1.5rem' }}
                    >
                      <span
                        class="material-symbols-outlined icon-size"
                        style={{ color: 'red' }}
                        onClick={() => {
                          setIsPriority(false)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                <button
                  className="fitted-button modal-save-button blue"
                  onClick={() => {
                    resetForm()
                    closeModal()
                  }}
                  type="button"
                >
                  {t('commonButtons.cancel')}
                </button>
                <button className="fitted-button modal-save-button blue" type="submit">
                  {t('commonButtons.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default EditMeetingTopicModal
