import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../components/Modal'
import SelectField from '../../../../components/CustomMultipleSelect/singleOption'
import CreatableSelect from 'react-select/creatable'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../utils/routes'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import { useBrainstorm } from '../../Recorder/BrainstormContext'

const BrainstormMeetingTagModal = ({
  closeModal,
  saveBrainstormSession = () => Promise.resolve(),
  availableMeetings = [],
  availableTags = [],
  shouldHideTag = false,
  setSelectedTagsCurrentSession = () => {},
  setSelectedMeetingsCurrentSession = () => {},
  selectedMeetingsCurrentSession = [],
  selectedTagsCurrentSession = [],
  meetingInstanceId,
  discardedList = [],
  highlightedSubProblems = [],
  allowBulkConvertToMH = false,
}) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const { state } = useBrainstorm()

  const [isTagMeetingSaving, setIsTagMeetingSaving] = React.useState(false)
  const [isMhSaving, setIsMhSaving] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const updateTag = (selectedTag) => {
    setSelectedTagsCurrentSession(selectedTag)
  }

  const updateMeeting = (selectedMeeting) => {
    setSelectedMeetingsCurrentSession(selectedMeeting)
  }

  const handleCreate = (inputValue) => {
    if (inputValue.trim() === '') return

    setIsLoading(true)
    setTimeout(() => {
      const newOption = {
        label: inputValue,
        // -1 means it's a new unique tag
        value: -1,
      }
      setIsLoading(false)
      // check if inputValue already exists in availableTags, if yes we should add it to selectedTagsCurrentSession
      const isOptionExist = availableTags.find((tag) => tag?.label?.trim() == inputValue.trim())
      if (!isOptionExist) {
        setSelectedTagsCurrentSession((prev) => [...prev, newOption])
      } else {
        setSelectedTagsCurrentSession((prev) => [...prev, isOptionExist])
      }
    }, 500)
  }

  return (
    <Modal
      title={t('brainstormTagsMeetingsModal.titleGeneral')}
      closeModal={closeModal}
      width={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '99%', padding: '0px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.5rem',
          width: '100%',
        }}
      >
        {availableMeetings.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginRight: 'auto',
              }}
            >
              {t('Common:meetingReport.meeting')}
            </div>
            <SelectField
              // placeholder={t("formPlaceHolder.owner")}
              name="selectMeeting"
              value={selectedMeetingsCurrentSession}
              options={availableMeetings}
              onChange={updateMeeting}
              getOptionLabel={(option) => option.meetingName}
              getOptionValue={(option) => option.meetingId}
              width={'75%'}
              menuPosition="fixed"
              isMulti={true}
              marginTop={'0.5rem'}
            />
          </div>
        )}
        {availableTags.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '0.5rem',
            }}
          >
            <div
              style={{
                marginRight: 'auto',
              }}
            >
              Tag
            </div>
            <CreatableSelect
              id="selectTag"
              value={selectedTagsCurrentSession}
              onChange={updateTag}
              options={availableTags}
              isLoading={isLoading}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onCreateOption={handleCreate}
              isMulti
              isClearable
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                container: (base) => ({
                  ...base,
                  width: '75%',
                  marginTop: '0.5rem',
                  textAlign: 'left',
                }),
              }}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {allowBulkConvertToMH && (
            <div
              className="sleek-button sleek-blue"
              onClick={async () => {
                setIsMhSaving(true)
                // await saveBrainstormSession(selectedTagsCurrentSession, selectedMeetingsCurrentSession)
                try {
                  await postHttpRequest('/brainstorm/save_brainstorm_to_mh', {
                    meetingInstanceId: meetingInstanceId,
                    discardedList: discardedList,
                    highlightedSubProblems: highlightedSubProblems,
                    selectedMeetings: selectedMeetingsCurrentSession
                      .map((item) => item.meetingId)
                      .filter((item) => item),
                    selectedTags: selectedTagsCurrentSession.filter((item) => item),
                    language_setting: state.language,
                  })
                  navigate(`/${routes.meetingMultiView}`)
                  closeModal(true)
                } catch (error) {
                  Toast.fire({
                    icon: 'error',
                    title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                  })
                  setIsMhSaving(false)
                }
              }}
              style={{ marginLeft: 'auto' }}
            >
              {isMhSaving === true ? (
                <div
                  style={{
                    width: '0.5rem',
                    height: '0.5rem',
                    margin: '0',
                    borderTopColor: 'white',
                  }}
                  className="loading-spinner icon-size-l"
                ></div>
              ) : null}
              <span>{t('Common:commonButtons.saveToMh')}</span>
            </div>
          )}
          <div
            className="sleek-button sleek-blue"
            onClick={async () => {
              setIsTagMeetingSaving(true)
              await saveBrainstormSession(
                selectedTagsCurrentSession,
                selectedMeetingsCurrentSession,
              )
              closeModal(true)
            }}
            style={{ marginLeft: '10px' }}
          >
            {isTagMeetingSaving === true ? (
              <div
                style={{
                  width: '0.5rem',
                  height: '0.5rem',
                  margin: '0',
                  borderTopColor: 'white',
                }}
                className="loading-spinner icon-size-l"
              ></div>
            ) : null}
            <span>{t('Common:commonButtons.saveSmall')}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BrainstormMeetingTagModal
