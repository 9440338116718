import React from 'react'
import { Link } from 'react-router-dom'
import { meetingMultiViewSubRoutes, routes } from '../../../../utils/routes'
import { useLocation } from 'react-router-dom'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  hasRole,
} from '../../../../utils/auth'
import { useTranslation } from 'react-i18next'

const MeetingMultiViewHeaders = ({ hasF2WCapacity }) => {
  const { t } = useTranslation(['MeetingHub'])

  const location = useLocation()
  const productSettings = getCompanyConfigSettings('productSettings')
  const showKpiReportTabInMh = getCompanyConfigSettings('showKpiReportTabInMh') === true
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const enableDecisionLog = getCompanyConfigSettings('enableDecisionLog') === true
  const enableSummaryOfObjectives =
    getConsolidatedConfigSettings('enable_summary_of_objectives') === true
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  return (
    <div className="meetingview-nav-container">
      <Link
        to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`}
        className={`nav-option ${
          location.pathname.endsWith(meetingMultiViewSubRoutes.meetingTopics) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tabTitle.meetingTopics')}</span>
      </Link>
      {(hasRole('team') || hasRole('area') || hasRole('cxo') || hasRole('cos') || hasRole('ceo')) &&
        showKpiReportTabInMh && (
          // regular TL should not see KPI report is L2 KPI is enabled
          <Link
            to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.kpiReportByLeader}`}
            className={`nav-option ${
              location.pathname.endsWith(meetingMultiViewSubRoutes.kpiReportByLeader)
                ? 'active'
                : ''
            }`}
          >
            <span className="nav-option-text">{t('tabTitle.kpiReportByLeader')}</span>
          </Link>
        )}
      {/* {(productSettings || enableSummaryOfObjectives) && (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.overview}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingMultiViewSubRoutes.overview) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">
            {productSettings === true
              ? t('tabTitle.initiativesReport')
              : t('tabTitle.summaryOfObjectives')}
          </span>
        </Link>
      )} */}

      {canUseMeetingSummarization && (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingSummaries}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingMultiViewSubRoutes.meetingSummaries) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">{t('tabTitle.meetingSummaries')}</span>
        </Link>
      )}

      {enableDecisionLog &&
        (hasRole('team') ||
          hasRole('area') ||
          hasRole('cxo') ||
          hasRole('cos') ||
          hasRole('ceo')) && (
          <Link
            to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`}
            className={`nav-option ${
              location.pathname.endsWith(meetingMultiViewSubRoutes.decisionLog) ? 'active' : ''
            }`}
          >
            <span className="nav-option-text">{t('tabTitle.decisionLog')}</span>
          </Link>
        )}
      {/*TODO Swetha: Commenting Overallocation for now */}
      {/* <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.overAllocation}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingMultiViewSubRoutes.overAllocation) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Overallocations</span>
        </Link> */}

      {/* {!hasF2WCapacity || hasRole('cxo') ? (
        ''
      ) : (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.F2WAssignment}`}
          className={`nav-option ${location.pathname.endsWith(meetingMultiViewSubRoutes.F2WAssignment) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.flowToWorkAllocations')}</span>
        </Link>
      )} */}

      {/* {hasRole('cxo') ? ('') : (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.tradeOffs}`}
          className={`nav-option ${location.pathname.endsWith(meetingMultiViewSubRoutes.tradeOffs) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.tradeOffs')}</span>
        </Link>
      )} */}
      {/* <Link
        to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.dependencyResolution}`}
        className={`nav-option ${
          location.pathname.endsWith(meetingMultiViewSubRoutes.dependencyResolution) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Dependency Resolution</span>
      </Link> */}

      {/* {!(hasRole('cxo') || hasRole('ceo') || hasRole('cos')) &&  (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.takeaways}`}
          className={`nav-option ${location.pathname.endsWith(meetingMultiViewSubRoutes.takeaways) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.meetingNotes')}</span>
        </Link>
      )} */}
      {/* {(showTrendAndAnalysis && !(hasRole('cxo') || hasRole('ceo') || hasRole('cos'))) && (
        <Link
          to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.trendsAnalysis}`}
          className={`nav-option ${location.pathname.endsWith(meetingMultiViewSubRoutes.trendsAnalysis) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.trendAndAnalysis')}</span>
        </Link>
      )} */}
      {/* <Link
        to={`/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`}
        state={{ area: "1" }}
        className={`nav-option ${location.pathname.endsWith(meetingMultiViewSubRoutes.myDecisions) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">{t('tabTitle.myDecisionHistory')}</span>
      </Link> */}
    </div>
  )
}

export default MeetingMultiViewHeaders
