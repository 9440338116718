import React, { useState, useEffect } from 'react'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Toast from '../../common/toast'
import SelectField from '../CustomMultipleSelect/singleOption'
import Modal from '../Modal'
import { API_ERROR_MESSAGE } from '../../api/constant/route'
import Swal from 'sweetalert2'
import InPageLoader from '../InPageLoader'

function SelectObjectiveModal({ isOpen, closeModal, sourceObjective }) {
  const { t } = useTranslation(['Common'])
  const [objectives, setObjectives] = useState([])
  const [selectedObjective, setSelectedObjective] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    fetchObjectives()
  }, [])

  const fetchObjectives = async () => {
    try {
      setIsLoading(true)
      const response = await getHttpRequest('get_objective_list_under_cxo')
      // Check if response.objectives exists and is an array
      if (response?.objectives && Array.isArray(response.objectives)) {
        // Filter out the source objective from the list
        const filteredObjectives = response.objectives.filter(
          (obj) => obj.id !== sourceObjective.id,
        )
        setObjectives(filteredObjectives)
        setIsLoading(false)
      } else {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
        setIsLoading(false)
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
      setIsLoading(false)
    }
  }

  const handleSave = async () => {
    if (!selectedObjective) {
      setShowError(true)
      return
    }
    const result = await Swal.fire({
      title: t('selectObjectiveModal.confirmTransfer'),
      text: '',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      setIsLoading(true)
      try {
        let response = await postHttpRequest(
          'transfer_actions_topics_from_one_objective_to_other',
          {
            sourceObjId: sourceObjective.id,
            targetObjId: selectedObjective?.value,
          },
        )

        if (response.success) {
          Toast.fire({
            icon: 'success',
            title: t('selectObjectiveModal.transferSuccess'),
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: API_ERROR_MESSAGE,
          })
        }
        closeModal()
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      }
      setIsLoading(false)
    } else {
      closeModal()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('selectObjectiveModal.title')}
      width={'30rem'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '99%', padding: '0px' }}
    >
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="form-field">
              <SelectField
                isMulti={false}
                placeholder={t('selectObjectiveModal.title')}
                name="selectObjective"
                value={selectedObjective}
                onChange={(option) => setSelectedObjective(option)}
                options={objectives.map((objective) => ({
                  label: objective.statement,
                  value: objective.id,
                }))}
                width={'75%'}
                menuShouldScrollIntoView={false}
                menuPosition="fixed"
              />
              {showError ? (
                <span className="error error-add-commnet">
                  {t('selectObjectiveModal.errorMessage')}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <div
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '1rem' }}
      >
        <div className="sleek-button sleek-blue" onClick={handleSave}>
          {t('Common:commonButtons.save')}
        </div>
        <div className="sleek-button sleek-blue" onClick={closeModal}>
          {t('Common:commonButtons.cancel')}
        </div>
      </div>
    </Modal>
  )
}

export default SelectObjectiveModal
