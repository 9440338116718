import { useEffect, useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import FileUploadModal from '../../components/FileUploadModal'
import ObjectiveMapIngestion from '../objectiveFlow/IngestionMode'
import RefineStategyModal from '../../components/FileUploadModal/RefineStrategyModal'
import { useTranslation } from 'react-i18next'
import {
  getIngestionHistory,
  saveIngestion,
  updateIngestionCanvasInfo,
} from '../../api/services/objectives.services'
import Swal from 'sweetalert2'
import IngestionAddL1ObjectiveModal from '../../components/IngestionAddL1ObjectiveModal'
import Toast from '../../common/toast'
import SaveIngestionModal from '../../components/SaveIngestionModal'

const AiIngestion = () => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [loadingObjectiveMap, setLoadingObjectiveMap] = useState(false)
  const [showObjMap, setShowObjMap] = useState(true)
  const [degradedCanvas, setDegradedCanvas] = useState(null)
  const [ingestId, setIngestId] = useState(null)
  const [fileUploaded, setFileUploaded] = useState(null)
  const [isRefineStrategyModalOpen, setIsRefineStrategyModalOpen] = useState(false)
  const { t } = useTranslation(['Common'])
  const [isRestore, setIsRestore] = useState(false)
  const [addIngestionLevel1ModalOpen, setAddIngestionLevel1ModalOpen] = useState(false)
  const [l1ObjectiveAddOrEdit, setL1ObjectiveAddOrEdit] = useState('add')
  const [addEditLevel2Objective, setAddEditLevel2Objective] = useState(false)
  const [selectedNode, setSelectedNode] = useState(null)
  const [finalizeIngestionModalOpen, setFinalizeIngestionModalOpen] = useState(false)
  const [newEmployeeWithId, setNewEmployeeWithId] = useState()
  const [recheckCanvas, setRecheckCanvas] = useState(false)

  useEffect(() => {
    getIngestionHistoryData()
  }, [])

  useEffect(() => {
    if (recheckCanvas) {
      checkDegradedCanvas()
      setRecheckCanvas(false)
    }
  }, [recheckCanvas])

  useEffect(() => {
    if (degradedCanvas) {
      let level3_cards = degradedCanvas.levelCards?.filter((card) => card.level === 2)[0]
      let employeeList = []
      if (level3_cards?.level) {
        for (let group of level3_cards.cardDisplayGroups) {
          for (let card of group.cards) {
            if (employeeList.some((e) => e.name === card.owner)) {
              continue
            } else {
              employeeList.push({ name: card.owner, id: card.ownerId })
            }
          }
        }
      }
      console.log(employeeList)
      setNewEmployeeWithId(employeeList)
      console.log(degradedCanvas)
    }
  }, [degradedCanvas])

  useEffect(() => {
    if (degradedCanvas) {
      console.log('degradedCanvas', degradedCanvas)
      checkDegradedCanvas()
    }
  }, [addIngestionLevel1ModalOpen])

  const checkDegradedCanvas = () => {
    let pass = true
    console.log('degradedCanvas', degradedCanvas)
    let copyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    for (let level of copyDegradedCanvas.levelCards) {
      for (let displayGroup of level.cardDisplayGroups) {
        for (let card of displayGroup.cards) {
          if (!card.realEId) {
            pass = false
            card.invalid = true
          } else {
            card.invalid = false
          }
        }
      }
    }
    setDegradedCanvas(copyDegradedCanvas)
    return pass
  }

  const handleSaveIngestion = async () => {
    let isDegradedCanvasValid = checkDegradedCanvas(degradedCanvas)
    if (isDegradedCanvasValid) {
      Swal.fire({
        title: t('Common:aiIngestion.finalizeIngestion'),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      }).then((result) => {
        if (result.isConfirmed) {
          setFinalizeIngestionModalOpen(true)
        }
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:aiIngestion.ownerMissing'),
      })
    }
  }

  const getIngestionHistoryData = async () => {
    let res = await getIngestionHistory()
    console.log('res', res)
    if (res?.canvasResponse) {
      Swal.fire({
        title: t('Common:aiIngestion.continueIngestion'),
        showCancelButton: true,
        cancelButtonText: t('Common:commonButtons.no'),
        confirmButtonText: t('Common:commonButtons.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          onIngestData(res)
          setIsRestore(true)
        } else {
          setIsFileUploadModalOpen(true)
        }
      })
    } else {
      setIsFileUploadModalOpen(true)
    }
  }

  const onIngestData = (data) => {
    console.log('onIngestData', data)
    if (data) {
      setLoadingObjectiveMap(false)
      // console.log('data.json_str =', data.json_str)
      let response = data
      let degradedCanvas
      if (response.canvasResponse) {
        if (response.canvasResponse.levelCards.length === 3) {
          degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))
        } else {
          degradedCanvas = JSON.parse(JSON.stringify(response.canvasResponse))
        }
        console.log('degradedCanvas', degradedCanvas)
        setDegradedCanvas(degradedCanvas)
        setRecheckCanvas(true)
      }
      if (response.ingestId) {
        setIngestId(response.ingestId)
      }
    }
  }

  const degradeCanvas = (canvas) => {
    let level_cards = canvas.levelCards
    let newLevelCards = level_cards
      .filter((level_card) => level_card.level !== 1)
      .map((level_card) => {
        if (level_card.level === 2 || level_card.level === 3) {
          level_card.level -= 1
          return level_card
        }
      })
    canvas.levelCards = newLevelCards
    return canvas
  }

  const deleteCard = async (cardId, level) => {
    Swal.fire({
      title: t('Common:aiIngestion.deleteCard'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCardFromCanvas(cardId, level)
      }
    })
  }

  const deleteCardFromCanvas = async (cardId, level) => {
    let newCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    let levelCards = newCanvas.levelCards[level - 1]
    for (let displayGroup of levelCards.cardDisplayGroups) {
      let index = displayGroup.cards.findIndex((card) => card.id === cardId)
      if (index !== -1) {
        displayGroup.cards.splice(index, 1)
      }
    }
    console.log('newCanvas', newCanvas)
    await updateIngestionCanvasInfo(ingestId, newCanvas)
    setDegradedCanvas(newCanvas)
  }

  return (
    <ApplicationLayout>
      <div style={{ marginTop: '4rem' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}
        >
          {!isRestore && (
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <div
                style={{ marginLeft: '0.6rem', width: '90%' }}
                class="fitted-button blue tooltip"
              >
                <span
                  style={{ fontSize: '16px' }}
                  onClick={() => {
                    setIsRefineStrategyModalOpen(true)
                  }}
                >
                  {t('fileUploadModal.refineStrategy')}
                </span>
              </div>
            </div>
          )}

          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <div style={{ marginLeft: '0.6rem', width: '100%' }} class="fitted-button blue tooltip">
              <span style={{ fontSize: '16px' }} onClick={handleSaveIngestion}>
                {t('fileUploadModal.commit')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'block', display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginLeft: '1rem',
            width: '100%',
          }}
        >
          {finalizeIngestionModalOpen && (
            <SaveIngestionModal
              isModalOpen={finalizeIngestionModalOpen}
              closeModal={() => {
                setFinalizeIngestionModalOpen(false)
              }}
              objectiveList={degradedCanvas}
              newEmployeeWithId={newEmployeeWithId}
              ingestId={ingestId}
            />
          )}
          {addIngestionLevel1ModalOpen && (
            <IngestionAddL1ObjectiveModal
              closeModal={() => {
                setAddEditLevel2Objective(false)
                setSelectedNode(null)
                setAddIngestionLevel1ModalOpen(false)
              }}
              mode={l1ObjectiveAddOrEdit}
              degradedCanvas={degradedCanvas}
              setDegradedCanvas={setDegradedCanvas}
              addEditLevel2Objective={addEditLevel2Objective}
              selectedNode={selectedNode}
              ingestId={ingestId}
            />
          )}
          {isFileUploadModalOpen && (
            <FileUploadModal
              closeModal={() => {
                setIsFileUploadModalOpen(false)
              }}
              isModalOpen={isFileUploadModalOpen}
              setDescription={setDescription}
              description={description}
              ingestCallback={onIngestData}
              fileUploaded={fileUploaded}
              setFileUploaded={setFileUploaded}
              setLoadingObjectiveMap={setLoadingObjectiveMap}
            />
          )}

          {isRefineStrategyModalOpen && (
            <RefineStategyModal
              closeModal={() => {
                setIsRefineStrategyModalOpen(false)
              }}
              isModalOpen={isRefineStrategyModalOpen}
              setDescription={setDescription}
              description={description}
              ingestCallback={onIngestData}
              fileUploaded={fileUploaded}
              ingestId={ingestId}
              setLoadingObjectiveMap={setLoadingObjectiveMap}
            />
          )}

          <div style={{ width: '98%', overflow: 'hidden', marginTop: '3rem' }}>
            {loadingObjectiveMap && <div className="obj_map_loader" />}
            {showObjMap && (
              <ObjectiveMapIngestion
                objectiveList={degradedCanvas}
                expandedMode={true}
                setAddIngestionLevel1ModalOpen={setAddIngestionLevel1ModalOpen}
                setL1ObjectiveAddOrEdit={setL1ObjectiveAddOrEdit}
                setAddEditLevel2Objective={setAddEditLevel2Objective}
                setSelectedNode={setSelectedNode}
                deleteCard={deleteCard}
              />
            )}
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default AiIngestion
