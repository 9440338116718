import React from 'react'
import { useBrainstorm } from '../Recorder/BrainstormContext'
import { useTranslation } from 'react-i18next'

const BaseButton = ({ onClick, children, disabled = false }) => (
  <div
    onClick={() => {
      if (!disabled) {
        onClick() // Need to call onClick as a function
      }
    }}
    className="sleek-button play-circle"
    style={{
      display: 'flex',
      width: '6rem',
      fontSize: '1rem',
      height: '2rem',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'rgb(255, 255, 255)',
      backgroundColor: disabled ? '#BEBEBE' : 'rgb(68, 114, 196)',
      disable: disabled,
      border: '1px solid rgb(255, 255, 255)',
      margin: '0 0.5rem', // Added margin for spacing between buttons
    }}
  >
    {children}
  </div>
)

const DraftStatementButton = ({ setIsThinking }) => {
  const { state, generateProblems } = useBrainstorm()
  const { t } = useTranslation(['Common'])

  const handleClick = async () => {
    setIsThinking(true)
    await generateProblems(state.meetingInstanceId)
  }

  return (
    <BaseButton onClick={handleClick}>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <span style={{ textAlign: 'center' }}>{t('Common:brainstorming.draftStatementText')}</span>
      </div>
    </BaseButton>
  )
}

const FinalizeStatementButton = ({ setIsThinking, primaryProblemState, language }) => {
  const { exploreProblems } = useBrainstorm()
  const { t } = useTranslation(['Common'])
  const [showLoader, setShowLoader] = React.useState(false)

  const handleClick = async () => {
    setShowLoader(true)
    setIsThinking(true)
    await exploreProblems()
    setShowLoader(false)
  }

  const hasValue = (str) => {
    return str !== null && str !== undefined && str !== ''
  }

  return (
    <BaseButton onClick={handleClick} disabled={!hasValue(primaryProblemState)}>
      {showLoader && (
        <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
          <div className="loading-spinner" style={{ height: '1.5rem', width: '1.5rem' }}></div>
        </div>
      )}

      {!showLoader && (
        <>
          <span className="material-symbols-outlined">check_circle</span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <span style={{ textAlign: 'center' }}>
              {language === 'es' ? 'Continuar' : 'Continue'}
            </span>
          </div>
        </>
      )}
    </BaseButton>
  )
}

const DoneButton = ({ setIsThinking, primaryProblemState }) => {
  const { state } = useBrainstorm()
  const isProblemStatementNotDone = state.progressState === 'idle'
  const hasPrimaryProblem = state.primaryProblem !== ''

  // Return null if neither button should be shown
  if (!isProblemStatementNotDone && !hasPrimaryProblem) {
    return null
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <DraftStatementButton setIsThinking={setIsThinking} /> */}
      <FinalizeStatementButton
        setIsThinking={setIsThinking}
        primaryProblemState={primaryProblemState}
        language={state.language}
      />
    </div>
  )
}
export default DoneButton

export const ImpNodesBt = () => {
  const { fitViewToImportantNodes } = useBrainstorm()
  const handleClick = async () => {
    const div = document.querySelector('#mindmap-beginning')
    const rect = div.getBoundingClientRect()
    const top = rect.top // Distance from the top of the viewport
    // console.log('top: ', top)
    const redDiv = document.querySelector('.floatingedges')
    alert(redDiv)
    redDiv.style.height = `calc(100vh - ${top}px)`
    fitViewToImportantNodes()
  }

  return (
    <BaseButton onClick={handleClick} disabled={false}>
      <>
        <span className="material-symbols-outlined">check_circle</span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <span style={{ textAlign: 'center' }}>Hello</span>
        </div>
      </>
    </BaseButton>
  )
}
