import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MilestoneLabelsManagementModal from '../../../../components/MilestoneLabelsManagementModal'
import kanbanTooltipHandlers from '../utils/kanbanTooltipHandlers'

const ActionLabelDisplay = ({
  labels = [],
  milestoneId,
  refresh = () => {},
  setTooltip = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [isMilestoneLabelsModalOpen, setIsMilestoneLabelsModalOpen] = useState(false)

  const sortedLabels = useMemo(
    () => labels.sort((a, b) => a.tagLabelName.localeCompare(b.tagLabelName)),
    [labels],
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.5rem',
          flexWrap: 'wrap',
        }}
      >
        <div
          className={'no-border-button no-border-button-gray'}
          onClick={() => {
            setIsMilestoneLabelsModalOpen(true)
          }}
          {...kanbanTooltipHandlers(t('Common:tooltip.addActionLabel'), setTooltip)}
        >
          <span className="material-symbols-outlined icon-size">new_label</span>
        </div>
        {sortedLabels.slice(0, 6).map((label) => (
          <div
            key={label.tagLabelId}
            style={{
              padding: '0 0.5rem 0.2rem 0.5rem',
              backgroundColor: '#a6a6a6',
              color: 'white',
              borderRadius: '3px',
              fontSize: '0.8rem',
              height: '1rem',
              alignItems: 'center',
            }}
            {...kanbanTooltipHandlers(label.tagLabelName, setTooltip)}
          >
            {label.tagLabelName.length > 8
              ? `${label.tagLabelName.slice(0, 10)}...`
              : label.tagLabelName}
          </div>
        ))}
      </div>
      {isMilestoneLabelsModalOpen && (
        <MilestoneLabelsManagementModal
          milestoneId={milestoneId}
          closeModal={() => {
            setIsMilestoneLabelsModalOpen(false)
          }}
          refresh={refresh}
        />
      )}
    </>
  )
}

export default ActionLabelDisplay
