import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/Modal'

const FacilitatorAprovalModal = ({ handleClose, handleNext, saveTimer, saveLastPageVisited }) => {
  return (
    <>
      <Modal
        title={''}
        closeModal={() => {
          handleClose()
        }}
      >
        <span>
          Make sure you've checked your answer with the facilitator. Have they accepted your answer
          as a PASS?
        </span>

        <div className="modal-dual-btn" style={{ gap: '1rem' }}>
          <button
            className="fitted-button modal-save-button blue"
            onClick={() => {
              handleClose()
            }}
            data-testid="save"
          >
            NO
          </button>
          <button
            className="fitted-button modal-save-button blue"
            style={{ backgroundColor: '#1DB81A', opacity: '1' }}
            onClick={() => {
              saveTimer()
              saveLastPageVisited()
              handleNext()
            }}
            data-testid="save"
          >
            YES
          </button>
        </div>
      </Modal>
    </>
  )
}
export default FacilitatorAprovalModal
