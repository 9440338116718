import React from 'react'
import Modal from '../Modal'
import * as Yup from 'yup'
import { Formik, Field, Form, useFormikContext, useField } from 'formik'
import ModalSaveButton from '../ModalSubmitButton'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'

const UserLicenseModal = ({ isModalOpen, closeModal, license, handleSubmit }) => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <Modal
        title={t('userLicenseModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'40rem'}
      >
        <Formik
          enableReinitialize
          initialValues={{
            licenseTotal: license.value,
          }}
          validationSchema={Yup.object().shape({
            licenseTotal: Yup.number().required(t('formError.required')),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values.licenseTotal, license)
          }}
        >
          {({ errors, touched, resetForm, values, setFieldValue, validateForm }) => (
            <Form className="form">
              <div className="form-field">
                <label>{t('userLicenseModal.label')}</label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Field type="number" id="licenseTotal" name="licenseTotal" autoComplete="off" />
                </div>

                {errors?.licenseTotal && (
                  <div
                    className="validation-error"
                    style={{ textAlign: 'left', maxWidth: '16rem' }}
                  >
                    {errors?.licenseTotal}
                  </div>
                )}
              </div>

              <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                <button
                  className="fitted-button modal-save-button blue"
                  onClick={() => {
                    closeModal()
                    resetForm()
                  }}
                  type="button"
                >
                  {t('commonButtons.cancel')}
                </button>
                <ModalSaveButton></ModalSaveButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default UserLicenseModal
