import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import Select from 'react-select'
import TextArea from 'rc-textarea'
import useEmployeeData_2_3 from '../../api/query/useEmployeeDataLevel2&3'
import { useEffect, useState } from 'react'
import { updateIngestionCanvasInfo } from '../../api/services/objectives.services'

const IngestionAddL1ObjectiveModal = ({
  mode,
  closeModal,
  degradedCanvas,
  setDegradedCanvas,
  addEditLevel2Objective,
  selectedNode,
  ingestId,
}) => {
  const { t } = useTranslation(['Common'])
  const { data: dataEmpLevel23 } = useEmployeeData_2_3()
  const [objectiveStatement, setObjectiveStatement] = useState(
    selectedNode && mode == 'edit' ? selectedNode.goal : '',
  )
  const [objectiveOwner, setObjectiveOwner] = useState({})
  const [cardId, setCardId] = useState(0)
  const [cardOwnerIdMap, setCardOwnerIdMap] = useState({})
  const [level2ObjectiveList, setLevel2ObjectiveList] = useState([])
  const [selectedL2Objective, setSelectedL2Objective] = useState(
    mode == 'add'
      ? { id: selectedNode?.id, statement: selectedNode?.goal }
      : {
          id: selectedNode?.parentId,
          statement: degradedCanvas.levelCards[0].cardDisplayGroups[0]?.cards?.find(
            (card) => card.id == selectedNode?.parentId,
          )?.goal,
        },
  )
  console.log('selectedNode parent', selectedNode?.parentId)
  console.log(
    '111',
    degradedCanvas.levelCards[0].cardDisplayGroups[0]?.cards?.find(
      (card) => card.id == selectedNode?.parentId,
    )?.goal,
  )
  const [allEmployeeList, setAllEmployeeList] = useState([])
  const [level2EmployeeList, setLevel2EmployeeList] = useState([])
  console.log('degradedCanvas', degradedCanvas)
  console.log('selectedNode', selectedNode)

  useEffect(() => {
    calculateCardNumber()
  }, [])

  useEffect(() => {
    if (dataEmpLevel23?.employees.length > 0) {
      const temp = dataEmpLevel23.employees.map((emp) => ({
        value: emp.eId,
        label: emp.name,
      }))
      setAllEmployeeList(temp)
      const tempLevel2 = dataEmpLevel23.employees
        .filter((emp) => emp.level === 2)
        .map((emp) => ({
          value: emp.eId,
          label: emp.name,
        }))
      setLevel2EmployeeList(tempLevel2)
    }
    setObjectiveOwner({
      name: selectedNode?.owner,
      eId: dataEmpLevel23?.employees.find((emp) => emp.name === selectedNode?.owner)?.eId,
    })
  }, [dataEmpLevel23])

  const handleObjectiveStatementChange = (e) => {
    setObjectiveStatement(e.target.value)
  }

  const handleObjectiveOwnerChange = (selectedOption) => {
    setObjectiveOwner({ eId: selectedOption.value, name: selectedOption.label })
  }

  const handleL2ObjectiveChange = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    if (mode == 'add') {
      setSelectedL2Objective({ id: selectedOption.value, statement: selectedOption.label })
    } else if (mode == 'edit') {
      console.log('selectedNode', selectedNode)
      setSelectedL2Objective({ id: selectedOption.value, statement: selectedOption.label })
      // move the card to the new L2 objective
      // let cpoyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas));
      // let selectedInCanvas = null
      // for (let level of cpoyDegradedCanvas.levelCards) {
      //     for (let group of level.cardDisplayGroups) {
      //         for (let card of group.cards) {
      //             if (card.id === selectedNode.id) {
      //                 selectedInCanvas = card;
      //                 break;
      //             }
      //         }
      //     }
      // }
      // console.log('selectedInCanvas', selectedInCanvas);
      // selectedInCanvas.parentId = selectedOption.value;
      // console.log('copyDegradedCanvas', cpoyDegradedCanvas);
      // setDegradedCanvas(cpoyDegradedCanvas);
      // return cpoyDegradedCanvas;
    }
  }

  const handleSave = async () => {
    let newDegradedCanvas = null
    if (mode == 'edit') {
      newDegradedCanvas = editObjective()
    } else {
      if (addEditLevel2Objective) {
        newDegradedCanvas = addL2Objective()
      } else {
        newDegradedCanvas = addL1Objective()
      }
    }
    await updateIngestionCanvasInfo(ingestId, newDegradedCanvas)
    setDegradedCanvas(newDegradedCanvas)
    closeModal()
  }

  const editObjective = () => {
    let cpoyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    let selectedInCanvas = null
    for (let level of cpoyDegradedCanvas.levelCards) {
      for (let group of level.cardDisplayGroups) {
        for (let card of group.cards) {
          if (card.id === selectedNode.id) {
            selectedInCanvas = card
            break
          }
        }
      }
    }
    console.log('selectedInCanvas', selectedInCanvas)
    selectedInCanvas.owner = objectiveOwner.name
    selectedInCanvas.goal = objectiveStatement
    selectedInCanvas.ownerId = cardOwnerIdMap[objectiveOwner.name]
    selectedInCanvas.realEId = objectiveOwner.eId
    selectedInCanvas.parentId = selectedL2Objective.id
    console.log('copyDegradedCanvas', cpoyDegradedCanvas)
    if (!cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)) {
      cpoyDegradedCanvas.levelCards[0].cardDisplayGroups.push({
        ownerId: selectedInCanvas.ownerId,
        cards: [],
        cardEdges: [],
      })

      cpoyDegradedCanvas.levelCards[1].cardDisplayGroups.push({
        ownerId: selectedInCanvas.ownerId,
        cards: [],
        cardEdges: [],
      })
    }
    return cpoyDegradedCanvas
  }

  const addL2Objective = () => {
    console.log(cardOwnerIdMap)
    let cpoyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    let cardDisplayGoup = cpoyDegradedCanvas.levelCards[1].cardDisplayGroups.find(
      (group) => group.ownerId === cardOwnerIdMap[objectiveOwner.name],
    )
    if (cardDisplayGoup) {
      console.log(cpoyDegradedCanvas)
      let cardDisplayGoup = cpoyDegradedCanvas.levelCards[1].cardDisplayGroups.find(
        (group) => group.ownerId === cardOwnerIdMap[objectiveOwner.name],
      )
      const nextId = Math.max(...Object.values(cardOwnerIdMap)) + 1
      cardDisplayGoup.cards.push({
        id: String(cardId),
        owner: objectiveOwner.name,
        goal: objectiveStatement,
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        parentId: selectedL2Objective.id,
        realEId: objectiveOwner.eId,
      })
    } else {
      const nextId = Math.max(...Object.values(cardOwnerIdMap)) + 1
      console.log(cardOwnerIdMap)
      let card = {
        id: String(cardId),
        owner: objectiveOwner.name,
        goal: objectiveStatement,
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        parentId: selectedL2Objective.id,
        realEId: objectiveOwner.eId,
      }
      cpoyDegradedCanvas.levelCards[1].cardDisplayGroups.push({
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        cards: [card],
        cardEdges: [],
      })
      // console.log({ ...cardOwnerIdMap, [objectiveOwner.name]: nextId })
      // setCardOwnerIdMap({ ...cardOwnerIdMap, [objectiveOwner.name]: nextId });
    }
    console.log('copyDegradedCanvas', cpoyDegradedCanvas)
    setCardId(cardId + 1)
    return cpoyDegradedCanvas
  }

  const addL1Objective = () => {
    let cpoyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    let cardDisplayGoup = cpoyDegradedCanvas.levelCards[0].cardDisplayGroups.find(
      (group) => group.ownerId === cardOwnerIdMap[objectiveOwner.name],
    )
    if (cardDisplayGoup) {
      let cardDisplayGoup = cpoyDegradedCanvas.levelCards[0].cardDisplayGroups.find(
        (group) => group.ownerId === cardOwnerIdMap[objectiveOwner.name],
      )
      const nextId = Math.max(...Object.values(cardOwnerIdMap)) + 1
      cardDisplayGoup.cards.push({
        id: String(cardId),
        owner: objectiveOwner.name,
        goal: objectiveStatement,
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        realEId: objectiveOwner.eId,
      })
    } else {
      const nextId = Math.max(...Object.values(cardOwnerIdMap)) + 1
      let card = {
        id: String(cardId),
        owner: objectiveOwner.name,
        goal: objectiveStatement,
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        realEId: objectiveOwner.eId,
      }
      cpoyDegradedCanvas.levelCards[0].cardDisplayGroups.push({
        ownerId: cardOwnerIdMap.hasOwnProperty(objectiveOwner.name)
          ? cardOwnerIdMap[objectiveOwner.name]
          : nextId,
        cards: [card],
        cardEdges: [],
      })
    }
    console.log('copyDegradedCanvas', cpoyDegradedCanvas)
    setCardId(cardId + 1)
    return cpoyDegradedCanvas
  }

  const calculateCardNumber = () => {
    let maxCardId = 0
    let ownerIdNameMap = {}
    let tempL2ObjectiveList = []
    let cpoyDegradedCanvas = JSON.parse(JSON.stringify(degradedCanvas))
    console.log('cpoyDegradedCanvas', cpoyDegradedCanvas)
    for (let level of cpoyDegradedCanvas.levelCards) {
      for (let group of level.cardDisplayGroups) {
        for (let card of group.cards) {
          maxCardId = Math.max(maxCardId, parseInt(card.id))
          ownerIdNameMap[card.owner] = card.ownerId
          if (level.level === 1) {
            tempL2ObjectiveList.push({
              value: card.id,
              label: card.goal,
            })
          }
        }
      }
    }
    setLevel2ObjectiveList(tempL2ObjectiveList)
    setCardOwnerIdMap(ownerIdNameMap)
    setCardId(maxCardId + 1)
  }

  return (
    <Modal
      title={mode === 'edit' ? t('areaObjective.editObj') : t('areaObjective.newObj')}
      closeModal={closeModal}
      height={addEditLevel2Objective ? '38rem' : '33rem'}
      width={'35rem'}
    >
      <div style={{ width: '28rem' }}>
        <div className="modal-inner-content">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <label style={{ marginBottom: '8px', fontWeight: 'bold' }}>
              {t('aiIngestion.ownerName')}
            </label>
            <Select
              options={addEditLevel2Objective ? allEmployeeList : level2EmployeeList}
              menuPosition="fixed"
              menuShouldScrollIntoView={false}
              styles={{
                menu: (base) => ({ ...base, width: '20rem', zIndex: 9999 }),
                control: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                singleValue: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                placeholder: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                option: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
              }}
              onChange={handleObjectiveOwnerChange}
              value={{ value: objectiveOwner?.eId, label: objectiveOwner?.name }}
            />
          </div>
          {console.log('selectedL2Objective', selectedL2Objective)}
          {addEditLevel2Objective && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '1rem',
                width: '100%',
              }}
            >
              <label style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                {t('aiIngestion.areaObjective')}
              </label>
              <Select
                style={{ width: '100%' }}
                options={level2ObjectiveList}
                menuPosition="fixed"
                menuShouldScrollIntoView={false}
                styles={{
                  menu: (base) => ({ ...base, width: '20rem', zIndex: 9999 }),
                  control: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                  singleValue: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                  placeholder: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                  option: (base) => ({ ...base, width: '20rem', textAlign: 'left' }),
                }}
                onChange={handleL2ObjectiveChange}
                value={{ value: selectedL2Objective?.id, label: selectedL2Objective?.statement }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginTop: '16px',
              width: '100%',
            }}
          >
            <label style={{ marginBottom: '8px', fontWeight: 'bold' }}>
              {t('aiIngestion.statement')}
            </label>
            <div className="review-generated-topics-list-item" style={{ width: '100%' }}>
              <TextArea
                style={{
                  width: '100%',
                  height: '10rem',
                  textAlign: 'flex-start',
                  display: 'flex',
                  alignItems: 'felx-start',
                  justifyContent: 'flex-start',
                  fontSize: '1rem',
                }}
                placeholder="Please start with a verb"
                onChange={(e) => {
                  handleObjectiveStatementChange(e)
                }}
                value={objectiveStatement}
              />
            </div>
          </div>
          <div
            style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center', gap: '2rem' }}
          >
            <div
              className="sleek-button sleek-blue"
              style={{ cursor: 'pointer', height: '1.5rem' }}
              onClick={() => {
                closeModal()
              }}
            >
              {t('commonButtons.cancel')}
            </div>
            <div
              className="sleek-button sleek-blue"
              style={{ cursor: 'pointer', height: '1.5rem' }}
              onClick={handleSave}
            >
              {t('commonButtons.save')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IngestionAddL1ObjectiveModal
