import { useMemo } from 'react'

// Create a function that returns an object with the event handlers
const kanbanTooltipHandlers = (tooltipText, setTooltip) => ({
  onMouseEnter: (e) => {
    setTooltip({
      x: e.clientX + window.scrollX,
      y: e.clientY + window.scrollY,
      tooltipText,
      visible: true,
    })
  },
  onMouseLeave: () => {
    setTooltip({
      x: 0,
      y: 0,
      tooltipText: '',
      visible: false,
    })
  },
})

export default kanbanTooltipHandlers
