import React from 'react'
import { useTranslation } from 'react-i18next'

const AddCommentButton = ({ isLoading = false }) => {
  const { t } = useTranslation(['Common'])

  return (
    <button
      style={{
        width: '6rem',
        marginTop: '0.5rem',
        height: '2rem',
        borderRadius: '4px',
        padding: '0',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
      }}
      disabled={isLoading}
      type="submit"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading === true ? (
          <div
            style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
            className="loading-spinner"
          ></div>
        ) : null}
        {t('commonButtons.add')}
      </div>
    </button>
  )
}

export default AddCommentButton
