import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { getCompanyConfigSettings, getConsolidatedConfigSettings } from '../../../utils/auth'

function ObjMapDropdownMenu({ topicMode = false, ...props }) {
  const { t } = useTranslation(['ObjectiveMap'])
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  return (
    <div style={{ textAlign: 'left', zIndex: '999' }}>
      <Menu
        menuClassName="obj-map-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0.1rem 0 0 0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined icon-size">
              more_vert
            </span>
          </MenuButton>
        }
        portal={true}
      >
        <div>
          {props.canEdit && (
            <MenuItem
              onClick={() => {
                props.onClickEdit()
              }}
            >
              {t('dropdown.edit')}
            </MenuItem>
          )}

          {!props.isDeactivated &&
            props.isReviewMode === false &&
            props.canEdit &&
            props.level === 3 && (
              <MenuItem
                onClick={() => {
                  props.onClickRequestUpdate()
                }}
              >
                {t('dropdown.requestUpdate')}
              </MenuItem>
            )}

          {props.canSeeFiles && showMeetingDeck && (props.level === 2 || props.level === 3) && (
            <MenuItem
              onClick={() => {
                props.onClickViewFiles()
              }}
            >
              {t('dropdown.viewFiles')}
            </MenuItem>
          )}
          {props.canKpi && (
            <MenuItem
              onClick={() => {
                props.onClickKPIs()
              }}
            >
              {t('dropdown.viewKPIs')}
            </MenuItem>
          )}
          {props.canEdit && props.isReviewMode === false && (
            <MenuItem
              onClick={() => {
                props.onClickChangelog()
              }}
            >
              {t('dropdown.viewChangeLog')}
            </MenuItem>
          )}
          {props.canDeleteComplete && (props.level === 2 || props.level === 3) && (
            <MenuItem
              onClick={() => {
                props.onClickCompleteObj()
              }}
            >
              {t('dropdown.completeObj')}
            </MenuItem>
          )}
          {props.canDeleteComplete && (props.level === 2 || props.level === 3) && (
            <MenuItem
              onClick={() => {
                props.onClickDeleteObj()
              }}
            >
              {t('dropdown.deleteObj')}
            </MenuItem>
          )}
          {props.level === 3 && props.isReviewMode === false && (
            <MenuItem
              onClick={() => {
                props.onClickShowGanttChart()
              }}
            >
              {t('dropdown.viewGanttChart')}
            </MenuItem>
          )}

          {props.isReviewMode === true && props.canAddTopic && (
            <MenuItem
              onClick={() => {
                props.onClickAddMeetingTopic()
              }}
            >
              {t('dropdown.addMeetingtopic')}
            </MenuItem>
          )}

          {topicMode === true && (
            <MenuItem
              onClick={() => {
                props?.ShowEditTopicModal()
              }}
            >
              {t('dropdown.edit')}
            </MenuItem>
          )}

          {topicMode === true && (
            <MenuItem
              onClick={() => {
                props?.ShowCardTopicModal()
              }}
            >
              {t('dropdown.seeMoreDetails')}
            </MenuItem>
          )}

          {topicMode === true && (
            <MenuItem
              onClick={() => {
                props?.ShowDeleteTopicModal()
              }}
            >
              {t('dropdown.delete')}
            </MenuItem>
          )}
        </div>
      </Menu>
    </div>
  )
}

export default ObjMapDropdownMenu
