import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo_new.png'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  getHasSetupWizardAction,
  hasRole,
  logout,
} from '../../utils/auth.js'
import { routes } from '../../utils/routes'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const Landing = () => {
  const navigate = useNavigate()

  let currentUser = localStorage.getItem('tfmu') || null
  let userRoles = localStorage.getItem('tfmp') || null
  let setup = localStorage.getItem('setup') || null
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  const enableSetupAgent = getConsolidatedConfigSettings('enable_setup_agent') === true
  const productSettings = getCompanyConfigSettings('productSettings')

  const landingPage = getConsolidatedConfigSettings('landing_page')

  React.useEffect(() => {
    if (currentUser !== null && userRoles !== null && setup !== null) {
      localStorage.setItem('hrefReason_5B', '0x5B')
      if (localStorage.getItem('tfex') === 'true') {
        if (getConsolidatedConfigSettings('enable_brainstorming') === true) {
          navigate('/BrainStorming')
        } else {
          logout()
        }
        return
      } else if (landingPage && landingPage !== 'LANDING_PAGE_NOT_SET') {
        switch (landingPage) {
          case 'LANDING_PAGE_OBJECTIVE_MAP':
            navigate('/ObjectiveMap')
            return
          case 'LANDING_PAGE_MEETING_HUB':
            navigate('/MeetingMultiView')
            return
          case 'LANDING_PAGE_KANBAN':
            navigate('/Kanban')
            return
          case 'LANDING_PAGE_SUMMARY_OF_OBJECTIVES':
            navigate('/SummaryOfObjectives')
            return
          case 'LANDING_PAGE_KPI_REPORT':
            navigate('/Report/KPIsV2')
            return
          case 'LANDING_PAGE_BRAINSTORMING':
            navigate('/BrainStorming')
            return
          case 'LANDING_PAGE_BIG_ROOM_PLANNING':
            navigate('/ReviewObjectiveMap')
            return
        }
      } else if (hasRole('ic')) {
        productSettings ? navigate('/Kanban') : navigate('/SummaryOfObjectives')
      }
      // Commenting this out as we don't want them to directly land on Setup Page
      // else if (getHasSetupWizardAction() && enableSetupAgent) {
      //   navigate(`/${routes.setupIntroScreen}`, {
      //     state: {
      //       teamLeaderIntro: true,
      //     },
      //   })
      // }
      else if (
        hasRole('cxo') ||
        hasRole('area') ||
        hasRole('ceo') ||
        hasRole('cos') ||
        (hasRole('team') && isDelegateTl)
      ) {
        navigate('/MeetingMultiView')
      } else if (hasRole('team')) {
        navigate('/MeetingMultiView')
      } else if (hasRole('enterprise')) {
        navigate('/Governance')
      } else {
        navigate('/MeetingMultiView')
      }
    }
  }, [navigate])

  localStorage.removeItem('logoutReason')

  return (
    <div style={{ marginTop: '10rem' }}>
      <center>
        <div style={{ marginBottom: '4rem' }}></div>
        <img src={logo} style={{ width: '300px' }}></img>

        {/* <h1>Decision+</h1> */}
        {/* <br /> */}
        <a href={`${BASE_URL}/f/login`}>
          <div
            style={{
              width: '100px',
              padding: '20px',
              background: 'rgb(237, 82, 46)',
              color: '#fff',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '4rem',
            }}
          >
            Login
          </div>
        </a>

        {localStorage.getItem('logoutReason') === 'timeout' && (
          <div id="logoutReason">
            <br />
            <em>You were logged out due to inactivity. Please login again.</em>
          </div>
        )}
      </center>
    </div>
  )
}

export default Landing
