import React, { useRef, useState } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import TextArea from 'rc-textarea'
import Toast from '../../common/toast'
import { initializeAudioAgentWithFile } from '../../api/services/objectives.services'
import './style.css'

//ingestion
const FileUploadModal = ({
  isModalOpen,
  closeModal,
  setDescription,
  description,
  ingestCallback,
  fileUploaded,
  setFileUploaded,
  setLoadingObjectiveMap,
}) => {
  const { t } = useTranslation(['Common'])
  const setupUploadDocumentRef = useRef(null)

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleUploadSetupDocumentClick = () => {
    setupUploadDocumentRef.current.click()
  }

  async function onChangeFileUploaded(e) {
    const files = e.target.files
    console.log('files', files)
    setFileUploaded(files)
  }

  const sendIngestionData = async () => {
    if (fileUploaded != null && fileUploaded?.length !== 0) {
      let formData = new FormData()

      formData.append('file', fileUploaded[0], fileUploaded[0].name)
      formData.append('description', description)
      console.log('FormData entries:', Array.from(formData.entries()))
      setLoadingObjectiveMap(true)
      closeModal()

      let response = await initializeAudioAgentWithFile(formData)
      if (response.ingestId !== '') {
        ingestCallback(response)
        console.log('response', response)
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  return (
    <>
      <div className="file-upload-modal">
        <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'40rem'}>
          <div
            className="modal-gray-box"
            style={{ width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem' }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="file-upload-modal-title">{t('fileUploadModal.title')}</div>
              <div style={{ marginTop: '1rem', color: '#9cb4e0' }}>
                {t('fileUploadModal.description')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <div>
                  {fileUploaded ? fileUploaded[0].name : t('fileUploadModal.noFileSelected')}
                </div>
                <div
                  class="tooltip"
                  onClick={handleUploadSetupDocumentClick}
                  onKeyDown={handleUploadSetupDocumentClick}
                  style={{
                    fontSize: '1rem',
                    height: '2rem',
                    marginLeft: 'auto',
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="file"
                    id="myFile"
                    name="filename"
                    ref={setupUploadDocumentRef}
                    accept=".ppt, .pptx, .pdf"
                    onChange={(e) => onChangeFileUploaded(e)}
                    style={{ display: 'none' }}
                  ></input>

                  <span class="material-symbols-outlined" id="meetingHubTutorial8a">
                    upload
                  </span>
                  <span class="tooltiptext"> {t('Common:tooltip.uploadAPresentation')} </span>
                </div>
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                {t('fileUploadModal.context')}
              </div>
              <div style={{ fontStyle: 'italic', fontSize: '15px', marginBottom: '0.5rem' }}>
                {t('fileUploadModal.contextContent')}
              </div>
              <div className="review-generated-topics-list-item">
                <TextArea
                  rows={10}
                  type="text"
                  style={{
                    height: '10rem',
                    backgroundColor: '#f1f0f0',
                    width: '90%',
                    borderRadius: '10px',
                    whiteSpace: 'pre-wrap',
                  }}
                  spellCheck={false}
                  onChange={(e) => handleDescriptionChange(e)}
                />
              </div>
            </div>
            <div
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                marginTop: '1rem',
              }}
            >
              <div
                className="sleek-button sleek-blue"
                style={{
                  cursor: 'pointer',
                  height: '1.8rem',
                  backgroundColor: 'white',
                  float: 'right',
                }}
              >
                <span
                  style={{ fontSize: '16px' }}
                  onClick={() => {
                    sendIngestionData()
                  }}
                >
                  {t('fileUploadModal.save')}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default FileUploadModal
